import { BodyScrollEndEvent, GetRowIdParams, RowClassParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useGetHeadersKC6AQuery } from '@/api/ksgKC6A/ksgKC6A.api'

import { useVerticalPagination } from '@/hooks/useVerticalPagination'

import '@/shared/stylesheets/AgGrid.scss'

import { agGridKC6ASelector } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { getWorksKC6A } from '@/store/slices/agGridKsgMsgSlices/ksgKC6AThunks'
import { IGetParamsKSG } from '@/store/slices/agGridKsgMsgSlices/ksgThunks'
import { authSelector } from '@/store/slices/authSlice'
import { setAgGrid } from '@/store/slices/drawersSlice'
import { useAppDispatch, useTypedSelector } from '@/store/store'

import { getKC6AColDefs } from './AgGridKsgKC6A.colDef'
import { LIMIT, ROW_CLASS_BY_LEVEL } from './AgGridKsgKC6A.config'
import { TKC6AHiddenRow, TKC6AWork, TKC6AWorkOrPosition } from './AgGridKsgKC6A.def'
import { transformWorkPositionToWork } from './AgGridKsgKC6A.utils'

export const useRowDataGet = (hiddenRows: TKC6AHiddenRow[]) => {
    const [filteredWorks, setFilterWorks] = useState<TKC6AWork[] | null>([])
    const { worksList } = useTypedSelector(agGridKC6ASelector)

    const rowData = useMemo(() => {
        return filteredWorks?.flatMap(item => {
            if (!item.positions) return item

            if (!hiddenRows.some(v => isEqual(v, { workId: item.workId, code: item.code }))) {
                return item
            }

            return [item, ...item?.positions?.map(position => transformWorkPositionToWork(position))]
        })
    }, [filteredWorks]) as TKC6AWorkOrPosition[]

    useEffect(() => {
        setFilterWorks(() => {
            if (!worksList) return []

            const filteredWorks = worksList.data.reduce((acc: TKC6AWork[], work) => {
                const isWorkInHiddenRows = hiddenRows.every(v => {
                    if (work.code === v.code) return true
                    return !v.code.split('-').every(v => work.code.split('-').includes(v))
                })

                return isWorkInHiddenRows ? [...acc, work] : acc
            }, [])

            return filteredWorks?.length ? filteredWorks : worksList.data
        })
        return () => {
            setFilterWorks([])
        }
    }, [hiddenRows, worksList])

    return {
        rowData
    }
}

export const useColumnDefsGet = () => {
    const [hiddenRows, setHiddenRows] = useState<TKC6AHiddenRow[]>([])
    const { projectId } = useParams()

    const location = useLocation()

    const pathname = location.pathname

    const { data: headersData, isLoading: isHeadersLoading } = useGetHeadersKC6AQuery({
        projectId: Number(projectId)
    })
    const headers = headersData?.headers

    const updateHiddenRows = (data: TKC6AHiddenRow, checked: boolean) => {
        setHiddenRows(hiddenRows => {
            if (checked) return hiddenRows.filter(v => v.workId !== data.workId)
            return [...hiddenRows, data]
        })
    }

    const columnDefs = useMemo(() => {
        if (isHeadersLoading) return []

        return getKC6AColDefs({
            projectId: projectId as string,
            pathname,
            headers: headers!,
            hiddenRows: hiddenRows,
            updateHiddenRows: updateHiddenRows
        })
    }, [isHeadersLoading, pathname, hiddenRows, headers, projectId])

    return {
        columnDefs,
        hiddenRows,
        isHeadersLoading
    }
}

export const useDataGet = (toggleGridOverlay: (isShow?: boolean) => void) => {
    const location = useLocation()
    const { projectId } = useParams()
    const { token } = useTypedSelector(authSelector)
    const [totalRows, setTotalRows] = useState(0)
    const { worksList } = useTypedSelector(agGridKC6ASelector)

    const scrollParams = JSON.parse(localStorage.getItem('scrollParams')!)
    const lastRenderedRow = scrollParams && scrollParams[location.pathname]?.lastRenderedRow

    useEffect(() => {
        if (worksList) {
            setTotalRows(worksList.total)
        }
    }, [worksList])

    const getData = useCallback((params: IGetParamsKSG) => {
        toggleGridOverlay(true)
        return getWorksKC6A(params)
    }, [])

    const { scrollHandlerFoAG, fetching: isWorksLoading } = useVerticalPagination<IGetParamsKSG>({
        initialParams: {
            limit: lastRenderedRow ? Math.ceil(lastRenderedRow / LIMIT) * LIMIT : LIMIT,
            offset: 0,
            id: Number(projectId)
        },
        sendFn: getData,
        thenFn: () => {
            toggleGridOverlay(false)
        },
        catchFn: () => {
            toggleGridOverlay(false)
        },
        totalCount: totalRows,
        requiredDeps: [projectId, totalRows !== null, token],
        resetToInitialDeps: [projectId]
    })

    const onBodyScrollEnd = (e: BodyScrollEndEvent<any, any>) => {
        if (worksList?.total === worksList?.data?.length) return
        scrollHandlerFoAG(e)
    }

    return {
        onBodyScrollEnd,
        isWorksLoading
    }
}

export const useAgGridUtils = () => {
    const dispatch = useAppDispatch()
    const [isGridReady, setIsGridReady] = useState(false)
    const gridRef = useRef<AgGridReact>(null)

    useEffect(() => {
        if (!isGridReady || !gridRef?.current) return

        gridRef?.current && dispatch(setAgGrid(gridRef?.current))
        return () => {
            dispatch(setAgGrid(null))
        }
    }, [isGridReady])

    const setGridReady = useCallback(() => {
        setIsGridReady(() => true)
    }, [])

    const toggleGridOverlay = (isShow: boolean = false) => {
        if (!gridRef.current) return
        if (isShow) {
            gridRef.current?.api?.showLoadingOverlay()
            return
        }
        gridRef.current?.api?.hideOverlay()
    }

    const getRowClass = useCallback((params: RowClassParams<TKC6AWork>) => {
        return params.data?.hasChildren
            ? ROW_CLASS_BY_LEVEL[params.data?.level as keyof typeof ROW_CLASS_BY_LEVEL]
            : ROW_CLASS_BY_LEVEL[7]
    }, [])

    const getRowId = useCallback((params: GetRowIdParams<TKC6AWorkOrPosition>) => {
        const data = params.data

        if (params.data && 'isPosition' in params.data) {
            return `${data.workId}-${data.code}-${data.offerId}`
        }

        return `${data?.workId}-${data?.code}`
    }, [])

    return {
        getRowClass,
        getRowId,
        gridRef,
        toggleGridOverlay,
        setGridReady
    }
}
