import styled from '@emotion/styled'
import { StyledAgGrid } from '../../AgGridService/AgGrid.styles'
import { StyledBtn } from '../../components.styles'

export const StyledWorkListCopyAgGrid = styled(StyledAgGrid)`
    .ag-cell {
        overflow: visible;
    }
`
export const StyledTextBtn = styled(StyledBtn)`
    background: transparent !important;
    padding: 0;
    line-height: 100%;
`

StyledTextBtn.defaultProps = {
    disableRipple: true,
    variant: "text"
}
