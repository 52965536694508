import { ProviderContext } from 'notistack'
import { Dispatch, SetStateAction } from 'react'
import { StyledDrawerTitle } from '../../../../components/StyledDrawer/StyledDrawer.styles'
import { Typography } from '@mui/material'
import { TCopyResourceDrawerParams } from './CopyResourcesDrawer.def'
import { req } from '../../api/api'

export interface IOnSubmitRejectedArgs {
    error: any
    enqueueSnackbar: ProviderContext['enqueueSnackbar']
    setIsFetching: Dispatch<SetStateAction<boolean>>
}

export function onSubmitRejected(args: IOnSubmitRejectedArgs) {
    const { error, enqueueSnackbar, setIsFetching } = args
    const errMessage: string = error?.response?.data || ''
    if (errMessage.includes('invalid_choice')) {
        enqueueSnackbar(
            'У одной или нескольких работ среди выбранных для копирования не указан тип ресурса (Трудозатраты, МиМ)',
            {
                variant: 'error',
            }
        )
    }
    setIsFetching(() => false)
}

export const COPY_MODE_PARAMS: TCopyResourceDrawerParams = {
    from: {
        title: <StyledDrawerTitle>Копирование из кабинета работ</StyledDrawerTitle>,
        warningSection: (
            <Typography variant="body2">
                Вы можете скопировать данные из других кабинетов работ в этот кабинет.
            </Typography>
        ),
        descriptionSection: (
            <Typography variant="body2">
                Если вы не видите работы, в которые хотели бы скопировать кабинет, <br />
                это означает, что в них уже имеются записи, и для осуществления копирования
                <br /> необходимо удалить данные - для этого перейдите
                <br /> в интересующие работы и удалите записи.
            </Typography>
        ),
        getListReq: ({ projectId, workID }) =>
            req.get(`/projects/${projectId}/works/${workID}/cabinet/resources/utils/copy/from/list`),
        submitReq: ({ projectId, workID, body }) =>
            req.post(`/projects/${projectId}/works/${workID}/cabinet/resources/utils/copy/from/run`, { data: body }),
    },
    into: {
        title: <StyledDrawerTitle>Копирование в кабинет работ</StyledDrawerTitle>,
        warningSection: (
            <Typography variant="body2">
                Вы можете скопировать данные в другие кабинеты работ из этого кабинета.
            </Typography>
        ),
        descriptionSection: (
            <Typography variant="body2">
                Если вы не видите работы, в которые хотели бы скопировать кабинет,
                <br />
                это означает, что в них уже имеются записи, и для осуществления копирования <br />
                необходимо удалить данные - для этого перейдите <br />в интересующие работы и удалите записи.
            </Typography>
        ),
        getListReq: ({ projectId, workID }) =>
            req.get(`/projects/${projectId}/works/${workID}/cabinet/resources/utils/copy/into/list`),
        submitReq: ({ projectId, workID, body }) =>
            req.post(`/projects/${projectId}/works/${workID}/cabinet/resources/utils/copy/into/run`, { data: body }),
    },
    delete: {
        title: <StyledDrawerTitle>Удаление кабинетов работ</StyledDrawerTitle>,
        warningSection: (
            <Typography variant="body2">Вы можете выбрать работы, у которых хотите удалить ресурсы.</Typography>
        ),
        descriptionSection: <Typography variant="body2">Ресуры удалятся безвозвратно</Typography>,
        getListReq: ({ projectId, workID }) =>
            req.get(`/projects/${projectId}/cabinet/resources/work-list`, {
                limit: 9999,
                offset: 0,
            }),
        submitReq: ({ projectId, body }) =>
            req.post(`/projects/${projectId}/cabinet/resources/delete-all`, { data: body }),
    },
}
