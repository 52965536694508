import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store/store'
import { setCredentials } from '../store/slices/authSlice'
import { LoginResponse } from './auth/auth.def'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { currentCompanyName, deployLinks } from '../shared/config/config'

const baseQuery = fetchBaseQuery({
    baseUrl: deployLinks.server,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const companyParam = currentCompanyName ? `&company=${currentCompanyName}` : ''

const baseQueryWithReauth = async (args: any, queryApi: BaseQueryApi, extraOptions: {}) => {
    const { dispatch, getState } = queryApi
    let result = await baseQuery(args, queryApi, extraOptions)

    if (result.error && result.error.status === 401) {
        const refreshToken = (getState() as RootState).auth.refreshToken

        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh',
                method: 'POST',
                body: { token: refreshToken },
            },
            queryApi,
            extraOptions
        )

        if (refreshResult.data) {
            dispatch(setCredentials(refreshResult.data as LoginResponse))
            result = await baseQuery(args, queryApi, extraOptions)
        } else {
            localStorage.removeItem('auth')
            window.location.replace(`${deployLinks.core}?source=resource_managment${companyParam}`)
        }
    }

    return result
}

export const api = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Profile',
        'Works',
        'ProfileByCompany',
        'Users',
        'Projects',
        'MSG',
        'Company',
        'SNZ',
        'Mobile',
        'KsgPeople',
        'KsgEquip',
        'Headers',
        'MsgPeople',
        'GetTemplatesState',
        'Eis',
        'ImportModules',
        'Notifications',
        'ListNameMsg',
    ],
    refetchOnMountOrArgChange: true,
    endpoints: () => ({}),
})
