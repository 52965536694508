import { PropsWithChildren } from 'react'
import { Notificator } from '../Notificator/Notificator'
import { Container, Text } from './NotificatorContainer.styles'

interface IProps extends PropsWithChildren {
    isVisible: boolean
    title: NonNullable<React.ReactNode>
}

export const NotificatorContainer: React.FC<IProps> = ({ children, isVisible, title }) => {
    return (
        <Container>
            <Text>{children}</Text>
            {isVisible && <Notificator title={title} />}
        </Container>
    )
}
