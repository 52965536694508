import { useNavigate } from 'react-router-dom'
import PageNotFoundIcon from '../../shared/SVG/PageNotFoundIcon'
import { theme } from '../../styles/theme'
import { FlexColumnWrapper } from '../NewExecutorView/components/components.styles'
import { StyledBtn } from '../WorkManagment/components/components.styles'
import { IPageNotFoundProps } from './PageNotFound.def'
import { Typography } from '@mui/material'

export function PageNotFound({ children, hideNavBtn, IconComponent  }: IPageNotFoundProps) {
    const navigate = useNavigate()

    return (
        <FlexColumnWrapper
            gap={5}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={10}
            bgcolor={theme.palette.bg.gray}
            height={'100%'}
        >
            {IconComponent || <PageNotFoundIcon />}
            {children}
            {!hideNavBtn && (
                <StyledBtn variant="outlined" onClick={() => navigate('/')}>
                    На главную
                </StyledBtn>
            )}
        </FlexColumnWrapper>
    )
}
