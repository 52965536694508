import { CPGHeader, Work } from '../../../api/ksg/ksg.def'
import { getIndexParent, getIndexParentHeaders } from '../Ksg.service'
import React from 'react'
import LineEl from './LineEl'
import { useSearchParams } from 'react-router-dom'

type coordinatesArray = {
    id: number
    bondId: number
    start: {
        numOrder: number | undefined
        left: number | undefined
        critical: boolean
    }
    end: {
        numOrder: number | undefined
        left: number | undefined
        critical: boolean
    }
}[]

export default function LinesContainer({
    width,
    height,
    works,
    headers,
    rowHeight,
    hiddenRowsIds,
    allWorks,
}: {
    width: number
    height: number
    works: Work[] | undefined
    headers: CPGHeader[] | undefined
    rowHeight: number
    hiddenRowsIds: { id: number; code: string }[] | undefined
    allWorks: Work[] | undefined
}) {
    const searchParams = new URLSearchParams(document.location.search)
    const arrayForCharts = () => {
        const finalArr: coordinatesArray = []

        const createCoordinatesAfter = () => {
            works?.forEach((work, index) => {
                const getChildren = (wrk: Work) => {
                    const workCode = wrk.code.split('-')
                    let newArr: Work[] = []
                    allWorks?.forEach((wr) => {
                        const codeSplit = wr.code.split('-')
                        const checker = (arr: string[], target: string[]) => target.every((v) => arr.includes(v))
                        if (checker(codeSplit, workCode)) {
                            if (wr.id === wrk.id) return
                            newArr.push(wr)
                        }
                    })
                    return newArr
                }

                if (
                    !!getChildren(work).length &&
                    getChildren(work).every(
                        (item) => !!hiddenRowsIds && hiddenRowsIds.findIndex((inc) => inc.id === item.id) > -1
                    )
                ) {
                    getChildren(work).forEach((wr) => {
                        wr.workBonds.worksAfter.forEach((bond) => {
                            finalArr.push({
                                id: work.id,
                                bondId: bond,
                                start: {
                                    numOrder: index,
                                    left:
                                        (headers?.findIndex(
                                            (fi) =>
                                                fi.year === Number(work.workPeriod.end?.split('.')[2]) &&
                                                fi.month === Number(work.workPeriod.end?.split('.')[1])
                                        ) || 0) + 1,
                                    critical: wr.critical,
                                },
                                end: {
                                    numOrder: hiddenRowsIds?.find((f) => f.id === bond)
                                        ? getIndexParent(bond, allWorks, works)
                                        : works?.findIndex((work) => work.id === bond),
                                    left: hiddenRowsIds?.find((f) => f.id === bond)
                                        ? getIndexParentHeaders(bond, allWorks, works, headers, 'start')
                                        : headers?.findIndex(
                                              (fi) =>
                                                  fi.year ===
                                                      Number(
                                                          works
                                                              ?.filter((work) => work.id === bond)[0]
                                                              ?.workPeriod.start?.split('.')[2]
                                                      ) &&
                                                  fi.month ===
                                                      Number(
                                                          works
                                                              ?.filter((work) => work.id === bond)[0]
                                                              ?.workPeriod.start?.split('.')[1]
                                                      )
                                          ),
                                    critical: wr.critical,
                                },
                            })
                        })
                    })
                } else {
                    work.workBonds.worksAfter.forEach((bond) => {
                        finalArr.push({
                            id: work.id,
                            bondId: bond,
                            start: {
                                numOrder: index,
                                left:
                                    (headers?.findIndex(
                                        (fi) =>
                                            fi.year === Number(work.workPeriod.end?.split('.')[2]) &&
                                            fi.month === Number(work.workPeriod.end?.split('.')[1])
                                    ) || 0) + 1,
                                critical: work.critical,
                            },
                            end: {
                                numOrder: hiddenRowsIds?.find((f) => f.id === bond)
                                    ? getIndexParent(bond, allWorks, works)
                                    : works?.findIndex((work) => work.id === bond),
                                left: hiddenRowsIds?.find((f) => f.id === bond)
                                    ? getIndexParentHeaders(bond, allWorks, works, headers, 'start')
                                    : headers?.findIndex(
                                          (fi) =>
                                              fi.year ===
                                                  Number(
                                                      works
                                                          ?.filter((work) => work.id === bond)[0]
                                                          ?.workPeriod.start?.split('.')[2]
                                                  ) &&
                                              fi.month ===
                                                  Number(
                                                      works
                                                          ?.filter((work) => work.id === bond)[0]
                                                          ?.workPeriod.start?.split('.')[1]
                                                  )
                                      ),
                                critical: hiddenRowsIds?.find((f) => f.id === bond)
                                    ? works[getIndexParent(bond, allWorks, works) || 0]?.critical || false
                                    : works?.find((work) => work.id === bond)?.critical || false,
                            },
                        })
                    })
                }
            })
        }

        createCoordinatesAfter()

        let uniqueArray: coordinatesArray = []

        finalArr.forEach((v) => {
            if (!uniqueArray.find((f) => JSON.stringify(f) === JSON.stringify(v))) {
                uniqueArray.push(v)
            }
        })
        return uniqueArray.filter((v) => v.end.numOrder !== v.start.numOrder)
    }

    return (
        <svg width={width} height={height}>
            {arrayForCharts().map((v, index) => {
                const {left=0, numOrder=0} = v.end
                if (left >= 0 && numOrder >= 0)
                    return (
                        <LineEl
                            bgColor={
                                (v.start.critical && v.end.critical && searchParams.get('criticalPath') && 'red') ||
                                undefined
                            }
                            key={index}
                            x1={(v.start.left || 0) * 130 - 19}
                            y1={(v.start.numOrder || 0) * rowHeight + rowHeight / 2}
                            x2={(v.end.left || 0) * 130 + 15}
                            y2={(v.end.numOrder || 0) * rowHeight + rowHeight / 2}
                        />
                    )
            })}
        </svg>
    )
}
