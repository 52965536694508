import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DownloadIcon from '@mui/icons-material/Download'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'
import { useGetModulesStatusesQuery } from '../../api/importingData/importingData.api'
import { useGetExcelQuery } from '../../api/projects/projects.api'
import { useUploadExcelMostMutation, useUploadExcelMutation } from '../../api/works/works.api'
import { uploadExcelReq } from '../../api/works/works.def'
import placeImg from '../../pages/Projects/component/Headline.png'
import s from '../../pages/Projects/component/ProjectsDrawer.module.scss'
import ErrorsList from '../../shared/components/ErrorsList/ErrorsList'
import { IUploadDrawerStatus } from '../../shared/def'
import useProjectUploadProgress from '../../shared/hooks/useProjectUploadProgress'
import { onKSGUpload } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { drawersSelector, toggleUploadKsg } from '../../store/slices/drawersSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { SyncProjects } from '../Synchronize/components/SyncProjects'
import { ICheckboxSettings, initialsParams, uploadNames1S, uploadNamesBase, uploadType } from './UploadDrawer.service'
import CheckParams from './components/CheckParams'
import DescriptionAndSelectUploadDrawer from './components/DescriptionAndSelectUploadDrawer'
import UploadAgain from './components/UploadAgain'
import UploadButtons from './components/UploadButtons'

export default function UploadDrawerKsg() {
    const [showErrorsList, setShowErrorsList] = useState(false)
    const [showUploadAgain, setShowUploadAgain] = useState(false)
    const [uploadType, setUploadType] = useState<uploadType>('Стандартный КСГ')
    const [uploadExRes, setUploadExRes] = useState<any>({})
    const { activeTask, setTriggerUpdateStatus, result } = useProjectUploadProgress({
        supressOnClose: true,
        supressWatchBtn: true,
    })
    const { uploadKsg } = useTypedSelector(drawersSelector)
    const [uploadStatus, setUploadStatus] = useState<IUploadDrawerStatus>('readyForDrop')

    /**
     * Состояние чекбоксов
     */
    const [checks, setChecks] = useState(initialsParams)

    /**
     * Функция изменения состояния чекбоксов
     * @param name - имя свойства объекта
     */
    const changeParams = (param: ICheckboxSettings) => {
        setChecks((prevState) => ({
            ...prevState,
            [param.name]: !prevState[param.name],
            ...(param.contradactoryField && {
                [param.contradactoryField]: false,
            }),
        }))
    }

    /**
     * список активных имен чекбоксов для отправки на сервер
     */
    const actualNames = useMemo(() => {
        return uploadType === '1С Шаблон' ? uploadNames1S : uploadNamesBase
    }, [uploadType])

    const { projectId } = useParams()
    const { t } = useTranslation('files')
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useAppDispatch()

    const exLinkKsg = useGetExcelQuery()
    const modulesList = useGetModulesStatusesQuery(
        {
            id: Number(projectId),
        },
        {
            skip: uploadType !== 'Интеграция с "Ценообразование"',
        }
    )

    const handleSendingReqBool = () => {
        return modulesList.data?.data.find((f) => f.module === 'PragmaOffer')?.status === 'АКТИВНО'
    }

    const [uploadExKsgReq, uploadExKsgRes] = useUploadExcelMutation()
    const [uploadExKsgMostReq, uploadExKsgMostRes] = useUploadExcelMostMutation()

    const uploadExReq = useCallback(
        (obj: uploadExcelReq) => {
            if (uploadType === 'Стандартный КСГ') {
                return uploadExKsgReq(obj)
            } else {
                return uploadExKsgMostReq(obj)
            }
        },
        [uploadExKsgMostReq, uploadExKsgReq, uploadType]
    )

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setUploadStatus('loading')
            acceptedFiles.forEach((file: File) => {
                const formData = new FormData()

                formData.append('file', file)

                /* Выбираются только нужные свойства из объекта */
                let check: keyof typeof checks
                for (check in checks) {
                    // if (actualNames.includes(check)) {
                    formData.append(check, String(checks[check]))
                    // }
                }
                uploadExReq({
                    id: Number(projectId),
                    body: formData,
                })
                    .unwrap()
                    // .then(() => dispatch(onKSGUpload()))
                    .catch((e) => {
                        console.error(e)
                        setUploadStatus('error')
                    })
            })
        },
        [projectId, uploadExReq, checks, actualNames]
    )

    function onDropRejected() {
        enqueueSnackbar(t('upload.wrong_extension'), {
            variant: 'error',
        })
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
    })

    useEffect(() => {
        if (uploadType === 'Стандартный КСГ') {
            return setUploadExRes(uploadExKsgRes)
        } else {
            return setUploadExRes(uploadExKsgMostRes)
        }
    }, [uploadExKsgMostRes, uploadExKsgRes, uploadType])

    useEffect(() => {
        uploadKsg && setTriggerUpdateStatus(true)
    }, [uploadKsg])

    useEffect(() => {
        activeTask?.result?.error && setShowUploadAgain(true)
    }, [activeTask?.result?.error])

    useEffect(() => {
        result?.error && setUploadStatus('error')
        result?.success && setUploadStatus('success')
    }, [result])

    useEffect(() => {
        uploadStatus === 'success' && dispatch(onKSGUpload())
    }, [dispatch, uploadStatus])

    // useEffect(() => {

    // }, )

    /**
     * Эффект следит за изменение селекта
     * После смен очищается состояние чекбоксов
     */
    useEffect(() => {
        setChecks(initialsParams)
    }, [uploadType])

    function disabledButton() {
        return uploadStatus === 'readyForDrop' || uploadStatus === 'loading'
    }

    if (showErrorsList) {
        return <ErrorsList setShowErrorsList={setShowErrorsList} errors={result?.error} title="Информация об ошибках" />
    }

    let loaderContent = <></>

    switch (uploadStatus) {
        case 'readyForDrop':
            loaderContent = (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={placeImg} alt="" />
                </div>
            )
            break
        case 'loading':
            loaderContent = <FadeLoader />
            break
        case 'success':
            loaderContent = (
                <div className={s.awd_loader_success}>
                    <CheckCircleIcon
                        sx={{
                            color: '#22c55e',
                            fontSize: '2rem',
                        }}
                    />
                    <p>
                        Успешно обработано! <br /> Всего добавлено строк: {result?.create}
                    </p>
                </div>
            )
            break
        case 'error':
            loaderContent = (
                <UploadAgain
                    length={result?.error?.length}
                    setShowUploadAgain={setShowUploadAgain}
                    setShowErrorsList={setShowErrorsList}
                    onUploadAgain={() => setUploadStatus('readyForDrop')}
                />
            )
            break
    }

    return (
        <main className={s.main_awd}>
            <h1>Загрузка КСГ</h1>
            <div className={s.awd_container}>
                <DescriptionAndSelectUploadDrawer uploadType={uploadType} setUploadType={setUploadType} />

                {uploadType !== 'Интеграция с "Ценообразование"' ? (
                    <div className={s.awd_loader}>{loaderContent}</div>
                ) : (
                    <div
                        className={s.wrapper}
                        style={{
                            height: 'calc(100vh - 320px)',
                        }}
                    >
                        <SyncProjects moduleName={'PragmaOffer'} sendingReqBool={handleSendingReqBool} height={524} />
                    </div>
                )}
                {uploadType !== 'Интеграция с "Ценообразование"' && (
                    <CheckParams
                        activeType={uploadType === '1С Шаблон' ? '1C' : 'Standart'}
                        checkFn={changeParams}
                        mode={'upload'}
                        state={checks}
                        disabled={uploadStatus === 'loading'}
                    />
                )}

                {uploadType === 'Стандартный КСГ' && (
                    <div className={s.awd_download}>
                        <DownloadIcon />
                        <p onClick={() => window.open(exLinkKsg.data?.data, '_blank')}>СКАЧАТЬ ФОРМУ ДЛЯ КСГ</p>
                    </div>
                )}

                {uploadType !== 'Интеграция с "Ценообразование"' ? (
                    <UploadButtons
                        disableButtons={disabledButton()}
                        isLoading={uploadStatus === 'loading'}
                        onClose={() => dispatch(toggleUploadKsg())}
                    />
                ) : (
                    <div></div>
                )}
            </div>
        </main>
    )
}
