import { FormControl, MenuItem, Select } from '@mui/material';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useLayoutEffect, useState } from 'react';

import { useIsMounted } from '@/hooks/useIsMounted';

import { stylesLevelAg } from '@/pages/AgGrid/AgGrid.service';
import { StyledCheckbox } from '@/pages/AgGrid/StyledCheckbox';
import { FormError } from '@/pages/Projects/component/FormikFormC';

import { Buttons } from '@/shared/components/ButtonsAndWarning';

import { ICreateKSGInitialFormValues, createKSGInitialFormValues, createKSGValidationSchema } from './AddLevel.service';
import styles from './AddLevelKsg.module.scss';
import DateLine from './components/DateLine';
import TextLineEl from './components/TextLineEl';
import WideTextLineEl from './components/WideTextLineEl';

type TProps = {
    onClose: () => void;
    setHasValues: (value: boolean) => void;
    onSubmit: (values: any, tag: boolean) => void;
};

export const CreateKsg: React.FC<TProps> = ({ setHasValues, onClose, onSubmit }) => {
    const [tag, setTag] = useState(false);
    const isMounted = useIsMounted();

    const handleSubmit = (values: ICreateKSGInitialFormValues) => {
        onSubmit(values, tag);
    };

    const formik = useFormik<ICreateKSGInitialFormValues>({
        initialValues: createKSGInitialFormValues,
        onSubmit: handleSubmit,
        validationSchema: createKSGValidationSchema,
    });

    const hasValues = Object.values(formik.values).some((v) => v);

    useEffect(() => {
        setHasValues(hasValues);
    }, [hasValues]);

    useLayoutEffect(() => {
        (() => formik.validateForm())();
    }, []);

    const isDisabled = () => {
        if (!isMounted) return true;
        return (!formik.isValid && Object.values(formik.touched).every((item) => item)) || formik.isSubmitting;
    };

    const numberProps = { type: 'number', step: 0.000001, min: 0, max: 99999999 };

    return (
        <>
            <div className={styles.top}>
                <h4>Создание уровня</h4>
                <div className={cn(stylesLevelAg(1), styles.level1Create)}>УР 1</div>
            </div>

            <div className={cn(styles.main, styles.mainCreate)}>
                <h4 className={styles.main_title}>Заполните обязательные поля</h4>
                <form
                    onSubmit={formik.handleSubmit}
                    className={styles.main_form}
                >
                    <TextLineEl
                        title='Титул'
                        errorTitle={formik.errors.objTitle}
                        touched={formik.touched.objTitle}
                        {...formik.getFieldProps('objTitle')}
                        required
                    />

                    <TextLineEl
                        title='Объект'
                        errorTitle={formik.errors.objName}
                        touched={formik.touched.objName}
                        {...formik.getFieldProps('objName')}
                        required
                    />

                    <TextLineEl
                        title='Раздел РД'
                        errorTitle={formik.errors.rdGroup}
                        touched={formik.touched.rdGroup}
                        required
                        {...formik.getFieldProps('rdGroup')}
                    />

                    <TextLineEl
                        title='Шифр РД'
                        errorTitle={formik.errors.rdCode}
                        touched={formik.touched.rdCode}
                        required
                        {...formik.getFieldProps('rdCode')}
                    />

                    <div className={styles.input_line}>
                        <p>Статус выдачи РД:</p>

                        <FormControl
                            sx={{
                                width: '10.375rem',
                            }}
                        >
                            <Select
                                sx={{
                                    backgroundColor: '#F6F7FB',

                                    '.MuiSelect-select': {
                                        padding: '0.3rem .5rem',
                                        borderRadius: '6px',
                                        fontSize: 14,
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(43, 54, 72, 0.12)',
                                        borderWidth: '1px !important',
                                    },
                                }}
                                {...formik.getFieldProps('rdStatus')}
                                error={!!formik.errors.rdStatus?.length}
                            >
                                {[
                                    'Согласовано',
                                    'На согласовании',
                                    'Корректировка',
                                    'Разработка',
                                    'В производстве',
                                    'Не в работе',
                                ].map((v) => (
                                    <MenuItem
                                        key={v}
                                        value={v}
                                    >
                                        {v} проект
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormError
                                isTouched={formik.touched.rdStatus}
                                error={formik.errors.rdStatus}
                                right
                            />
                        </FormControl>
                    </div>
                    <DateLine
                        errorTitle={formik.errors.rdDateApproval}
                        touched={formik.touched.rdDateApproval}
                        title='Дата согласования РД'
                        onChange={(newValue) => {
                            formik.setFieldValue('rdDateApproval', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                rdDateApproval: true,
                            })
                        }
                        value={formik.values.rdDateApproval}
                    />
                    <TextLineEl
                        title='Версия РД'
                        errorTitle={formik.errors.rdVersion}
                        touched={formik.touched.rdVersion}
                        {...formik.getFieldProps('rdVersion')}
                        {...numberProps}
                    />
                    <DateLine
                        errorTitle={formik.errors.rdDate}
                        touched={formik.touched.rdDate}
                        title='Дата выдачи РД'
                        onChange={(newValue) => {
                            formik.setFieldValue('rdDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                rdDate: true,
                            })
                        }
                        value={formik.values.rdDate}
                    />
                    <WideTextLineEl
                        title='Наименование работы'
                        errorTitle={formik.errors.workName}
                        touched={formik.touched.workName}
                        {...formik.getFieldProps('workName')}
                        required
                    />
                    <TextLineEl
                        title='Головная группа работ'
                        errorTitle={formik.errors.groupOfWorks}
                        touched={formik.touched.groupOfWorks}
                        required
                        {...formik.getFieldProps('groupOfWorks')}
                    />
                    <TextLineEl
                        title='Конструктив (Вид работ)'
                        errorTitle={formik.errors.typeOfWorks}
                        touched={formik.touched.typeOfWorks}
                        required
                        {...formik.getFieldProps('typeOfWorks')}
                    />
                    <div className={styles.input_line}>
                        <p>Прочие работы:</p>
                        <StyledCheckbox
                            checked={tag}
                            onChange={(e) => setTag(e.target.checked)}
                        />
                    </div>
                    <TextLineEl
                        title='Ключевые объемы'
                        errorTitle={formik.errors.mainGroup}
                        touched={formik.touched.mainGroup}
                        {...formik.getFieldProps('mainGroup')}
                    />
                    <WideTextLineEl
                        title='Группа работ'
                        errorTitle={formik.errors.workGroup}
                        touched={formik.touched.workGroup}
                        required
                        {...formik.getFieldProps('workGroup')}
                    />
                    <TextLineEl
                        title='Подрядчик'
                        errorTitle={formik.errors.contractorCompany}
                        touched={formik.touched.contractorCompany}
                        required
                        {...formik.getFieldProps('contractorCompany')}
                    />

                    <TextLineEl
                        title='Единица измерения'
                        errorTitle={formik.errors.unit}
                        touched={formik.touched.unit}
                        required
                        {...formik.getFieldProps('unit')}
                    />
                    <TextLineEl
                        title='Общий объем'
                        errorTitle={formik.errors.volumeTotal}
                        touched={formik.touched.volumeTotal}
                        required
                        {...formik.getFieldProps('volumeTotal')}
                        {...numberProps}
                    />

                    <DateLine
                        errorTitle={formik.errors.operationalStartDate}
                        touched={formik.touched.operationalStartDate}
                        title='Опер.план дата начала'
                        onChange={(newValue) => {
                            formik.setFieldValue('operationalStartDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                operationalStartDate: true,
                            })
                        }
                        value={formik.values.operationalStartDate}
                        required
                    />
                    <DateLine
                        errorTitle={formik.errors.operationalEndDate}
                        touched={formik.touched.operationalEndDate}
                        title='Опер.план дата окончания'
                        onChange={(newValue) => {
                            formik.setFieldValue('operationalEndDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                operationalEndDate: true,
                            })
                        }
                        value={formik.values.operationalEndDate}
                        required
                    />
                    <DateLine
                        errorTitle={formik.errors.startDate}
                        touched={formik.touched.startDate}
                        title='Баз.план дата начала'
                        onChange={(newValue) => {
                            formik.setFieldValue('startDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                startDate: true,
                            })
                        }
                        value={formik.values.startDate}
                        required
                    />
                    <DateLine
                        errorTitle={formik.errors.endDate}
                        touched={formik.touched.endDate}
                        title='Баз.план дата окончания'
                        onChange={(newValue) => {
                            formik.setFieldValue('endDate', newValue);
                        }}
                        onBlur={() =>
                            formik.setTouched({
                                ...formik.touched,
                                endDate: true,
                            })
                        }
                        value={formik.values.endDate}
                        required
                    />
                    <TextLineEl
                        title='Трудозатраты на ед. изм., чел-час'
                        titleAsNode={
                            <>
                                Трудозатраты на ед. изм., <br /> чел-час
                            </>
                        }
                        errorTitle={formik.errors.humanHourCost}
                        touched={formik.touched.humanHourCost}
                        required
                        {...formik.getFieldProps('humanHourCost')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title='Затраты МиМ на ед.изм., маш-час'
                        titleAsNode={
                            <>
                                Затраты МиМ на ед.изм., <br /> маш-час
                            </>
                        }
                        errorTitle={formik.errors.machineHourCost}
                        touched={formik.touched.machineHourCost}
                        required
                        {...formik.getFieldProps('machineHourCost')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title='Стоимость ЕР (Дог.+ДР), руб.'
                        titleAsNode={
                            <>
                                Стоимость ЕР (Дог.+ДР), <br /> руб.
                            </>
                        }
                        errorTitle={formik.errors.costER}
                        touched={formik.touched.costER}
                        required
                        {...formik.getFieldProps('costER')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title='Стоимость ЕР (Договор), руб.'
                        titleAsNode={
                            <>
                                Стоимость ЕР (Договор), <br /> руб.
                            </>
                        }
                        errorTitle={formik.errors.costERGK}
                        touched={formik.touched.costERGK}
                        {...formik.getFieldProps('costERGK')}
                        {...numberProps}
                    />

                    <TextLineEl
                        title='Стоимость ЕР (Расчет), руб.'
                        errorTitle={formik.errors.costERSub}
                        touched={formik.touched.costERSub}
                        {...formik.getFieldProps('costERSub')}
                        {...numberProps}
                    />

                    <button
                        type='submit'
                        style={{
                            display: 'none',
                        }}
                    ></button>
                </form>
            </div>
            <div className={styles.buttons}>
                <Buttons
                    loading={formik.isSubmitting}
                    onClose={onClose}
                    onSubmit={formik.handleSubmit}
                    submitText='сохранить'
                    cancelText='отменить'
                    disabled={isDisabled()}
                />
            </div>
        </>
    );
};
