import { Button, Box, CircularProgress } from '@mui/material'
import {
    StyledDrawer,
    StyledDrawerContent,
    StyledDrawerAlert,
    StyledDrawerSearch,
} from '../../../../components/StyledDrawer/StyledDrawer.styles'
import { FlexColumnWrapper, FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'
import { ICopyResourcesDrawerProps, IWorkForCopy } from './CopyResourcesDrawer.def'
import { workListColDef } from './WorkList/WorksList.colDef'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { OverflowableTypographyWithTooltip } from '../../../../components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip'
import { GetRowIdParams, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog'
import { StyledTextBtn, StyledWorkListCopyAgGrid } from './WorkList/WorkListStyles'
import { useSnackbar } from 'notistack'
import { drawersSelector } from '../../../../store/slices/drawersSlice'
import { useTypedSelector } from '../../../../store/store'
import { COPY_MODE_PARAMS, onSubmitRejected } from './CopyResourcesDrawer.service'

export function CopyResourcesDrawer({
    DrawerProps,
    title,
    descriptionSection,
    warningSection,
    copyMode,
    getWorkListMethod,
    onCopy,
}: ICopyResourcesDrawerProps) {
    const { projectId, workID } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [workList, setWorkList] = useState<IWorkForCopy[] | null>(null)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
    const [isFetching, setIsFetching] = useState<boolean>(false)
    const gridRef = useRef<AgGridReact>(null)

    const {
        workManagmentResourcesGridRefs: { setTriggerRefresh },
    } = useTypedSelector(drawersSelector)

    function extractChosenRowData() {
        let extractedData: Partial<IWorkForCopy>[] = []
        gridRef?.current?.api?.forEachNode((node) => {
            node?.data?.checked && extractedData?.push(node.data)
        })
        return extractedData.map(
            (work) =>
                ({
                    choice: {
                        ...work.choice,
                    },
                    workId: work.workId,
                } as Partial<IWorkForCopy>)
        )
    }

    const BTNS_DISABLED = !workList || workList?.length === 0

    function onSubmit() {
        const body = extractChosenRowData()
        if (body.length === 0) {
            return enqueueSnackbar('Выберите работу', { variant: 'error' })
        }
        if (onCopy) {
            onCopy(extractChosenRowData(), setIsFetching)
            return
        }
        setIsFetching(() => true)

        COPY_MODE_PARAMS[copyMode]
            .submitReq({
                projectId: projectId as string,
                workID: workID as string,
                body: body,
            })
            .then(() => {
                DrawerProps.onClose && DrawerProps.onClose({}, 'backdropClick')
                copyMode !== 'into' && setTriggerRefresh && setTriggerRefresh((prevState) => !prevState)
                setIsFetching(() => false)
            })
            .catch((e) => {
                onSubmitRejected({
                    error: e,
                    enqueueSnackbar: enqueueSnackbar,
                    setIsFetching: setIsFetching,
                })
            })
    }

    function onSearch(value: string) {
        gridRef.current?.api.setQuickFilter(value)
    }

    function triggerOnClose() {
        let hasCheckedWorks = false
        gridRef.current?.api.forEachNode((node) => {
            if (node.data.checked) {
                hasCheckedWorks = true
            }
        })
        if (hasCheckedWorks) {
            setIsConfirmDialogOpen(() => true)
        } else {
            DrawerProps.onClose && DrawerProps.onClose({}, 'backdropClick')
        }
    }

    function checkAll() {
        reset()
        gridRef?.current?.api.forEachNodeAfterFilter((row) => {
            gridRef?.current?.api.applyTransaction({ update: [{ ...row.data, checked: true }] })
            gridRef?.current?.api.redrawRows({ rowNodes: [row] })
        })
    }
    function reset() {
        gridRef?.current?.api.forEachNode((row) => {
            gridRef?.current?.api.applyTransaction({ update: [{ ...row.data, checked: false, choice: {
                ...row.data?.choice,
                ...row.data.initialChoice
            } }] })
            gridRef?.current?.api.redrawRows({ rowNodes: [row] })
        })
    }

    useEffect(() => {
        const targetReq = getWorkListMethod
            ? getWorkListMethod()
            : COPY_MODE_PARAMS[copyMode].getListReq({
                  projectId: projectId as string,
                  workID: workID as string,
              })
        if (DrawerProps.open === true) {
            targetReq.then(({ data }: any) => {
                setWorkList((prevState) =>
                    data.data.map((work: IWorkForCopy) => ({
                        ...work,
                        checked: false,
                        initialChoice: {
                            ...work.choice,
                        },
                    }))
                )
            })
        }
        return () => {
            setWorkList(() => null)
        }
    }, [DrawerProps.open, copyMode, projectId, workID])

    return (
        <StyledDrawer {...DrawerProps} onClose={triggerOnClose} width={800} sx={{ position: 'relative' }}>
            {title ? title : COPY_MODE_PARAMS[copyMode].title}
            <StyledDrawerContent sx={{ pointerEvents: isFetching ? 'none' : 'auto' }}>
                {descriptionSection ? descriptionSection : COPY_MODE_PARAMS[copyMode].descriptionSection}
                <FlexColumnWrapper gap={0}>
                    <StyledDrawerAlert>ВНИМАНИЕ!</StyledDrawerAlert>
                    {warningSection ? warningSection : COPY_MODE_PARAMS[copyMode].warningSection}
                </FlexColumnWrapper>

                <StyledDrawerSearch
                    placeholder="Поиск"
                    onChange={(e) => onSearch(e.target.value)}
                    disabled={BTNS_DISABLED}
                />
                <FlexRowWrapper justifyContent={'flex-start'} alignItems={'center'} width={'100%'}>
                    <StyledTextBtn onClick={() => checkAll()} disabled={BTNS_DISABLED}>
                        Выбрать все
                    </StyledTextBtn>
                    <StyledTextBtn onClick={() => reset()} disabled={BTNS_DISABLED}>
                        Сбросить
                    </StyledTextBtn>
                </FlexRowWrapper>

                <Box style={{ height: '100%', width: '100%', paddingRight: '0.5rem' }}>
                    <StyledWorkListCopyAgGrid
                        ref={gridRef}
                        columnDefs={workListColDef}
                        rowData={workList}
                        headerHeight={40}
                        rowHeight={50}
                        defaultColDef={{
                            cellRenderer: (params: ICellRendererParams) => (
                                <OverflowableTypographyWithTooltip
                                    maxRows={2}
                                    TooltipProps={{
                                        leaveDelay: 0,
                                        placement: 'right',
                                        TransitionProps: {
                                            timeout: 0,
                                        },
                                    }}
                                >
                                    {params.value}
                                </OverflowableTypographyWithTooltip>
                            ),
                        }}
                        context={{
                            copyMode: copyMode,
                        }}
                        getRowId={(params: GetRowIdParams<any>) => {
                            return params.data?.workId
                        }}
                        overlayNoRowsTemplate={'Нет данных'}
                    />
                </Box>
                <FlexRowWrapper width={'60%'} mt={'auto'}>
                    {copyMode !== 'delete' ? (
                        <Button
                            size="medium"
                            color={'success'}
                            variant="contained"
                            fullWidth
                            onClick={(e) => onSubmit()}
                            disabled={BTNS_DISABLED || isFetching}
                            startIcon={isFetching && <CircularProgress size={20} />}
                        >
                            Скопировать
                        </Button>
                    ) : (
                        <Button
                            size="medium"
                            color={'error'}
                            variant="contained"
                            fullWidth
                            onClick={(e) => onSubmit()}
                            disabled={BTNS_DISABLED || isFetching}
                            startIcon={isFetching && <CircularProgress size={20} />}
                            sx={{ color: 'white' }}
                        >
                            Удалить ресурсы
                        </Button>
                    )}
                    <Button size="medium" variant="contained" fullWidth onClick={triggerOnClose} disabled={isFetching}>
                        Отменить
                    </Button>
                </FlexRowWrapper>
            </StyledDrawerContent>
            <ConfirmDialog
                open={isConfirmDialogOpen}
                title={'Вы уверены, что хотите завершить копирование'}
                message={'Все несохраненные данные будут удалены'}
                onYes={{
                    action: (e) => {
                        DrawerProps.onClose && DrawerProps.onClose({}, 'backdropClick')
                        setIsConfirmDialogOpen(() => false)
                    },
                }}
                onNo={{
                    action: (e) => {
                        setIsConfirmDialogOpen(() => false)
                    },
                }}
            />
        </StyledDrawer>
    )
}
