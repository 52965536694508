import { useMemo } from 'react';

import { Work } from '@/api/ksg/ksg.def';

import { filtersSelector } from '@/store/slices/filtersSlice';
import { useTypedSelector } from '@/store/store';

export const useGetAppliedFilters = () => {
    const { filters } = useTypedSelector(filtersSelector);

    const appliedFilters = useMemo(() => {
        const result = [];

        // TODO:
        // 1. Отрефакторить все условия фильтров
        if (filters.workGroupList.length > 0 || filters.showEmptyWorkGroups) {
            result.push('workGroup');
        }

        if (filters.rdCodes.length > 0) {
            result.push('rdCode');
        }

        if (filters.contractors.length > 0) {
            result.push('contractorCompany');
        }

        if (filters.undistributedBaseVolume || filters.undistributedOperationalVolume) {
            result.push('volumeTotal');
        }

        if (Object.values(filters.workName).some((v) => v.length > 0)) {
            result.push('workName');
        }

        if (Object.values(filters.objTitle).some((v) => v.length > 0)) {
            result.push('objTitle');
        }

        if (Object.values(filters.objName).some((v) => v.length > 0)) {
            result.push('objName');
        }

        if (Object.values(filters.rdStatus).some((v) => v.length > 0)) {
            result.push('rdStatus');
        }

        return result;
    }, [filters]);

    return appliedFilters;
};

export const useGetMinMaxLevels = (works: Work[] | undefined, listMode: boolean) => {
    const [maxLevel, minLevel] = useMemo(() => {
        if (!works) return [null, null];

        const levels = works.map((v) => v.level) as number[];
        return [Math.max(...levels), Math.min(...levels)];
    }, [works]);

    const minLevelByListMode = listMode ? minLevel : null;

    return {
        maxLevel: maxLevel,
        minLevel: minLevel,
        minLevelByListMode: minLevelByListMode,
    };
};
