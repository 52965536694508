import { CircularProgress } from '@mui/material'
import { CellEditingStoppedEvent } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { Dispatch, SetStateAction } from 'react'
import { ResourceAPI } from '../../../api/ResourcesAPI'
import { addNewMim, addNewStaff, getOrCreateProf } from '../ResourcePage.service'
import { StyledLoadingOverlay } from '../ResourcesPage.styles'
import { mimTableColumnDef } from './ColDefs/MimTable.colDef'
import { mtoTableColumnDef } from './ColDefs/MtoTable.colDef'
import { staffTableColumnDef } from './ColDefs/StaffTable.colDef'

export const PARAMS = {
    staff: {
        colDef: staffTableColumnDef,
        suffix: ' ч.ч.',
    },
    mim: {
        colDef: mimTableColumnDef,
        suffix: ' м.ч.',
    },
    mto: {
        colDef: mtoTableColumnDef,
        suffix: ' р.',
    },
}

export const STATUS_LIMITS = {
    error: 50,
    warning: 80,
    default: 95,
}

export function getStatus(value: number) {
    if (value <= STATUS_LIMITS.warning) {
        return 'error'
    } else if (value > STATUS_LIMITS.warning && value <= STATUS_LIMITS.default) {
        return 'warning'
    } else if (value > STATUS_LIMITS.warning) {
        return 'default'
    }
    return 'default'
}

export function getStaffAgGridProps(
    setIsConfirmDialogOpen: Dispatch<SetStateAction<boolean>>,
    setCellEditingStoppedEvent: Dispatch<SetStateAction<CellEditingStoppedEvent<any, any> | null>>
) {
    return {
        onCellEditingStopped: (event) => {
            if (event.newValue === '' || event.newValue === null) {
                setIsConfirmDialogOpen(true)
                setCellEditingStoppedEvent(event)
            }
        },
        onCellValueChanged: async (event) => {
            const field = event.colDef.field
            const newValue = event.newValue
            const oldValue = event.oldValue

            if (newValue === '') return

            if (field === 'plan') {
                ResourceAPI.updateItem({
                    resourceID: event.data.id,
                    resourceType: event.context.resourceType,
                    projectID: event.context.projectID,
                    workID: event.context.workID,
                    body: { ...event.data, plan: newValue },
                }).then((data) => {
                    event.context.getTotals(event)
                })
                return
            }

            const prof = await getOrCreateProf(newValue, event.context.proffList)
            if (!prof) return

            if (field === 'nameProf' && oldValue === '') {
                addNewStaff(event, {
                    nameProf: prof.name,
                    profId: prof.id,
                    plan: 0,
                })
                return
            }

            ResourceAPI.updateItem({
                resourceID: event.data.id,
                resourceType: event.context.resourceType,
                projectID: event.context.projectID,
                workID: event.context.workID,
                body: { ...event.data, nameProf: prof.name, profId: prof.id },
            }).then((data) => {
                event.context.getTotals(event)
            })
        },
        loadingOverlayComponent: () => (
            <StyledLoadingOverlay>
                <CircularProgress />
            </StyledLoadingOverlay>
        ),
    } as AgGridReactProps
}

export function getMimAgGridProps(
    setIsConfirmDialogOpen: Dispatch<SetStateAction<boolean>>,
    setCellEditingStoppedEvent: Dispatch<SetStateAction<CellEditingStoppedEvent<any, any> | null>>,
    AgGrid: AgGridReact
) {
    return {
        columnDefs: AgGrid && mimTableColumnDef(AgGrid),
        onCellEditingStopped: (event) => {
            if (event.newValue === '' || (!event.newValue && event.data.id === 'draft')) {
                setIsConfirmDialogOpen(true)
                setCellEditingStoppedEvent(event)
            }
            // Variant without modal
            // const excludeNames: string[] = []
            // event?.api?.forEachNode((row) => excludeNames.push(row?.data?.name))
            // if (
            //     event.data.id === 'draft' &&
            //     (excludeNames.includes(event.newValue) || event.newValue === '' || !event.newValue)
            // ) {
            //     event.api.applyTransaction({
            //         remove: [{ id: 'draft' }],
            //     })
            // }
        },
        onCellValueChanged: (event) => {
            if (event.newValue === '') {
                return
            }
            if (event.colDef.field === 'name' && event.oldValue === '') {
                addNewMim(event)
                return
            }
            ResourceAPI.updateItem({
                resourceID: event.data.id,
                resourceType: event.context.resourceType,
                projectID: event.context.projectID,
                workID: event.context.workID,
                body: { ...event.data },
            }).then((data) => {
                event.context.getTotals(event)
            })
        },
        loadingOverlayComponent: () => (
            <StyledLoadingOverlay>
                <CircularProgress />
            </StyledLoadingOverlay>
        ),
    } as AgGridReactProps
}
