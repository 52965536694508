import { WritableDraft } from 'immer/dist/types/types-external'
import * as Yup from 'yup'
import { CPGHeader, Work } from '../../api/ksg/ksg.def'
import { workStatuses } from '../../api/works/works.def'
import g from '../../shared/stylesheets/TableGrid.module.scss'
import { initCpgTemplateStateType } from '../../store/slices/ksgTemplatesSlice'

const termPlusWidthObj = {
    level: 130,
    tag: 100,
    numOrder: 100,
    code: 130,
    objTitle: 260,
    objName: 300,
    rdGroup: 180,
    rdCode: 160,
    rdStatus: 225,
    rdDateApproval: 200,
    rdVersion: 180,
    rdDate: 180,
    workGroup: 180,
    contractorCompany: 180,
    workName: 300,
    unit: 130,
    volumeTotal: 180,
    verifiedFactTotal: 180,
    volumeDone: 360,
    volumeDonePlan: 180,
    volumeDoneFact: 180,
    startDate: 180,
    endDate: 180,
    humanHourCost: 180,
    humanHoursTotal: 180,
    humanHoursProgressTZ: 180,
    // humanHours:540,
    humanHoursProgressFact: 180,
    humanHoursProgressPlan: 180,
    humanHoursProgressPercent: 180,
    machineHourCost: 180,
    machineHoursTotal: 180,
    costER: 180,
    costTotal: 180,
    costTotalProgress: 180,
    // costProgress:540,
    costProgressPercent: 180,
    costProgressPlan: 180,
    costProgressFact: 180,
    costRemaining: 180,
    costDone: 180,
    costNZP: 180,
    prStart: 180,
    prEnd: 180,
    prProvision: 180,
    workPeriod: 180,
}

export const isWiderThanScreen = (object: Omit<initCpgTemplateStateType, 'name'>) => {
    let count = 0
    Object.entries(termPlusWidthObj).forEach(([key, value]) => {
        if (object[key as keyof Omit<initCpgTemplateStateType, 'name'>].fix) {
            count += value
        }
    })
    return count
}

export type termPlusWidthObjType = typeof termPlusWidthObj

export type stickyStylesType = {
    left: number
    position: 'sticky'
    width: number
    zIndex: number
}

export const stickyColumnStyles = (
    term: keyof termPlusWidthObjType,
    object: Omit<initCpgTemplateStateType, 'name'>,
    head?: boolean,
    minusWidth?: number,
    plusLeft?: number
) => {
    let params = new URL(document.location as unknown as string).searchParams
    const subset = Object.keys(object)
        .filter((f) => object[f as keyof Omit<initCpgTemplateStateType, 'name'>].fix)
        .map((key) => {
            return {
                key,
                width: termPlusWidthObj[key as keyof termPlusWidthObjType],
            }
        })

    const indexOf = subset.map((v) => v.key).indexOf(term)

    const toLeft = subset.reduce((acc: number, item: { key: string | null; width: number }, index: number) => {
        if (indexOf > index) {
            return acc + item.width
        } else {
            return acc
        }
    }, 0)

    // @ts-ignore
    return indexOf < 0 || !params.has('r')
        ? {}
        : {
              left: toLeft + (plusLeft || 0),
              position: 'sticky' as any,
              width: termPlusWidthObj[term] - (minusWidth || 0),
              zIndex: head ? 5 : 4,
          }
}

export const getCellBodyStyles = (level: number | null | undefined, hasChildren?: boolean) => {
    if (level === undefined || level === null) return
    let params = new URL(document.location as unknown as string).searchParams

    const cssClass = params.has('b') ? g.cell_body_span_3 : g.cell_body

    const stylesByLevel = {
        0: `${g.level_zero} ${cssClass}`,
        1: `${g.level_one} ${cssClass}`,
        2: `${g.level_two} ${cssClass}`,
        3: `${g.level_three} ${cssClass}`,
        4: `${g.level_four} ${cssClass}`,
        5: `${g.level_five} ${cssClass}`,
        6: `${g.level_six} ${cssClass}`,
        7: `${g.level_seven} ${cssClass}`,
    }

    return hasChildren ? stylesByLevel[level as keyof typeof stylesByLevel] : stylesByLevel[7]
}

export const getCellBodyStylesAddRow = (level: number | null, hasChildren: boolean) => {
    const stylesByLevel = {
        0: `${g.level_zero}`,
        1: `${g.level_one}`,
        2: `${g.level_two}`,
        3: `${g.level_three}`,
        4: `${g.level_four}`,
        5: `${g.level_five}`,
        6: `${g.level_six}`,
        7: `${g.level_seven}`,
    }

    return hasChildren ? stylesByLevel[level as keyof typeof stylesByLevel] : stylesByLevel[7]
}

export const getGridYears = (years: number[] | undefined) => {
    if (!years) return
    let unique = Array.from(new Set(years))

    return unique.map((v, index) => {
        let count = 0
        for (const val of years) {
            if (v === val) {
                count++
            }
        }
        return (
            <div
                style={{
                    gridColumn: `span ${count}`,
                }}
                className={`${g.cell_head} ${index + 1 === unique.length && g.radius_right}`}
                key={`${index}${v}`}
            >
                {v}
            </div>
        )
    })
}

export function getRowWidth(length: number | undefined, cpgTObj: Omit<initCpgTemplateStateType, 'name'>) {
    if (!length) return

    const width = () => {
        // let count = 7875
        let count = 8415
        if (!cpgTObj.level.show) {
            count -= 130
        }
        if (!cpgTObj.numOrder.show) {
            count -= 100
        }
        if (!cpgTObj.code.show) {
            count -= 130
        }
        if (!cpgTObj.tag.show) {
            count -= 100
        }
        if (!cpgTObj.unit.show) {
            count -= 130
        }
        if (!cpgTObj.workName.show) {
            count -= 300
        }
        if (!cpgTObj.objName.show) {
            count -= 300
        }
        // if (!cpgTObj.humanHours.show) {
        //     count -= 540
        // }
        if (!cpgTObj.humanHoursProgressFact.show) {
            count -= 180
        }
        if (!cpgTObj.humanHoursProgressPlan.show) {
            count -= 180
        }
        if (!cpgTObj.humanHoursProgressPercent.show) {
            count -= 180
        }
        if (cpgTObj.costProgress.show) {
            if (!cpgTObj.costProgressPlan.show) {
                count -= 180
            }
            if (!cpgTObj.costProgressFact.show) {
                count -= 180
            }
            if (!cpgTObj.costProgressPercent.show) {
                count -= 180
            }
        } else {
            count -= 540
        }
        if (!cpgTObj.objTitle.show) {
            count -= 260
        }
        if (!cpgTObj.rdCode.show) {
            count -= 160
        }
        if (!cpgTObj.rdStatus.show) {
            count -= 225
        }
        if (!cpgTObj.rdDateApproval.show) {
            count -= 200
        }
        if (cpgTObj.volumeDone.show) {
            if (!cpgTObj.volumeDoneFact.show) {
                count -= 180
            }
            if (!cpgTObj.volumeDonePlan.show) {
                count -= 180
            }
        } else {
            count -= 360
        }
        if (!cpgTObj.rdGroup.show) {
            count -= 180
        }
        if (!cpgTObj.rdVersion.show) {
            count -= 180
        }
        if (!cpgTObj.rdDate.show) {
            count -= 180
        }
        if (!cpgTObj.workGroup.show) {
            count -= 180
        }
        if (!cpgTObj.contractorCompany.show) {
            count -= 180
        }
        if (!cpgTObj.volumeTotal.show) {
            count -= 180
        }
        if (cpgTObj.basePlan.show) {
            if (!cpgTObj.startDate.show) {
                count -= 180
            }
            if (!cpgTObj.endDate.show) {
                count -= 180
            }
        } else {
            count -= 360
        }
        if (cpgTObj.operPlan.show) {
            if (!cpgTObj.operationalStartDate.show) {
                count -= 180
            }
            if (!cpgTObj.operationalEndDate.show) {
                count -= 180
            }
        } else {
            count -= 360
        }
        if (!cpgTObj.humanHourCost.show) {
            count -= 180
        }
        if (!cpgTObj.humanHoursTotal.show) {
            count -= 180
        }
        if (!cpgTObj.humanHoursProgressTZ.show) {
            count -= 180
        }
        if (!cpgTObj.machineHourCost.show) {
            count -= 180
        }
        if (!cpgTObj.machineHoursTotal.show) {
            count -= 180
        }
        if (!cpgTObj.costER.show) {
            count -= 180
        }
        if (!cpgTObj.costTotal.show) {
            count -= 180
        }
        if (!cpgTObj.costTotalProgress.show) {
            count -= 180
        }
        if (!cpgTObj.costRemaining.show) {
            count -= 180
        }
        if (!cpgTObj.costDone.show) {
            count -= 180
        }
        if (!cpgTObj.costNZP.show) {
            count -= 180
        }
        if (!cpgTObj.prStart.show) {
            count -= 180
        }
        if (!cpgTObj.prEnd.show) {
            count -= 180
        }
        if (!cpgTObj.prProvision.show) {
            count -= 180
        }
        if (!cpgTObj.workPeriod.show) {
            count -= 180
        }

        return count
    }
    return length * 120 + width()
}

export function getGridTableStyles(length: number | undefined, cpgTObj: Omit<initCpgTemplateStateType, 'name'>) {
    // ${cpgTObj.volumeDone.show ? `calc(360px - ${!cpgTObj.volumeDonePlan.show ? '180px' : '0px'} - ${!cpgTObj.volumeDoneFact.show ? '180px' : '0px'})` : ''}
    // ${cpgTObj.humanHours.show ? `calc(540px - ${!cpgTObj.humanHoursProgressPlan.show ? '180px' : '0px'} - ${!cpgTObj.humanHoursProgressFact.show ? '180px' : '0px'} - ${!cpgTObj.humanHoursProgressPercent.show ? '180px' : '0px'})` : ''}
    // ${cpgTObj.costProgress.show ? `calc(540px - ${!cpgTObj.costProgressPercent.show ? '180px' : '0px'} - ${!cpgTObj.costProgressPlan.show ? '180px' : '0px'} - ${!cpgTObj.costProgressFact.show ? '180px' : '0px'})` : ''}
    return {
        width: getRowWidth(length, cpgTObj),
        display: 'grid',
        fontSize: '14px',
        gridTemplateColumns: `
        ${cpgTObj.level.show ? '130px' : ''}
        
        ${cpgTObj.numOrder.show ? '100px' : ''}

        ${cpgTObj.code.show ? '130px' : ''}
        ${cpgTObj.tag.show ? '100px' : ''}
        ${cpgTObj.objTitle.show ? '260px' : ''}
        ${cpgTObj.objName.show ? '300px' : ''}
        ${cpgTObj.rdGroup.show ? '180px' : ''}
        ${cpgTObj.rdCode.show ? '160px' : ''}
        ${cpgTObj.rdStatus.show ? '225px' : ''}
        ${cpgTObj.rdDateApproval.show ? '200px' : ''}
        ${cpgTObj.rdVersion.show ? '180px' : ''}
        ${cpgTObj.rdDate.show ? '180px' : ''}
        ${cpgTObj.workGroup.show ? '180px' : ''}
        ${cpgTObj.contractorCompany.show ? '180px' : ''}
        ${cpgTObj.workName.show ? '300px' : ''}
        ${cpgTObj.unit.show ? '130px' : ''}
        ${cpgTObj.volumeTotal.show ? '180px' : ''}
        ${cpgTObj.volumeDonePlan.show ? '180px' : ''}
        ${cpgTObj.volumeDoneFact.show ? '180px' : ''}
 
        ${cpgTObj.startDate.show ? '180px' : ''}
        ${cpgTObj.endDate.show ? '180px' : ''}
        ${cpgTObj.operationalStartDate.show ? '180px' : ''}
        ${cpgTObj.operationalEndDate.show ? '180px' : ''}

        ${cpgTObj.humanHourCost.show ? '180px' : ''}
        ${cpgTObj.humanHoursTotal.show ? '180px' : ''}
        ${cpgTObj.humanHoursProgressTZ.show ? '180px' : ''}


        ${cpgTObj.humanHoursProgressPlan.show ? '180px' : ''}
        ${cpgTObj.humanHoursProgressFact.show ? '180px' : ''}
        ${cpgTObj.humanHoursProgressPercent.show ? '180px' : ''}
        
        ${cpgTObj.machineHourCost.show ? '180px' : ''}
        ${cpgTObj.machineHoursTotal.show ? '180px' : ''}
        ${cpgTObj.costER.show ? '180px' : ''}
        ${cpgTObj.costTotal.show ? '180px' : ''}
        ${cpgTObj.costTotalProgress.show ? '180px' : ''}


        ${cpgTObj.costProgressPlan.show ? '180px' : ''}
        ${cpgTObj.costProgressFact.show ? '180px' : ''}
        ${cpgTObj.costProgressPercent.show ? '180px' : ''}
        
        ${cpgTObj.costRemaining.show ? '180px' : ''}
        ${cpgTObj.costDone.show ? '180px' : ''}
        ${cpgTObj.costNZP.show ? '180px' : ''}

        ${cpgTObj.prStart.show ? '180px' : ''}
        ${cpgTObj.prEnd.show ? '180px' : ''}
        ${cpgTObj.prProvision.show ? '180px' : ''}
        ${cpgTObj.workPeriod.show ? '180px' : ''}
        
        180px
        80px
        180px
        repeat(${length}, 120px)
        `,
    }
}

export const thirdRowCellsCount = (cpgTObj: Omit<initCpgTemplateStateType, 'name'>) => {
    let count = 45

    if (!cpgTObj.level.show) {
        count -= 1
    }
    if (!cpgTObj.tag.show) {
        count -= 1
    }
    if (!cpgTObj.numOrder.show) {
        count -= 1
    }

    if (!cpgTObj.code.show) {
        count -= 1
    }
    if (!cpgTObj.objTitle.show) {
        count -= 1
    }
    if (!cpgTObj.objName.show) {
        count -= 1
    }
    if (!cpgTObj.rdCode.show) {
        count -= 1
    }
    if (!cpgTObj.rdGroup.show) {
        count -= 1
    }
    if (!cpgTObj.rdStatus.show) {
        count -= 1
    }
    if (!cpgTObj.rdDateApproval.show) {
        count -= 1
    }
    if (!cpgTObj.rdVersion.show) {
        count -= 1
    }
    if (!cpgTObj.rdDate.show) {
        count -= 1
    }
    if (!cpgTObj.workGroup.show) {
        count -= 1
    }
    if (!cpgTObj.contractorCompany.show) {
        count -= 1
    }
    if (!cpgTObj.workName.show) {
        count -= 1
    }
    if (!cpgTObj.unit.show) {
        count -= 1
    }
    if (!cpgTObj.volumeTotal.show) {
        count -= 1
    }
    if (!cpgTObj.startDate.show) {
        count -= 1
    }
    if (!cpgTObj.endDate.show) {
        count -= 1
    }
    if (!cpgTObj.operationalStartDate.show) {
        count -= 1
    }
    if (!cpgTObj.operationalEndDate.show) {
        count -= 1
    }
    if (!cpgTObj.humanHourCost.show) {
        count -= 1
    }
    if (!cpgTObj.humanHoursTotal.show) {
        count -= 1
    }
    if (!cpgTObj.humanHoursProgressTZ.show) {
        count -= 1
    }
    if (!cpgTObj.machineHourCost.show) {
        count -= 1
    }
    if (!cpgTObj.machineHoursTotal.show) {
        count -= 1
    }
    if (!cpgTObj.costER.show) {
        count -= 1
    }
    if (!cpgTObj.costTotal.show) {
        count -= 1
    }
    if (!cpgTObj.costTotalProgress.show) {
        count -= 1
    }
    if (!cpgTObj.costRemaining.show) {
        count -= 1
    }
    if (!cpgTObj.costDone.show) {
        count -= 1
    }
    if (!cpgTObj.costNZP.show) {
        count -= 1
    }
    if (!cpgTObj.volumeDoneFact.show) {
        count -= 1
    }
    if (!cpgTObj.volumeDonePlan.show) {
        count -= 1
    }
    if (!cpgTObj.humanHoursProgressPercent.show) {
        count -= 1
    }
    if (!cpgTObj.humanHoursProgressFact.show) {
        count -= 1
    }
    if (!cpgTObj.humanHoursProgressPlan.show) {
        count -= 1
    }
    if (!cpgTObj.costProgressFact.show) {
        count -= 1
    }
    if (!cpgTObj.costProgressPercent.show) {
        count -= 1
    }
    if (!cpgTObj.costProgressPlan.show) {
        count -= 1
    }
    if (!cpgTObj.prStart.show) {
        count -= 1
    }
    if (!cpgTObj.prEnd.show) {
        count -= 1
    }
    if (!cpgTObj.prProvision.show) {
        count -= 1
    }
    if (!cpgTObj.workPeriod.show) {
        count -= 1
    }

    return count
}

export const initAddWorkState = {
    objTitle: '',
    objName: '',
    rdCode: '',
    rdGroup: '',
    rdStatus: '' as workStatuses,
    rdDateApproval: null,
    rdVersion: '',
    rdDate: null,
    workGroup: '',
    contractorCompany: '',
    workName: '',
    unit: '',
    volumeTotal: '',
    startDate: null,
    endDate: null,
    humanHourCost: '',
    machineHourCost: '',
    costER: '',
    costDone: '',
    charts: [
        {
            fact: '',
            month: '',
            plan: '',
            year: '',
        },
    ],
    operationalStartDate: null,
    operationalEndDate: null,
}

export const placeholderEditableCell = {
    objTitle: 'Титул',
    objName: 'Название',
    rdCode: 'Шифр',
    rdDateApproval: 'Дата согласования РД',
    rdGroup: 'Раздел РД',
    rdVersion: 'Версия РД',
    rdStatus: 'Статус',
    workGroup: 'Группа работ',
    contractorCompany: 'Подрядчик',
    workName: 'Наименование работы',
    unit: 'Единица изм.',
    volumeTotal: 'Общий объем',
    volumeDonePlan: 'Вып. объем план',
    volumeDoneFact: 'Вып. объем факт',
    humanHourCost: 'Трудозатраты, чел-час',
    machineHourCost: 'Затраты маш. и м. маш-час',
    costER: 'Стоимость ЕР',
    costDone: 'Закрыто по КС2',
}

// export const updateFirstAndSecondLevel = (d: WritableDraft<GetWorksTreeRes | null>, wor: Work) => {
//     if (!d) return
//     d.data.forEach((lev1) => {
//         if (lev1.payload.id === wor.id) {
//             lev1.payload = wor
//         }
//     })
//     d.data.forEach((parent) => {
//         parent.children.forEach((lev2) => {
//             if (lev2.payload.id === wor.id) {
//                 lev2.payload = wor
//             }
//         })
//     })
// }

export const updateLevelById = (d: WritableDraft<Work[]>, wor: Work) => {
    const indexChange = d.findIndex((lev) => lev.id === wor.id)
    if (indexChange >= 0) {
        d.splice(indexChange, 1, wor)
    }
}

export const donePercentSameStyles: React.CSSProperties = {
    borderRadius: '32px',
    padding: '4px 13px',
    fontSize: '10px !important',
    fontWeight: 600,
    textTransform: 'uppercase',
    minWidth: 60,
}
export const donePercentLess50 = {
    ...donePercentSameStyles,
    color: '#fff',
    backgroundColor: '#ffb1b1 !important',
}
export const donePercent5090 = {
    ...donePercentSameStyles,
    color: '#988d22',
    backgroundColor: '#fef08a !important',
}
export const donePercent90Plus = {
    ...donePercentSameStyles,
    color: '#fff',
    backgroundColor: '#8dd4c8 !important',
}

export function stylesLevel(lev: number | null) {
    const stylesByLevel = {
        0: g.level0,
        1: g.level1,
        2: g.level2,
        3: g.level3,
        4: g.level4,
        5: g.level5,
        6: g.level6,
        7: g.level7,
    }
    return stylesByLevel[lev as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7]
}

export function applyStylesPercent(percent: number | null) {
    if (percent === null) return donePercentLess50
    if (percent < 50) return donePercentLess50
    if (percent < 90) return donePercent5090
    return donePercent90Plus
}

export function applyMonthStyles(plan: null | number, fact: null | number) {
    if (!plan || !fact) return
    if (plan < fact + 1) return { color: '#2E7D32' }
    return { color: '#D32F2F' }
}

export const hasParam = (valueToCheck: 'g' | 'b' | 'r' | 'gf' | 'gant', searchString?: string) => {
    const valuesObj = {
        g: 'g=on',
        b: 'b=on',
        r: 'r=on',
        gf: 'gf=on',
        gant: 'true',
    }
    return searchString?.includes(valuesObj[valueToCheck])
}

export const getIndexParent = (id: number, worksList: Work[] | undefined, worksShort: Work[] | undefined) => {
    const code = worksList?.find((f) => f.id === id)?.code
    if (!code) return
    let desiredCode = ''

    worksShort?.forEach((work) => {
        const workCode = work.code.split('-')
        const codeSplit = code.split('-')
        const checker = (arr: string[], target: string[]) => target.every((v) => arr.includes(v))
        if (checker(codeSplit, workCode)) {
            desiredCode = work.code
        }
    })
    return worksShort?.findIndex((f) => f.code === desiredCode)
}

export const getIndexParentHeaders = (
    id: number,
    worksList: Work[] | undefined,
    worksShort: Work[] | undefined,
    headers: CPGHeader[] | undefined,
    startEnd: 'start' | 'end'
) => {
    const code = worksList?.find((f) => f.id === id)?.code
    if (!code) return

    let desiredCode = ''

    worksShort?.forEach((work) => {
        const workCode = work.code.split('-')
        const codeSplit = code.split('-')
        const checker = (arr: string[], target: string[]) => target.every((v) => arr.includes(v))
        if (checker(codeSplit, workCode)) {
            desiredCode = work.code
        }
    })

    const workLookedFor = worksShort?.find((f) => f.code === desiredCode)
    if (!workLookedFor) return
    return headers?.findIndex(
        (fi) =>
            fi.year === Number(workLookedFor?.workPeriod[startEnd]?.split('.')[2]) &&
            fi.month === Number(workLookedFor?.workPeriod[startEnd]?.split('.')[1])
    )
}

export const validationSchemaAddLevelKsg = Yup.object({
    objTitle: Yup.string().required('Введите титул').max(150, 'Нельзя вводить больше 150 символов'),
    objName: Yup.string().max(150, 'Нельзя вводить больше 150 символов'),
    rdGroup: Yup.string().max(150, 'Нельзя вводить больше 150 символов'),
    rdCode: Yup.string().max(150, 'Нельзя вводить больше 150 символов'),
    workName: Yup.string().required('Введите наименовние').max(150, 'Нельзя вводить больше 150 символов'),
    startDate: Yup.date()
        .nullable()
        .typeError('Неверный формат, введите дату')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    endDate: Yup.date()
        .nullable()
        .typeError('Неверный формат, введите дату')
        .min(Yup.ref('startDate'), 'Дата окончания должна быть позже даты начала')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    rdDateApproval: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
    rdDate: Yup.date()
        .nullable()
        .typeError('Неверный формат')
        .min(new Date(2000, 0, 1), 'Не раньше 2000 года')
        .max(new Date(2050, 0, 1), 'Не позднее 2050 года'),
})
