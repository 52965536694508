import { Dispatch, Fragment, SetStateAction, SyntheticEvent, useCallback, useState } from 'react'
import { HtmlTooltip } from '../../../shared/components/HtmlTooltip'
import { tooltipHelperNumber } from '../../../shared/utils'
import { NumericFormat } from 'react-number-format'
import { monthlyChart, Work } from '../../../api/ksg/ksg.def'
import g from '../../../shared/stylesheets/TableGrid.module.scss'
import { Updater, useImmer } from 'use-immer'
import { useUpdateChartsKs3Mutation } from '../../../api/ksg/ksg.api'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../store/store'
import { updateKs3KsgAg } from '../../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'

export function Ks3Input({
    charts,
    setMutationsLoading,
    cell,
    setIsInputMode,
    setWorksList,
}: {
    charts: monthlyChart[]
    setMutationsLoading: Dispatch<SetStateAction<boolean>>
    setIsInputMode: Dispatch<SetStateAction<string>>
    setWorksList?: Updater<{ data: Work[]; total: number } | null>
    cell: {
        month: number
        year: number
    }
}) {
    const [values, setValues] = useImmer(charts)

    const [updateChartReq, updateChartRes] = useUpdateChartsKs3Mutation()

    const { projectId } = useParams()

    const dispatch = useAppDispatch()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    const onFormMonthsSubmit = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault()

            setMutationsLoading(true)

            updateChartReq({
                id: Number(projectId),
                body: {
                    data: values.map((v) => {
                        return {
                            kc3: v.kc3,
                            month: v.month,
                            year: v.year,
                        }
                    }),
                },
            })
                .unwrap()
                .then(() => {
                    setMutationsLoading(false)
                    setIsInputMode('')
                    setWorksList &&
                        setWorksList((d) => {
                            if (!d) return
                            d.data[0].monthlyCharts.forEach((f, index) => {
                                if (f.year === cell.year && f.month === cell.month) {
                                    f.kc3 = values[index].kc3
                                }
                            })
                        })
                    dispatch(updateKs3KsgAg(values))

                    enqueueSnackbar(t('saved'), {
                        variant: 'success',
                    })
                })
                .catch((e) => {
                    setMutationsLoading(false)
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    })
                    console.error(e)
                    setIsInputMode('')
                })
        },
        [
            cell.month,
            cell.year,
            dispatch,
            enqueueSnackbar,
            projectId,
            setIsInputMode,
            setMutationsLoading,
            setWorksList,
            t,
            updateChartReq,
            values,
        ]
    )

    const getValue = () => {
        const val = values.find((f) => f.month === cell.month && f.year === cell.year)
        if (!val) return ''
        return val.kc3 === null ? '' : val.kc3
    }

    return (
        <HtmlTooltip title="Нажмите enter чтобы сохранить!" placement="top-end">
            <form onSubmit={onFormMonthsSubmit}>
                <input
                    step="0.000001"
                    value={getValue()}
                    onChange={(e) => {
                        setValues((d) => {
                            const value =
                                e.target.value === '0' ? 0 : e.target.value === '' ? null : Number(e.target.value)
                            const fItem = d.find((v) => v.month === cell.month && v.year === cell.year)
                            if (!fItem) return
                            fItem.kc3 = value
                        })
                    }}
                    className={g.month_input}
                    type="number"
                    max="99999999999"
                    min="0"
                    autoFocus
                    disabled={updateChartRes.isLoading}
                />
                <button className={g.d_none} type="submit"></button>
            </form>
        </HtmlTooltip>
    )
}

export default function UpdateKs3({
    charts,
    clName,
    setMutationsLoading,
    setWorksList,
}: {
    charts: monthlyChart[]
    clName?: string
    setMutationsLoading: Dispatch<SetStateAction<boolean>>
    setWorksList?: Updater<{ data: Work[]; total: number } | null>
}) {
    const [isInputMode, setIsInputMode] = useState('')
    return (
        <Fragment>
            <div className={clName}>КС3</div>

            {charts.map((cell) => (
                <Fragment key={`${cell.month} + ${cell.year}`}>
                    {isInputMode !== `${cell.month}${cell.year}` ? (
                        <div className={clName} onClick={() => setIsInputMode(`${cell.month}${cell.year}`)}>
                            {cell.kc3 === null ? (
                                '-'
                            ) : (
                                <HtmlTooltip title={tooltipHelperNumber(cell.kc3)}>
                                    <span>
                                        <NumericFormat
                                            displayType={'text'}
                                            value={cell.kc3}
                                            thousandSeparator={' '}
                                            decimalScale={2}
                                            decimalSeparator=","
                                        />
                                    </span>
                                </HtmlTooltip>
                            )}
                        </div>
                    ) : (
                        <div className={clName}>
                            <Ks3Input
                                charts={charts}
                                setIsInputMode={setIsInputMode}
                                setMutationsLoading={setMutationsLoading}
                                cell={{
                                    month: cell.month,
                                    year: cell.year,
                                }}
                                setWorksList={setWorksList}
                            />
                        </div>
                    )}
                </Fragment>
            ))}
        </Fragment>
    )
}
