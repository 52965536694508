import { IWorkForCopy } from '../CopyResourcesDrawer.def'
import { IAddWorkToListArgs, ISwitchResourceArgs } from './WorkList.def'

export function addWorkToList({ params, checked }: IAddWorkToListArgs) {
    params.api.applyTransaction({
        update: [{ ...(params.data as IWorkForCopy), checked: checked }],
    })
    params.api.redrawRows({ rowNodes: [params.node] })
}

export function switchResource({ params, checked, resourceType }: ISwitchResourceArgs) {
    const updatedWork = {
        ...(params.data as IWorkForCopy),
        choice: { ...(params.data as IWorkForCopy).choice, [resourceType]: checked },
    }
    params.api.applyTransaction({
        update: [updatedWork],
    })
    params.api.redrawRows({ rowNodes: [params.node] })
}

export function switchWork({ params, checked }: IAddWorkToListArgs) {
    params.api.forEachNode(node => {
        node.data?.checked && params.api.applyTransaction({
            update: [{ ...(node.data as IWorkForCopy), checked: false }],
        })
    })
    params.api.applyTransaction({
        update: [{ ...(params.data as IWorkForCopy), checked: true }],
    })
    params.api.redrawRows({ rowNodes: [params.node] })
}