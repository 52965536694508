import { FirstDataRenderedEvent, RowClassParams } from 'ag-grid-community';
import axios, { isAxiosError } from 'axios';
import { Buffer } from 'buffer';
import { format, isValid, parse, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Location } from 'react-router-dom';

import { LoginResponse } from '@/api/auth/auth.def';

import { req } from '@/pages/WorkManagment/api/api';

import { setCredentials } from '@/store/slices/authSlice';
import { RootState, store } from '@/store/store';

import { deployLinks } from './config/config';
import { ISubmitParams, ISubmitRejectedParams, ISubmitResultParams } from './def';

export const compose = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) =>
    fns.reduce((prevFn, nextFn) => (value) => prevFn(nextFn(value)), fn1);

export const getDateTimeString = (dateTimeString: string | null) => {
    const serverString = dateTimeString?.split(' ');
    if (!serverString) return;
    const serverStringArr = serverString[0].split('.');
    const parsedDate = parseISO(
        `${serverStringArr[2]}-${serverStringArr[1]}-${serverStringArr[0]}T${serverString[1]}Z`
    );
    return format(parsedDate as Date, 'dd.MM.yyyy в HH:mm');
};

export const axiosRefetchToken = (status?: any) => {
    if (status === 401) {
        const refreshToken = (store.getState() as RootState).auth.refreshToken;

        axios<LoginResponse>({
            method: 'POST',
            url: deployLinks.server + '/auth/refresh',
            data: { token: refreshToken },
        })
            .then((res) => {
                store.dispatch(setCredentials(res.data as LoginResponse));
            })
            .catch((e) => {
                console.error(e);
            });
    }
};

export const loopThroughDate = (year: number, month: number) => {
    let now = new Date(year, month);
    let daysOfMonth = [];
    for (let d = new Date(year, month - 1, 1); d <= now; d.setDate(d.getDate() + 1)) {
        daysOfMonth.push(new Date(d));
    }
    daysOfMonth.pop();
    return daysOfMonth;
};

export const tooltipHelper = (text: string | null) => {
    return (text && text.length > 30 && text) || '';
};

export const tooltipHelperNumber = (num: number | string | null) => {
    if (num && String(num).split('.')[1]?.length > 2) {
        return String(num).replace('.', ',');
    }
    return '';
};

export const formDownloadLink = (res: string, loc: string[], projectId?: string, ksg: boolean = true) => {
    const fileName = ksg ? 'КСГ' : 'МСГ';
    const name = () => {
        if (loc[2] === 'workers') {
            return `${fileName}_Персонал`;
        } else if (loc[2] === 'mim') {
            return `${fileName}_Механизмы`;
        } else {
            return fileName;
        }
    };

    const a = document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([Buffer.from(res, 'base64')], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
    );
    a.download = `${name()}_${projectId ? Number(projectId) : 'randomProject'}`;
    a.click();
};

export const isDatePast = (dateString: string | null) => {
    if (!dateString) return;
    const date = parse(dateString, 'dd.MM.yyyy', new Date());
    const now = new Date();
    return now > date;
};

export const modalStyles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 373,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '0.25rem',
    borderRadius: '4px',
};

export function getWorkManagmentResourceRowSlyles(params: RowClassParams) {
    return params.data.isResource && 'workManagmentResource';
}

export function selectInputOnCtrlA(
    event: React.KeyboardEvent<HTMLInputElement>,
    inputRef: React.RefObject<HTMLInputElement>
) {
    event.ctrlKey && event.keyCode == 65 && inputRef.current?.select();
}

export const isTooManyRequests = (error: unknown) => {
    if (!isAxiosError(error)) return false;
    return error.response?.status === 429;
};

export function onSubmitSuccess({ params, enqueueSnackbar, translate }: ISubmitResultParams) {
    const oldValue =
        typeof params.oldValue === 'string' || typeof params.oldValue === 'number'
            ? params.oldValue || ''
            : params.oldValue?.label || '';
    const newValue =
        typeof params.newValue === 'string' || typeof params.newValue === 'number'
            ? params.newValue || ''
            : params.newValue?.label || '';
    let successMessage = `Значение поля ${translate(
        params?.colDef?.field || ''
    )} успешно изменено c ${oldValue} на ${newValue}`;
    if (!newValue) {
        successMessage = `Значение поля ${translate(params?.colDef?.field || '')} успешно удалено`;
    }
    if (!oldValue) {
        successMessage = `Значение поля ${translate(params?.colDef?.field || '')} успешно изменено на ${newValue}`;
    }
    enqueueSnackbar(successMessage, {
        variant: 'success',
    });
}

export function onSubmitRejected({ enqueueSnackbar, params, translate, error }: ISubmitRejectedParams) {
    params.api.applyTransaction({ update: [{ ...params.data, [params.colDef.field as string]: params.oldValue }] });
    translate &&
        enqueueSnackbar(
            translate(('errors.' + error?.response?.data || '') as string, 'Ошибка. Обратитесь к администратору'),
            {
                variant: 'error',
            }
        );
}

export function submitExecutorName(args: ISubmitParams) {
    const { projectID: projectId, params, resourceType } = args;
    req.post(
        `/projects/${projectId}/${resourceType}/msg/set-executor?year=${params.data?.year}&month=${params.data?.month}`,
        {
            executorId: params.newValue?.employeeID,
            id: params.data.id,
        }
    )
        .then(({ data }) => {
            params.api.applyTransaction({ update: [data.data] });
            onSubmitSuccess(args);
        })
        .catch((e) => {
            onSubmitRejected({ ...args, error: e });
        });
}

export function preserveScroll(event: FirstDataRenderedEvent, location: Location) {
    const scrollParams = JSON.parse(localStorage.getItem('scrollParams')!);
    const firstRenderedRow = scrollParams && scrollParams[location.pathname]?.firstRenderedRow;
    const lastRenderedRow = scrollParams && scrollParams[location.pathname]?.lastRenderedRow;
    if (lastRenderedRow && (firstRenderedRow || firstRenderedRow === 0) && event) {
        // lastRenderedRow && event && event?.api?.showLoadingOverlay()
        const bufferedRowsTop = 10;
        const bufferedRowsBottom = 10;
        const bufferedRowsQty = bufferedRowsTop + bufferedRowsBottom;
        const rowsPerPage = lastRenderedRow - firstRenderedRow - bufferedRowsQty;
        const currentRenderedRowsQty = lastRenderedRow - firstRenderedRow;
        let targetRow = lastRenderedRow - bufferedRowsBottom - rowsPerPage;
        if (lastRenderedRow <= currentRenderedRowsQty) {
            targetRow = lastRenderedRow - bufferedRowsBottom - 1;
            setTimeout(() => {
                event?.api?.ensureIndexVisible(targetRow, 'bottom');
            }, 250);
        } else {
            setTimeout(() => {
                event?.api?.ensureIndexVisible(targetRow, 'top');
            }, 250);
        }
        setTimeout(() => {
            event?.api?.hideOverlay();
        }, 500);
    }
}

export const isNumber = (value: unknown): value is number => typeof value === 'number' && !isNaN(Number(value));

export const areAllValuesNull = (obj: Record<string, any>): boolean => {
    for (const key in obj) {
        if (obj[key] !== null) {
            return false;
        }
    }
    return true;
};

export const formatNumberWithComma = (num: number): string => num.toString().replace('.', ',');

export const capitalizeFirstLetter = (word: string) => {
    if (word.length === 0) return word;
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const isCurrentDayAndMonth = (day: number, month: number) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();

    return day === currentDay && month === currentMonth;
};

export const isValidDate = (date: string, formatString = 'dd.MM.yyyy') => {
    const parsed = parse(date, formatString, new Date());
    return isValid(parsed);
};

export const getDayOfWeek = (dateAsString: string, formatString = 'dd.MM.yyyy', locale = ru): string => {
    if (!isValidDate(dateAsString, formatString)) return '';

    const date = parse(dateAsString, formatString, new Date());
    return format(date, 'EEEEEE', { locale });
};
