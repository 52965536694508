import { RefObject, createRef, useEffect, useState } from 'react'
import { OverflowableTypography } from '../../pages/WorkManagment/components/components.styles'
import { IOverflowableTypographyWithTooltipProps } from './OverflowableTypographyWithTooltip.def'
import { StyledTooltip } from '../../shared/components/StyledTooltip'

export function OverflowableTypographyWithTooltip({
    TooltipProps,
    children,
    TypographyProps,
    maxRows,
}: IOverflowableTypographyWithTooltipProps) {
    const ref = createRef() as RefObject<HTMLSpanElement | HTMLParagraphElement>
    const [isOverflown, setIsOverflown] = useState<boolean>(false)
    const noTooltipConditions = [!children, children === 0, children === "-", children === "0"]

    function checkIsElementOverflown(element: HTMLSpanElement | HTMLParagraphElement | null) {
        return element
            ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
            : false
    }

    useEffect(() => {
        ref.current && setIsOverflown(() => checkIsElementOverflown(ref.current))
    }, [ref])

    if (noTooltipConditions.includes(true)) {
        return (
            <OverflowableTypography ref={ref} {...TypographyProps} rows={maxRows}>
                {children}
            </OverflowableTypography>
        )
    }

    return (
        <StyledTooltip title={isOverflown ? (children ? children : '') : ''} disableInteractive {...TooltipProps}>
            <OverflowableTypography ref={ref} {...TypographyProps} rows={maxRows}>
                {children}
            </OverflowableTypography>
        </StyledTooltip>
    )
}
