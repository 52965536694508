import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { newDate } from '../../../store/slices/cpgViewSlice'
import { useAppDispatch, useTypedSelector } from '../../../store/store'
import s from '../TableLayout.module.scss'
import { arrowStyles } from '../TableLayout.service'
import { monthDownMsg, monthMsgSelector, monthUpMsg } from '../../../store/slices/monthMsgSlice'
import { setTotal } from '../../../store/slices/paginationSlice'
import { useEffect, useMemo } from 'react'
import { useGetHeadersCPGQuery } from '../../../api/ksg/ksg.api'
import { useLocation, useParams } from 'react-router-dom'
import { CANVAS_ID } from '../../../pages/AgGridMsg/AgGridMsg.service'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../shared/rolePermissions'
import { profileSelector } from '../../../store/slices/profileSlice'

export const monthsList = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

export default function ChangeMonthTableTop() {
    const { month, year } = useTypedSelector(monthMsgSelector)
    const { projectId } = useParams()
    const { profile } = useTypedSelector(profileSelector)
    const dispatch = useAppDispatch()
    const location = useLocation()

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
        isStaff: location.pathname.includes('workers'),
        isTech: location.pathname.includes('mim'),
    })

    useEffect(() => {
        return () => document.getElementById(CANVAS_ID + 'wrapper')?.remove()
    }, [month, year])
    useEffect(() => {}, [headersCPG.data])
    const prevMonth = useMemo(() => {
        return new Date(year, month, 0).getMonth()
    }, [])
    const nextMonth = useMemo(() => {
        return (new Date().getMonth() + 1) % 12
    }, [])
    const currentYear = new Date().getFullYear()
    const nextYear = nextMonth === 0 ? currentYear + 1 : currentYear

    function showLeftArrow() {
        const doesPrevMonthExist =
            headersCPG.data?.allHeaders?.filter((header) => {
                return (
                    header.month === month &&
                    header.year === new Date(year, month, 0).getFullYear() &&
                    header.isCreatedMSG
                )
            }) || []
        if (month !== prevMonth && doesPrevMonthExist.length) {
            // if (currentMonthHeader.length > 0) {
            if (
                (month <= nextMonth && !DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_MSG_SMR')) ||
                DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_MSG_SMR')
            ) {
                return (
                    <span
                        className={`pointer  ${s.arrows_hover_width}`}
                        onClick={() => {
                            dispatch(setTotal(1))
                            dispatch(monthDownMsg())
                        }}
                    >
                        <KeyboardArrowLeftIcon sx={arrowStyles} />
                    </span>
                )
            }
            // }
        }
        return <div style={{ width: 36 }}>&nbsp;</div>
    }

    function showRightArrow() {
        const nextMonthHeader =
            headersCPG?.data?.allHeaders.filter(
                (headersCPG) => headersCPG.month === nextMonth + 1 && headersCPG.year === nextYear
            ) || []
        if (month !== nextMonth) {
            if (nextMonthHeader.length > 0)
                if (
                    (month < nextMonth && !DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_MSG_SMR')) ||
                    DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_MSG_SMR')
                ) {
                    return (
                        <span
                            className={`pointer  ${s.arrows_hover_width}`}
                            onClick={() => {
                                dispatch(setTotal(1))
                                dispatch(monthUpMsg())
                            }}
                        >
                            <KeyboardArrowRightIcon sx={arrowStyles} />
                        </span>
                    )
                }
        }

        return <div style={{ width: 36 }}>&nbsp;</div>
    }

    return headersCPG?.data && (headersCPG?.data?.allHeaders || []).length > 0 ? (
        <div className={s.select_month}>
            {showLeftArrow()}
            <h4 style={{ width: '4.5rem' }}>{monthsList[month]}</h4>
            {showRightArrow()}
        </div>
    ) : null
}
