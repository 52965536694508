import { ICellRendererParams } from 'ag-grid-community'
import { parseStringToNumber } from '../../../../components/EditChartCell/EditChartCell.service'
import { IOnSubmitChartDataArgs } from '../../../../components/EditChartCell/EditChartCell.def'
import { IMobilizationScheduleChart } from './MobilizationSchedulePage.def'
import { req } from '../../api/api'

export const MONTH_ORDER_TO_MONTH_NAME = {
    1: {
        shortName: 'Янв',
        fullName: 'январь',
    },
    2: {
        shortName: 'Фев',
        fullName: 'февраль',
    },
    3: {
        shortName: 'Мар',
        fullName: 'март',
    },
    4: {
        shortName: 'Апр',
        fullName: 'апрель',
    },
    5: {
        shortName: 'Май',
        fullName: 'май',
    },
    6: {
        shortName: 'Июн',
        fullName: 'июнь',
    },
    7: {
        shortName: 'Июл',
        fullName: 'июль',
    },
    8: {
        shortName: 'Авг',
        fullName: 'август',
    },
    9: {
        shortName: 'Сен',
        fullName: 'сентябрь',
    },
    10: {
        shortName: 'Окт',
        fullName: 'октябрь',
    },
    11: {
        shortName: 'Ноя',
        fullName: 'ноябрь',
    },
    12: {
        shortName: 'Дек',
        fullName: 'декабрь',
    },
}

const SHIFT_SIZE = 10

export function getMonthOptions() {
    var targetArray = []
    for (var i = 1; i <= 12; i++) {
        targetArray.push({
            label: MONTH_ORDER_TO_MONTH_NAME[i as unknown as keyof typeof MONTH_ORDER_TO_MONTH_NAME].fullName,
            value: i,
        })
    }
    return targetArray
}

export function getConvertedAverage(cellRendererParams: ICellRendererParams) {
    let planInShifts = cellRendererParams.data?.average.plan === null ? '-' : cellRendererParams.data?.average.plan
    let planInHours = planInShifts === '-' ? '-' : planInShifts * SHIFT_SIZE
    let factInShifts = cellRendererParams.data?.average.fact === null ? '-' : cellRendererParams.data?.average.fact
    let factInHours = factInShifts === '-' ? '-' : factInShifts * SHIFT_SIZE
    const targetPlan = cellRendererParams.context.searchParams.get('subType') === 'shift' ? planInShifts : planInHours
    const targetFact = cellRendererParams.context.searchParams.get('subType') === 'shift' ? factInShifts : factInHours
    return {
        plan: Math.round(targetPlan * 100000) / 100000,
        fact: Math.round(targetFact * 100000) / 100000,
    }
}

export function getConvertedChartData(cellRendererParams: ICellRendererParams, chartIndex: number) {
    let planInShifts =
        cellRendererParams.data?.chart[chartIndex]?.data?.plan === null
            ? '-'
            : cellRendererParams.data?.chart[chartIndex]?.data?.plan
    let planInHours = planInShifts === '-' ? '-' : planInShifts * SHIFT_SIZE
    let factInShifts =
        cellRendererParams.data?.chart[chartIndex]?.data?.fact === null
            ? '-'
            : cellRendererParams.data?.chart[chartIndex]?.data?.fact
    let factInHours = factInShifts === '-' ? '-' : factInShifts * SHIFT_SIZE
    const targetPlan = cellRendererParams.context.searchParams.get('subType') === 'shift' ? planInShifts : planInHours
    const targetFact = cellRendererParams.context.searchParams.get('subType') === 'shift' ? factInShifts : factInHours
    return {
        plan: targetPlan,
        fact: targetFact,
    }
}

function insertFactReq(
    projectID: string | number | undefined,
    cellRendererParams: ICellRendererParams,
    fact: number | null,
    chartIndex: number
) {
    const work = JSON.parse(localStorage.getItem('work')!)
    const day = cellRendererParams.data.chart[chartIndex].header
    const month = cellRendererParams.context.searchParams.get('month')
    const year = cellRendererParams.context.searchParams.get('year')

    return req.post(
        `/projects/${projectID}/works/${
            work?.id
        }/cabinet/mobilization/update-${cellRendererParams.context.searchParams.get('resourceType')}`,
        {
            date: `${day}.${month}.${year}`,
            fact: fact,
            resName: cellRendererParams.data?.name,
        }
    )
}

export function onSubmitNewFact(args: IOnSubmitChartDataArgs) {
    const { formData, projectID, enqueueSnackbar, setIsFetching, cellRendererParams, chartIndex, setIsEditMode } = args
    const newChartValue = { ...cellRendererParams.data }
    const inputedFact = parseStringToNumber(formData?.fact)
    const newFactInShifts = !formData.fact ? null : inputedFact / 10
    const newFactInHours = !formData?.fact ? null : inputedFact
    let totalFact = 0
    let daysWithValue = 0
    newChartValue.chart[chartIndex as number].data.fact = newFactInShifts
    newChartValue.chart.forEach((chart: IMobilizationScheduleChart) => {
        totalFact += chart.data?.fact || 0 || 0
        if (chart.data?.fact !== null && chart.data?.fact > 0) {
            daysWithValue += 1
        }
    })
    newChartValue.average.fact = daysWithValue === 0 ? 0 : totalFact / daysWithValue
    insertFactReq(projectID, cellRendererParams, newFactInHours, chartIndex as number)
        .then((data) => {
            cellRendererParams.api.applyTransactionAsync({
                update: [{ ...newChartValue }],
            })
            enqueueSnackbar('Факт успешно изменен', { variant: 'success' })
            setTimeout(() => setIsFetching(() => false), 100)
            setIsEditMode(() => false)
        })
        .catch((e) => {
            enqueueSnackbar('Ошибка', { variant: 'error' })
            setIsFetching(() => false)
            setIsEditMode(() => false)
        })
}
