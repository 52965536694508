import { isAxiosError } from 'axios';

import { req } from '@/pages/WorkManagment/api/api';

export const isProjectNotFound = async (projectId: string) => {
    try {
        await req.get(`/projects/${projectId}/get`);
        return false;
    } catch (error) {
        if (!isAxiosError(error)) return false;

        if (error.response?.status === 404) return true;

        return false;
    }
};
