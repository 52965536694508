import { ResourcesPageWrapper } from './ResourcesPage.styles'
import { ResourceCard } from './ResourceCard/ResourceCard'
import { useEffect, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { drawersSelector, setWorkManagmetResourceGridApi } from '../../../../store/slices/drawersSlice'
import { ConfirmDialog } from '../../components/ConfirmDialog/ConfirmDialog'
import { CellEditingStoppedEvent } from 'ag-grid-community'
import { getMimAgGridProps, getStaffAgGridProps } from './ResourceCard/ResourceCard.service'
import { AgGridReact } from 'ag-grid-react'

export function ResourcesPage() {
    const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
    const [cellEditingStoppedEvent, setCellEditingStoppedEvent] = useState<CellEditingStoppedEvent | null>(null)
    const { workManagmentResourcesGridRefs } = useTypedSelector(drawersSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(
            setWorkManagmetResourceGridApi({
                setTriggerRefresh: setTriggerRefresh,
            })
        )
    }, [])

    useEffect(() => {}, [triggerRefresh])

    return (
        <ResourcesPageWrapper>
            <ResourceCard
                triggerRefresh={triggerRefresh}
                key={'staff'}
                resourceType="staff"
                AgGridProps={getStaffAgGridProps(setIsConfirmDialogOpen, setCellEditingStoppedEvent)}
            />

            <ResourceCard
                triggerRefresh={triggerRefresh}
                key={'mim'}
                resourceType="mim"
                AgGridProps={getMimAgGridProps(
                    setIsConfirmDialogOpen,
                    setCellEditingStoppedEvent,
                    workManagmentResourcesGridRefs.mim as AgGridReact
                )}
            />

            <ResourceCard triggerRefresh={triggerRefresh} key={'mto'} resourceType="mto" />
            <ConfirmDialog
                open={isConfirmDialogOpen}
                title={'Удалить строку?'}
                message="Вы не указали наименование ресурса, или пытаетесь добавить уже имеющийся ресурс. Строка будет удалена"
                onYes={{
                    action: (e) => {
                        cellEditingStoppedEvent?.api.applyTransaction({ remove: [{ id: 'draft' }] })
                        setIsConfirmDialogOpen(() => false)
                    },
                    btnText: 'Удалить',
                }}
                onNo={{
                    action: (e) => {
                        setIsConfirmDialogOpen(() => false)
                        cellEditingStoppedEvent?.api.setFocusedCell(
                            cellEditingStoppedEvent.rowIndex as number,
                            cellEditingStoppedEvent.context.resourceType === 'staff' ? 'nameProf' : 'name'
                        )
                        cellEditingStoppedEvent?.api.startEditingCell({
                            rowIndex: cellEditingStoppedEvent.rowIndex as number,
                            colKey: cellEditingStoppedEvent.context.resourceType === 'staff' ? 'nameProf' : 'name',
                        })
                    },
                    btnText: 'Отменить',
                }}
            />
        </ResourcesPageWrapper>
    )
}
