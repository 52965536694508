import { Drawer } from '@mui/material';

import AddProjectDrawer from '../../pages/Projects/component/AddProjectDrawer';
import ChangeProjectDrawer from '../../pages/Projects/component/ChangeProjectDrawer';
import {
    drawersSelector,
    toggleAddLevelKsgEquip,
    toggleAddLevelKsgPeople,
    toggleAddProject,
    toggleAutoSendSnz,
    toggleChangeCpgTemplate,
    toggleChangeMsgTemplate,
    toggleChangeProject,
    toggleCreateCpgTemplate,
    toggleCreateMsgTemplate,
    toggleEisReports,
    toggleNotificationsCenter,
    toggleProjectBackup,
    toggleSendSnz,
    toggleSyncModule,
    toggleSynchronize,
    toggleUpdateKSG,
    toggleUploadKsg,
    toggleUploadKsgEquip,
    toggleUploadKsgPeople,
    toggleUploadProgress,
} from '../../store/slices/drawersSlice';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import AddLevelKsgPeq from '../AddLevelKsgPeq/AddLevelKsgPeq';
import AutoSendSnzDrawer from '../AutoSendSnzDrawer/AutoSendSnzDrawer';
import { BackupDrawer } from '../BackupDrawer/BackupDrawer';
import { DeleteZeroValueFactDialog } from '../DeleteZeroValueFactDialog';
import { DistributionProgressDialog } from '../DistributionProgressDialog/DistributionProgressDialog';
import EisReportsDrawer from '../EisReportsDrawer/EisReportsDrawer';
import { NotificationsCenter } from '../NotificationsCenter/NotificationsCenter';
import SnzDrawer from '../SnzDrawer/SnzDrawer';
import SyncDone from '../SyncDone/SyncDone';
import SynchronizeDrawer from '../Synchronize/SynchronizeDrawer';
import ChangeKsgTemplate from '../TableTemplates/ChangeKsgTemplate';
import ChangeMsgTemplate from '../TableTemplates/ChangeMsgTemplate';
import CreateKsgTemplate from '../TableTemplates/CreateKsgTemplate';
import CreateMsgTemplate from '../TableTemplates/CreateMsgTemplate';
import { UpdateKSGDrawer } from '../UpdateKSGDrawer/UpdateKSGDrawer';
import UploadDrawerKsg from '../UploadDrawer/UploadDrawerKsg';
import UploadDrawerKsgEquip from '../UploadDrawer/UploadDrawerKsgEquip';
import UploadDrawerKsgPeople from '../UploadDrawer/UploadDrawerKsgPeople';
import { UploadProgressDialog } from '../UploadProgressDialog/UploadProgressDialog';

export default function DrawersEl() {
    const drawers = useTypedSelector(drawersSelector);

    const dispatch = useAppDispatch();
    return (
        <>
            {/* projects */}
            <Drawer
                anchor='right'
                open={drawers.addProject}
                onClose={() => dispatch(toggleAddProject())}
            >
                <AddProjectDrawer />
            </Drawer>
            <Drawer
                anchor='right'
                open={drawers.uploadKsg}
                onClose={() => dispatch(toggleUploadKsg())}
            >
                <UploadDrawerKsg />
            </Drawer>
            <Drawer
                anchor='right'
                open={drawers.uploadKsgPeople}
                onClose={() => dispatch(toggleUploadKsgPeople())}
            >
                <UploadDrawerKsgPeople />
            </Drawer>
            <Drawer
                anchor='right'
                open={drawers.uploadKsgEquip}
                onClose={() => dispatch(toggleUploadKsgEquip())}
            >
                <UploadDrawerKsgEquip />
            </Drawer>
            <Drawer
                anchor='right'
                open={drawers.changeProject.open}
                onClose={() => dispatch(toggleChangeProject())}
            >
                <ChangeProjectDrawer />
            </Drawer>
            {/*end projects*/}

            {/* snz */}
            <Drawer
                anchor='right'
                open={drawers.sendSnz}
                onClose={() => dispatch(toggleSendSnz())}
            >
                <SnzDrawer />
            </Drawer>
            <Drawer
                anchor='right'
                open={drawers.autoSendSnz}
                onClose={() => dispatch(toggleAutoSendSnz())}
            >
                <AutoSendSnzDrawer />
            </Drawer>
            {/* end snz */}

            {/* ksg templates */}
            <Drawer
                anchor='right'
                open={drawers.createCpgTemplate}
                onClose={() => dispatch(toggleCreateCpgTemplate())}
            >
                <CreateKsgTemplate />
            </Drawer>
            <Drawer
                anchor='right'
                open={drawers.changeCpgTemplate}
                onClose={() => dispatch(toggleChangeCpgTemplate())}
            >
                <ChangeKsgTemplate />
            </Drawer>
            {/* end ksg templates */}

            {/* msg templates */}
            <Drawer
                anchor='right'
                open={drawers.createMsgTemplate}
                onClose={() => dispatch(toggleCreateMsgTemplate())}
            >
                <CreateMsgTemplate />
            </Drawer>
            <Drawer
                anchor='right'
                open={drawers.changeMsgTemplate}
                onClose={() => dispatch(toggleChangeMsgTemplate())}
            >
                <ChangeMsgTemplate />
            </Drawer>
            {/* end msg templates */}

            {/* importingData */}
            <Drawer
                anchor='right'
                open={drawers.synchronize}
                onClose={() => dispatch(toggleSynchronize())}
            >
                <SynchronizeDrawer />
            </Drawer>
            {/* end importingData */}

            {/* notifications center */}
            <Drawer
                anchor='right'
                open={drawers.notificationsCenter}
                onClose={() => dispatch(toggleNotificationsCenter())}
            >
                <NotificationsCenter />
            </Drawer>
            {/* end notifications center */}

            {/* eis reports */}
            <Drawer
                anchor='right'
                open={drawers.eisReports}
                onClose={() => dispatch(toggleEisReports())}
            >
                <EisReportsDrawer />
            </Drawer>
            {/* end eis reports */}

            {/* sync module */}
            <Drawer
                anchor='right'
                open={drawers.syncModule}
                onClose={() => dispatch(toggleSyncModule())}
            >
                <SyncDone />
            </Drawer>
            {/* sync module */}

            {/* add level ksg people */}
            <Drawer
                anchor='right'
                open={drawers.addLevelKsgPeople}
                onClose={() => dispatch(toggleAddLevelKsgPeople())}
            >
                <AddLevelKsgPeq people />
            </Drawer>
            {/* end add level ksg people */}

            {/* add level ksg equip */}
            <Drawer
                anchor='right'
                open={drawers.addLevelKsgEquip}
                onClose={() => dispatch(toggleAddLevelKsgEquip())}
            >
                <AddLevelKsgPeq />
            </Drawer>
            {/* end add level ksg equip */}

            {/* add level ksg equip */}
            <Drawer
                anchor='right'
                open={drawers.updateKSG}
                onClose={() => {
                    dispatch(toggleUpdateKSG());
                    drawers?.uploadProgress?.activeTask && dispatch(toggleUploadProgress({ openDialog: 'wrapped' }));
                }}
            >
                <UpdateKSGDrawer />
            </Drawer>
            {/* end add level ksg equip */}

            <BackupDrawer
                open={drawers.projectBackup}
                onClose={() => {
                    dispatch(toggleProjectBackup());
                }}
            />

            <UploadProgressDialog
                open={drawers.uploadProgress.openDialog === true}
                onClose={() =>
                    !drawers.uploadProgress.supressOnClose && dispatch(toggleUploadProgress({ openDialog: 'wrapped' }))
                }
                suppressWatchBtn={drawers.uploadProgress.supressWatchBtn}
            />

            <DistributionProgressDialog open={drawers.distributionProgress.open} />

            <DeleteZeroValueFactDialog open={drawers.deleteZeroValueFact.open} />
        </>
    );
}
