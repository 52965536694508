import { ColDef, ICellEditorParams, ICellRendererParams } from 'ag-grid-community'
import { COL_DEF_TEMPLATE } from '../../../../components/AgGridService/AgGridColumnDef.service'
import { NumberCellRenderer } from '../../../../components/CellRenderers/NumberCellRenderer/NumberCellRenderer'
import { addNewRow, removeRow } from '../../ResourcePage.service'
import { IMTOResource } from '../../ResoursePage.types'

export interface IMtoColDef extends ColDef {
    field: keyof IMTOResource | undefined
    cellRenderer?: (params: ICellRendererParams<IMTOResource, number | string, any>) => React.ReactNode
    cellEditor?: ((params: ICellEditorParams<IMTOResource, number | string, any>) => React.ReactNode) | string
}

export const mtoTableColumnDef: IMtoColDef[] = [
    {
        ...COL_DEF_TEMPLATE.ADD_NEW_ROW_TABLE_COL((params) => addNewRow(getNewMtoRow(), params)),
    },
    {
        headerName: 'Наименование',
        field: 'name',
        editable: true,
        headerClass: 'ag-cell-left',
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
        cellEditorParams: {
            dataType: 'text',
            validation: {
                validate: {
                    trim: (v: string) => (v.trim() ? true : 'Заполните поле'),
                },
            },
        },

        cellClass: 'ag-cell-left ag-cell-editable ag-cell-px',
    },
    {
        headerName: 'Ед. изм',
        field: 'unit',
        maxWidth: 100,
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
    },
    {
        headerName: 'Цена за ед.',
        field: 'cost',
        maxWidth: 160,
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('cost'),
    },
    {
        headerName: 'Требуется ед.',
        field: 'required',
        maxWidth: 140,
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('required'),
    },
    {
        headerName: 'Стоимость на ЕР, р.',
        field: 'costRequired',
        maxWidth: 160,
        cellRenderer: (params) => (
            <NumberCellRenderer
                {...params}
                NumericFormatProps={{
                    suffix: ' р.',
                }}
            />
        ),
        valueGetter: (params) => {
            const unitQtyRequired = params.getValue('required')
            const costPerUnitRequired = params.getValue('cost')
            const targetValue = unitQtyRequired * costPerUnitRequired
            return targetValue
        },
    },
    {
        ...COL_DEF_TEMPLATE.DELETE_ROW_TABLE_COL((params) => removeRow(params)),
    },
]

export const newMtrRow: IMTOResource = {
    id: 0,
    name: 'Новая строка',
    required: 0,
    available: 0,
    ratio: 100,
    cost: 0,
    costAvailable: 0,
    costRequired: 0,
    unit: '',
}

function getNewMtoRow() {
    return {
        ...newMtrRow,
        id: Math.random(),
    }
}
