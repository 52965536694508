export type CompanyName = typeof companyNames[number]

export const companyNames = [
    'nn',
    'vls',
    'gm',
    'kfha',
    'pyrus',
    'nlmk',
    'oes',
    'most',
    'eks',
    'ap',
    'ig',
    'energomost',
    'sti',
    'rzd',
    'zs',
    'cg',
    'rgs',
    'si',
    'geo',
    'su555',
    'rusteko',
    'dml',
    'gcsu-555',
    'kvant',
    'krno',
    'tg',
    'rsk',
    'blacksea',
    'samolet',
] as const

export const isCompany = (companyName: any): companyName is CompanyName => {
    return companyNames.includes(companyName)
}
