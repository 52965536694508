import g from '../../shared/stylesheets/TableGrid.module.scss'
import { WritableDraft } from 'immer/dist/types/types-external'
import { GetMSGTreeRes, WorkMsg } from '../../api/msg/msg.def'
import { initMsgTemplateStateType } from '../../store/slices/msgTemplatesSlice'

const termPlusWidthObjMsg = {
    level: 130,
    code: 160,
    objTitle: 225,
    objName: 300,
    rdGroup: 180,
    rdCode: 180,
    rdStatus: 180,
    rdDateApproval: 200,
    rdVersion: 180,
    rdDate: 180,
    workGroup: 180,
    contractorCompany: 180,
    executorName: 225,
    workName: 300,
    unit: 130,
    volumeTotal: 180,
    volumeDone: 300,
    startDate: 180,
    endDate: 180,
    humanHourCost: 180,
    humanHoursTotal: 180,
    humanHoursProgressTZ: 180,
    humanHoursProgress: 430,
    costER: 180,
    costTotal: 180,
    costRemaining: 180,
}
export type termPlusWidthObjType = typeof termPlusWidthObjMsg

export const stickyColumnStylesMsg = (
    term: keyof termPlusWidthObjType,
    object: Omit<initMsgTemplateStateType, 'name'>,
    head?: boolean,
    minusWidth?: number,
    plusLeft?: number
) => {
    const subset = Object.keys(object)
        .filter((f) => object[f as keyof Omit<initMsgTemplateStateType, 'name'>].fix)
        .map((key) => {
            return {
                key,
                width: termPlusWidthObjMsg[key as keyof termPlusWidthObjType],
            }
        })

    const indexOf = subset.map((v) => v.key).indexOf(term)

    const toLeft = subset.reduce((acc: number, item: { key: string | null; width: number }, index: number) => {
        if (indexOf > index) {
            return acc + item.width
        } else {
            return acc
        }
    }, 0)

    return indexOf < 0
        ? {}
        : {
              /* left: toLeft + (plusLeft || 0),
 position: 'sticky' as any,
 width: termPlusWidthObjMsg[term] - (minusWidth || 0),
 zIndex: head ? 5 : 4,*/
          }
}

export const daysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate()
}

export const getCellBodyStylesMsg = (level: number | null, hasChildren: boolean) => {
    if (level === null) return

    const stylesByLevel = {
        0: `${g.level_zero} ${g.cell_body_span_3}`,
        1: `${g.level_one} ${g.cell_body_span_3}`,
        2: `${g.level_two} ${g.cell_body_span_3}`,
        3: `${g.level_three} ${g.cell_body_span_3}`,
        4: `${g.level_four} ${g.cell_body_span_3}`,
        5: `${g.level_five} ${g.cell_body_span_3}`,
        6: `${g.level_six} ${g.cell_body_span_3}`,
        7: `${g.level_seven} ${g.cell_body_span_3}`,
    }

    return hasChildren ? stylesByLevel[level as keyof typeof stylesByLevel] : stylesByLevel[7]
}

export const getRowWidthMsg = (length: number | undefined, msgTObj: Omit<initMsgTemplateStateType, 'name'>) => {
    if (!length) return
    const width = () => {
        let count = 5740

        if (!msgTObj.level.show) {
            count -= 130
        }
        if (!msgTObj.code.show) {
            count -= 160
        }
        if (!msgTObj.objTitle.show) {
            count -= 225
        }
        if (!msgTObj.rdDateApproval.show) {
            count -= 200
        }
        if (!msgTObj.objName.show) {
            count -= 300
        }
        if (!msgTObj.executorName.show) {
            count -= 225
        }
        if (!msgTObj.workName.show) {
            count -= 300
        }
        if (!msgTObj.unit.show) {
            count -= 130
        }
        if (!msgTObj.volumeDone.show) {
            count -= 300
        }
        if (!msgTObj.humanHoursProgress.show) {
            count -= 430
        }
        if (!msgTObj.rdCode.show) {
            count -= 180
        }
        if (!msgTObj.rdStatus.show) {
            count -= 180
        }
        if (!msgTObj.rdDate.show) {
            count -= 180
        }
        if (!msgTObj.rdGroup.show) {
            count -= 180
        }
        if (!msgTObj.rdVersion.show) {
            count -= 180
        }
        if (!msgTObj.workGroup.show) {
            count -= 180
        }
        if (!msgTObj.contractorCompany.show) {
            count -= 180
        }
        if (!msgTObj.startDate.show) {
            count -= 180
        }
        if (!msgTObj.endDate.show) {
            count -= 180
        }
        if (!msgTObj.humanHourCost.show) {
            count -= 180
        }
        if (!msgTObj.humanHoursTotal.show) {
            count -= 180
        }
        if (!msgTObj.humanHoursProgressTZ.show) {
            count -= 180
        }
        if (!msgTObj.volumeTotal.show) {
            count -= 180
        }
        if (!msgTObj.costER.show) {
            count -= 180
        }
        if (!msgTObj.costTotal.show) {
            count -= 180
        }
        if (!msgTObj.costRemaining.show) {
            count -= 180
        }

        return count
    }
    return length * 120 + width()
}

export function getGridTableStylesMsg(length: number | undefined, msgTObj: Omit<initMsgTemplateStateType, 'name'>) {
    if (!length) return

    return {
        width: getRowWidthMsg(length, msgTObj),
        display: 'grid',
        fontSize: '14px',
        gridTemplateColumns: `
        ${msgTObj.level.show ? '130px' : ''}
        100px
        ${msgTObj.code.show ? '160px' : ''}
        ${msgTObj.objTitle.show ? '225px' : ''}
        ${msgTObj.objName.show ? '300px' : ''}
        ${msgTObj.rdGroup.show ? '180px' : ''}
        ${msgTObj.rdCode.show ? '180px' : ''}
        ${msgTObj.rdStatus.show ? '180px' : ''}
        ${msgTObj.rdDateApproval.show ? '200px' : ''}
        ${msgTObj.rdVersion.show ? '180px' : ''}
        ${msgTObj.rdDate.show ? '180px' : ''}
        ${msgTObj.workGroup.show ? '180px' : ''}
        ${msgTObj.contractorCompany.show ? '180px' : ''}
        ${msgTObj.executorName.show ? '225px' : ''}
        ${msgTObj.workName.show ? '300px' : ''}
        ${msgTObj.unit.show ? '130px' : ''}
        ${msgTObj.volumeTotal.show ? '180px' : ''}
        repeat(2, 180px)
        ${msgTObj.volumeDone.show ? 'repeat(3, 100px)' : ''}
        ${msgTObj.startDate.show ? '180px' : ''}
        ${msgTObj.endDate.show ? '180px' : ''}
        ${msgTObj.humanHourCost.show ? '180px' : ''}
        ${msgTObj.humanHoursTotal.show ? '180px' : ''}
        ${msgTObj.humanHoursProgressTZ.show ? '180px' : ''}
        ${msgTObj.humanHoursProgress.show ? '100px 100px 100px 130px' : ''}
        ${msgTObj.costER.show ? '180px' : ''}
        ${msgTObj.costTotal.show ? '180px' : ''}
        ${msgTObj.costRemaining.show ? '180px' : ''}
       
        repeat(${length}, 120px)`,
    }
}

export const thirdRowCellsCountMsg = (msgTObj: Omit<initMsgTemplateStateType, 'name'>) => {
    let count = 32

    if (!msgTObj.level.show) {
        count -= 1
    }
    if (!msgTObj.code.show) {
        count -= 1
    }
    if (!msgTObj.objTitle.show) {
        count -= 1
    }
    if (!msgTObj.objName.show) {
        count -= 1
    }
    if (!msgTObj.rdCode.show) {
        count -= 1
    }
    if (!msgTObj.rdStatus.show) {
        count -= 1
    }
    if (!msgTObj.rdDate.show) {
        count -= 1
    }
    if (!msgTObj.rdGroup.show) {
        count -= 1
    }
    if (!msgTObj.rdDateApproval.show) {
        count -= 1
    }
    if (!msgTObj.rdVersion.show) {
        count -= 1
    }
    if (!msgTObj.workGroup.show) {
        count -= 1
    }
    if (!msgTObj.contractorCompany.show) {
        count -= 1
    }
    if (!msgTObj.executorName.show) {
        count -= 1
    }
    if (!msgTObj.workName.show) {
        count -= 1
    }
    if (!msgTObj.unit.show) {
        count -= 1
    }
    if (!msgTObj.volumeTotal.show) {
        count -= 1
    }
    if (!msgTObj.startDate.show) {
        count -= 1
    }
    if (!msgTObj.endDate.show) {
        count -= 1
    }
    if (!msgTObj.humanHourCost.show) {
        count -= 1
    }
    if (!msgTObj.humanHoursTotal.show) {
        count -= 1
    }
    if (!msgTObj.humanHoursProgressTZ.show) {
        count -= 1
    }
    if (!msgTObj.costER.show) {
        count -= 1
    }
    if (!msgTObj.costTotal.show) {
        count -= 1
    }
    if (!msgTObj.costRemaining.show) {
        count -= 1
    }

    if (!msgTObj.volumeDone.show) {
        count -= 3
    }

    if (!msgTObj.humanHoursProgress.show) {
        count -= 4
    }

    return count
}

export const updateLevelByIdMsg = (d: WritableDraft<WorkMsg[]>, wor: WorkMsg) => {
    const indexChange = d.findIndex((lev) => lev.id === wor.id)
    if (indexChange >= 0) {
        d.splice(indexChange, 1, wor)
    }
}

export const updateFirstAndSecondLevelMsg = (d: WritableDraft<GetMSGTreeRes | null>, wor: WorkMsg) => {
    if (!d) return
    d.data.forEach((lev1) => {
        if (lev1.payload.id === wor.id) {
            lev1.payload = wor
        }
    })
    d.data.forEach((parent) => {
        parent.children.forEach((lev2) => {
            if (lev2.payload.id === wor.id) {
                lev2.payload = wor
            }
        })
    })
}
