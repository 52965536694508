import {
    MobilizationPageWrapper,
    StyledControlPeriodSelect,
    StyledNoRowsOverlayComponent,
    StyledSelect,
} from './MobilizationSchedulePage.styles'
import { CardWrapper, TabsContained } from '../../components/components.styles'
import { CircularProgress, MenuItem, Tab, Typography } from '@mui/material'
import { TResourceType } from '../../api/api.types'
import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { req } from '../../api/api'
import { StyledAgGrid } from '../../components/AgGridService/AgGrid.styles'
import { AG_GRID_DEFAULT_PARAMS, disableAddAndDelete } from '../../components/AgGridService/AgGridColumnDef.service'
import { StaticMobilizationTableColDef, getDinamicColDef as getDynamicColDef } from './MobilizationSchedulePage.colDef'
import { IMobilizationScheduleRow, IMobilizationScheduleSearchParams } from './MobilizationSchedulePage.def'
import { ColDef, INoRowsOverlayParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { FlexRowWrapper } from '../../../NewExecutorView/components/components.styles'
import qs from 'qs'
import { getMonthOptions } from './MobilizationSchedulePage.service'
import { IAutocompleteOption } from '../../components/CellEditors/CellEditor/CellEditor.def'
import { profile } from 'console'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../../shared/rolePermissions'
import { useTypedSelector } from '../../../../store/store'
import { profileSelector } from '../../../../store/slices/profileSlice'

export function MobilizationSchedulePage() {
    const { projectId, workID } = useParams()
    const [total, setTotal] = useState<number>(0)
    const gridRef = useRef<AgGridReact>(null)
    const { profile } = useTypedSelector(profileSelector)
    const [availableYears, setAvailableYears] = useState<IAutocompleteOption[]>([
        {
            label: new Date().getFullYear().toString(),
            value: new Date().getFullYear().toString(),
        },
    ])
    const location = useLocation()
    const work = JSON.parse(localStorage.getItem('work')!)

    const DEFAULT_SEARCH_PARAMS = {
        resourceType: 'staff',
        year: new Date().getFullYear().toString(),
        month: (new Date().getMonth() + 1).toString(),
        type: 'MONTHLY',
        subType: 'shift',
    } as IMobilizationScheduleSearchParams

    const [searchParams, setSearchParams] = useSearchParams()

    function changeType(e: SyntheticEvent<Element, Event>, value: IMobilizationScheduleSearchParams['type']) {
        setSearchParams(
            (prevValue) => {
                const newSearchParams = {
                    ...qs.parse(prevValue.toString(), { ignoreQueryPrefix: true }),
                    type: value,
                } as any
                if (value === 'MONTHLY') {
                    delete newSearchParams.month
                } else {
                    newSearchParams.month = DEFAULT_SEARCH_PARAMS.month
                }
                return newSearchParams
            },
            {
                state: location.state,
            }
        )
    }

    function changeSearchParam(newValue: Partial<IMobilizationScheduleSearchParams>) {
        setSearchParams(
            (prevValue) =>
                ({
                    ...qs.parse(prevValue.toString(), { ignoreQueryPrefix: true }),
                    ...newValue,
                } as IMobilizationScheduleSearchParams),
            {
                state: location.state,
            }
        )
    }

    useEffect(() => {
        req.get(`/projects/${projectId}/works/${workID}/cabinet/mobilization/report/years`).then(({ data }) =>
            setAvailableYears(() =>
                data.availableYears?.map((year: number) => ({
                    label: year.toString(),
                    value: year.toString(),
                }))
            )
        )
    }, [])

    useEffect(() => {
        gridRef.current?.api?.showLoadingOverlay()
        gridRef.current?.api?.setColumnDefs([])
        gridRef.current?.api?.setRowData([])
        searchParams.get('resourceType') &&
            req
                .get(
                    `/projects/${projectId}/works/${workID}/cabinet/mobilization/report` + '?' + searchParams.toString()
                )
                .then(({ data }: any) => {
                    setTotal(data?.data?.length)
                    gridRef.current?.api.setRowData(
                        data?.data?.map((resource: IMobilizationScheduleRow, index: number) => ({
                            ...resource,
                            id: index,
                        }))
                    )
                    gridRef.current?.api.setColumnDefs([
                        ...StaticMobilizationTableColDef,
                        ...getDynamicColDef(data?.data, searchParams),
                    ] as ColDef<unknown>[])
                    setTimeout(() =>
                        data?.data?.length
                            ? gridRef?.current?.api?.hideOverlay()
                            : gridRef?.current?.api?.showNoRowsOverlay()
                    )
                })
                .catch((e) => {})

        return () => {
            gridRef.current?.api?.showLoadingOverlay()
        }
    }, [
        searchParams.get('resourceType'),
        searchParams.get('year'),
        searchParams.get('month'),
        searchParams.get('type'),
    ])

    // useEffect(() => {
    //     total === 0 ? gridRef.current?.api?.showNoRowsOverlay() : gridRef?.current?.api?.hideOverlay()
    // }, [total, gridRef])

    useEffect(() => {
        gridRef?.current?.api?.refreshCells({
            force: true,
        })
    }, [searchParams.get('subType')])

    useEffect(() => {
        !searchParams.get('resourceType') && setSearchParams(() => DEFAULT_SEARCH_PARAMS, { state: location.state })
    }, [])

    return (
        <MobilizationPageWrapper>
            <CardWrapper>
                <FlexRowWrapper justifyContent={'space-between'}>
                    <StyledSelect
                        value={searchParams.get('resourceType')}
                        autoWidth
                        onChange={(e) =>
                            changeSearchParam({
                                resourceType: e.target.value as TResourceType,
                            })
                        }
                    >
                        <MenuItem value="staff">Трудозатраты</MenuItem>
                        <MenuItem value="mim">МиМ</MenuItem>
                    </StyledSelect>

                    <TabsContained
                        value={searchParams.get('subType')}
                        onChange={(e, newValue) =>
                            changeSearchParam({
                                subType: newValue,
                            })
                        }
                    >
                        <Tab value={'shift'} label="Смены"></Tab>
                        <Tab value={'hour'} label="Часы"></Tab>
                    </TabsContained>

                    <FlexRowWrapper gap={2} alignItems={'center'}>
                        <FlexRowWrapper gap={1}>
                            <Typography>Год:</Typography>
                            <StyledControlPeriodSelect
                                value={searchParams.get('year')}
                                autoWidth
                                onChange={(e) =>
                                    changeSearchParam({
                                        year: e.target.value as TResourceType,
                                    })
                                }
                            >
                                {availableYears &&
                                    availableYears.map((year) => <MenuItem value={year.value}>{year.label}</MenuItem>)}
                            </StyledControlPeriodSelect>
                        </FlexRowWrapper>

                        {searchParams.get('type') === 'DAILY' && (
                            <FlexRowWrapper gap={1}>
                                <Typography>Месяц:</Typography>
                                <StyledControlPeriodSelect
                                    value={searchParams.get('month')}
                                    autoWidth
                                    onChange={(e) =>
                                        changeSearchParam({
                                            month: e.target.value as TResourceType,
                                        })
                                    }
                                >
                                    {getMonthOptions().map((option) => {
                                        return <MenuItem value={option.value}>{option.label}</MenuItem>
                                    })}
                                </StyledControlPeriodSelect>
                            </FlexRowWrapper>
                        )}

                        <TabsContained value={searchParams.get('type')} onChange={changeType}>
                            <Tab value={'DAILY'} label="Месяц"></Tab>
                            <Tab value={'MONTHLY'} label="Год"></Tab>
                        </TabsContained>
                    </FlexRowWrapper>
                </FlexRowWrapper>

                <StyledAgGrid
                    ref={gridRef}
                    {...AG_GRID_DEFAULT_PARAMS()}
                    noRowsOverlayComponent={(params: INoRowsOverlayParams) => (
                        <StyledNoRowsOverlayComponent>Нет данных</StyledNoRowsOverlayComponent>
                    )}
                    loadingOverlayComponent={(params: INoRowsOverlayParams) => (
                        <StyledNoRowsOverlayComponent>
                            <CircularProgress />
                        </StyledNoRowsOverlayComponent>
                    )}
                    context={{
                        searchParams: searchParams,
                        work: work,
                        profile: profile,
                    }}
                    rowHeight={50}
                    getRowId={(params) => (params.data as IMobilizationScheduleRow)?.id}
                    {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_MANAGMENT') ||
                        JSON.parse(localStorage.getItem('work')!)?.hasChildren) && {
                        suppressClickEdit: true,
                        ...disableAddAndDelete(true),
                    })}
                />
            </CardWrapper>
        </MobilizationPageWrapper>
    )
}
