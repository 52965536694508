import uniqolor from 'uniqolor'
import React from 'react'

/*{
    "start": {
        "y1": 16,
        "x1": 5
},
    "end": {
        "y2": 6,
        "x2": 2
}
}*/

export default function LineEl({
    x1,
    y1,
    x2,
    y2,
    bgColor,
}: {
    x1: number
    y1: number
    x2: number
    y2: number
    bgColor?: string
}) {
    const color = bgColor || uniqolor(Math.random() * 100, { format: 'rgb' }).color

    const radius = 12
    const smallRadius = 8
    const offsetDistance = 12
    const arrowTopAndBottom = 3
    const arrowLength = 5

    const topLeft = (
        <path
            stroke={color}
            strokeWidth="2"
            fill="none"
            d={`M${x1},${y1} L${
                x1 + offsetDistance - smallRadius
            },${y1} a${smallRadius},${smallRadius} 1 0 0 ${smallRadius},-${smallRadius} L${x1 + offsetDistance},${
                (y2 - y1) / 2 + y1 + radius
            } a${radius},${radius} 1 0 0 -${radius},-${radius} L${x2 + radius - offsetDistance},${
                (y2 - y1) / 2 + y1
            }  a${radius},${radius} 0 0 1 -${radius},-${radius} L${x2 - offsetDistance},${
                y2 + smallRadius
            } a${smallRadius},${smallRadius} 0 0 1 ${smallRadius},-${smallRadius} L${x2},${y2} L${x2 - arrowLength},${
                y2 - arrowTopAndBottom
            } L${x2 - arrowLength},${y2 + arrowTopAndBottom} L${x2},${y2}`}
        />
    )
    const topRight = (
        <>
            <path
                stroke={color}
                strokeWidth="2"
                fill="none"
                d={`M${x1},${y1} L${
                    (x2 - x1) / 2 + x1 - radius
                },${y1} a${radius},${radius} 1 0 0 ${radius},-${radius} L${(x2 - x1) / 2 + x1},${
                    y2 + radius
                }  a${radius},${radius} 0 0 1 ${radius},-${radius} L${x2},${y2} L${x2 - arrowLength},${
                    y2 - arrowTopAndBottom
                } L${x2 - arrowLength},${y2 + arrowTopAndBottom} L${x2},${y2}`}
            />
        </>
    )
    const bottomLeft = (
        <>
            <path
                stroke={color}
                strokeWidth="2"
                fill="none"
                d={`M${x1},${y1} L${
                    x1 + offsetDistance - smallRadius
                },${y1} a${smallRadius},${smallRadius} 0 0 1 ${smallRadius},${smallRadius} L${x1 + offsetDistance},${
                    (y2 - y1) / 2 + y1 - radius
                } a${radius},${radius} 0 0 1 -${radius},${radius} L${x2 + radius - offsetDistance},${
                    (y2 - y1) / 2 + y1
                }  a${radius},${radius} 1 0 0 -${radius},${radius} L${x2 - offsetDistance},${
                    y2 - smallRadius
                } a${smallRadius},${smallRadius} 1 0 0 ${smallRadius},${smallRadius} L${x2},${y2} L${
                    x2 - arrowLength
                },${y2 - arrowTopAndBottom} L${x2 - arrowLength},${y2 + arrowTopAndBottom} L${x2},${y2}`}
            />
        </>
    )
    const bottomRight = (
        <>
            <path
                stroke={color}
                strokeWidth="2"
                fill="none"
                d={`M${x1},${y1} L${
                    (x2 - x1) / 2 + x1 - radius
                },${y1} a${radius},${radius} 0 0 1 ${radius},${radius} L${(x2 - x1) / 2 + x1},${
                    y2 - radius
                }  a${radius},${radius} 1 0 0 ${radius},${radius} L${x2},${y2} L${x2 - arrowLength},${
                    y2 - arrowTopAndBottom
                } L${x2 - arrowLength},${y2 + arrowTopAndBottom} L${x2},${y2}`}
            />
        </>
    )

    if (x1 < x2) {
        if (y1 < y2) {
            return bottomRight
        } else {
            return topRight
        }
    } else {
        if (y1 < y2) {
            return bottomLeft
        } else {
            return topLeft
        }
    }
}
