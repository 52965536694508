import { Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useInitMSGMutation } from '../../api/msg/msg.api'
import { useInitMsgEquipMutation } from '../../api/msgEquip/msgEquip.api'
import { useInitMsgPeopleMutation } from '../../api/msgPeople/msgPeople.api'
import { monthMsgSelector } from '../../store/slices/monthMsgSlice'
import { useTypedSelector } from '../../store/store'
import './smd.scss'

type TOptions = 'proportion' | 'zero' | 'nothing'

type TProps = {
    people?: boolean
    equip?: boolean
    setTriggerRerender?: Dispatch<SetStateAction<boolean>>
}

const ShowMsgDialog: React.FC<TProps> = ({ people, equip, setTriggerRerender }) => {
    const [type, setType] = useState<TOptions>('proportion')

    const { month, year } = useTypedSelector(monthMsgSelector)

    const [initReqPeople, initPeopleRes] = useInitMsgPeopleMutation()
    const [initReqEquip, initEquipRes] = useInitMsgEquipMutation()
    const [initReqMsg, initMSGRes] = useInitMSGMutation()

    const { projectId } = useParams()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('cpg')

    const payload = useMemo(
        () => ({
            id: Number(projectId),
            body: {
                year: year,
                month: month + 1,
                type: type,
            },
        }),
        [projectId, year, month, type]
    )

    const processSuccess = () => {
        enqueueSnackbar(t('success'), {
            variant: 'success',
        })
        if (setTriggerRerender) {
            setTriggerRerender((p) => !p)
        }
    }

    const processError = (e?: any) => {
        enqueueSnackbar('Ошибка', {
            variant: 'error',
        })
        console.error(e)
    }

    const handleSubmit = () => {
        if (people) return initReqPeople(payload).unwrap().then(processSuccess).catch(processError)
        if (equip) return initReqEquip(payload).unwrap().then(processSuccess).catch(processError)

        return initReqMsg(payload).unwrap().then(processSuccess).catch(processError)
    }

    if (initPeopleRes.isLoading || initEquipRes.isLoading || initMSGRes.isLoading) {
        return (
            <div style={{ padding: '4rem' }} className="progress">
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="smd">
            <div className="smd__top">
                <h4>Создать МСГ</h4>
                <p>
                    Создание месячно-суточного графика из календарно-сетевого графика. Выберите пожалуйста способ
                    создания МСГ:{' '}
                </p>
            </div>
            <div className="smd__middle">
                <FormControl>
                    <RadioGroup value={type} onChange={(event) => setType(event.target.value as TOptions)}>
                        <FormControlLabel value="proportion" control={<Radio />} label="Пропорционально дням" />
                        <FormControlLabel value="nothing" control={<Radio />} label="Без распределения" />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="smd__bottom">
                <p>
                    При пропорциональном способе, весь запланированный объем на месяц будет разнесен пропорционально
                    дням месяца, на каждый день.
                </p>
                <p>В любой момент времени, Вы можете изменить план работ на день.</p>
            </div>
            <div className="smd__buttons">
                <Button sx={{ width: '10rem' }} variant="contained" color="success" onClick={() => handleSubmit()}>
                    создать
                </Button>
                <Link to={`/ksg/${projectId}`}>
                    <Button sx={{ width: '10rem' }} variant="contained">
                        отменить
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default ShowMsgDialog
