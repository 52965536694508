import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
    useNotificationsIncreasePeriodMutation,
    useNotificationsIncreaseVolumeMutation,
    useNotificationsListQuery,
    useNotificationsLookMutation,
} from '../../api/notificationsCenter/notificationsCenter.api'
import {
    LIMIT,
    calculateOffset,
    calculatePaginationCount,
    getAlertIds,
    hasNotificationsByTotal,
} from './NotificationsCenter.utils'

export const useNotificationsCenter = () => {
    const { projectId } = useParams()
    const [page, setPage] = React.useState(1)

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('mutations')

    const {
        data: notificationResponse,
        refetch: refetchNotifications,
        isLoading: isLoadingNotifications,
    } = useNotificationsListQuery({
        projectId: Number(projectId),
        limit: LIMIT,
        offset: calculateOffset(page),
    })

    const total = notificationResponse?.total
    const notifications = notificationResponse?.data

    const [increasePeriod, { isLoading: isLoadingIncreasePeriod }] = useNotificationsIncreasePeriodMutation()
    const [increaseVolume, { isLoading: isLoadingIncreaseVolume }] = useNotificationsIncreaseVolumeMutation()
    const [lookNotifications, { isLoading: isLoadingLookNotifications }] = useNotificationsLookMutation()

    const hasNotifications = hasNotificationsByTotal(total)
    const paginationCount = calculatePaginationCount(total)

    const { volumeIncreaseIds, periodIncreaseIds } = getAlertIds(notifications)

    const handleVolumeIncrease = (alertIds: number[] | undefined) => () => {
        if (!alertIds) return
        if (isLoadingIncreaseVolume) return

        increaseVolume({ projectId: Number(projectId), body: { alertIds: alertIds } })
            .unwrap()
            .then((res) => {
                if (res.success) {
                    enqueueSnackbar(t('notifications_volume_increased'), {
                        variant: 'success',
                    })
                } else {
                    enqueueSnackbar(res.description, {
                        variant: 'error',
                    })
                }
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    const handlePeriodIncrease = (alertIds: number[] | undefined) => () => {
        if (!alertIds) return
        if (isLoadingIncreasePeriod) return

        increasePeriod({ projectId: Number(projectId), body: { alertIds: alertIds } })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('notifications_period_increased'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    const handleNotificationsLook = () => {
        if (isLoadingLookNotifications) return

        lookNotifications({
            projectId,
        })
            .unwrap()
            .then(() => {
                refetchNotifications()
                enqueueSnackbar(t('look_notifications'), {
                    variant: 'success',
                })
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                })
                console.error(e)
            })
    }

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => setPage(value)

    const loading = {
        circularProgress: isLoadingNotifications || isLoadingLookNotifications,
        linearProgress: isLoadingIncreasePeriod || isLoadingIncreaseVolume,
        lookNotifications: isLoadingLookNotifications,
        increasePeriod: isLoadingIncreasePeriod,
        increasePeriodAll: isLoadingIncreasePeriod || !periodIncreaseIds?.length,
        increaseVolume: isLoadingIncreaseVolume,
        increaseVolumeAll: isLoadingIncreaseVolume || !volumeIncreaseIds?.length,
    }

    const pagination = {
        count: paginationCount,
        page: page,
        onChange: handlePageChange,
    }

    return {
        notifications,
        hasNotifications,
        loading,
        pagination,

        handleVolumeIncrease,
        handleVolumeIncreaseAll: handleVolumeIncrease(volumeIncreaseIds),
        handlePeriodIncrease,
        handlePeriodIncreaseAll: handlePeriodIncrease(periodIncreaseIds),
        handleNotificationsLook,
    }
}
