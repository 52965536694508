import { ICellRendererParams } from 'ag-grid-community'
import { useParams } from 'react-router-dom'
import { req } from '../../../WorkManagment/api/api'
import { NotificatorContainer } from '../NotificatorContainer/NotificatorContainer'
import { SpreadPlanBtn } from '../SpreadPlanBtn/SpreadPlanBtn'

interface IProps extends ICellRendererParams {
    textValue: string
    canSpread: boolean
    isVisibleNotificator: boolean
    notificatorTitle: string
    typeOfPlan: 'base' | 'operational'
}

export const DateCellRenderer: React.FC<IProps> = ({
    textValue,
    isVisibleNotificator,
    canSpread,
    typeOfPlan,
    notificatorTitle,
    ...params
}) => {
    const { projectId } = useParams()
    const data = params?.data

    const isVisibleSpreadBtn = () => {
        if (!canSpread) return false
        if (data?.hasChildren && params?.data?.level === 0) return true
        if (data?.hasChildren === false) return true
        return false
    }

    const getUrl = () => {
        if (data?.hasChildren && params?.data?.level === 0) {
            const m = {
                base: 'base',
                operational: 'plan-fact',
            }

            return `/projects/${projectId}/works/ksg/distribute/${m[typeOfPlan]}`
        }

        return null
    }

    const spreadPlan = () => {
        const url = getUrl()

        if (!url) return

        req.post(url)
            .then(({ data }) => {
                console.log('data', data)
            })
            .then(() => {})
            .catch((e) => {})
    }

    const getSpreadPlan = () => {
        if (data?.hasChildren && params?.data?.level === 0) {
            return spreadPlan
        }

        return null
    }

    return (
        <>
            <NotificatorContainer isVisible={isVisibleNotificator} title={notificatorTitle}>
                {textValue}
            </NotificatorContainer>
            {isVisibleSpreadBtn() && (
                <SpreadPlanBtn
                    url={getUrl()}
                    typeOfPlan={typeOfPlan}
                    cellRendererParams={params}
                    spreadPlanFunc={getSpreadPlan()}
                />
            )}
        </>
    )
}
