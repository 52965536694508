import { Switch } from '@mui/material'
import { ColDef, ICellEditorParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../../../../../shared/rolePermissions'
import { COL_DEF_TEMPLATE } from '../../../../components/AgGridService/AgGridColumnDef.service'
import { removeRow } from '../../ResourcePage.service'
import { IMIMResource } from '../../ResoursePage.types'

export interface IMimColDef extends ColDef {
    field: keyof IMIMResource | undefined
    cellRenderer?: (params: ICellRendererParams<IMIMResource, number | string, any>) => React.ReactNode
    cellEditor?: ((params: ICellEditorParams<IMIMResource, number | string, any>) => React.ReactNode) | string
}

function addNewRow(params: IHeaderParams) {
    params.api.applyTransaction({
        add: [
            {
                id: 'draft',
                name: '',
                tzmIncluded: false,
                plan: 0,
            },
        ],
    })
    setTimeout(() => {
        params.api.startEditingCell({
            rowIndex: params.api.getRowNode('draft')?.rowIndex as number,
            colKey: 'name',
        })
        params.api.setFocusedCell(params.api.getRowNode('draft')?.rowIndex as number, 'name')
        params.api.ensureNodeVisible(params.api.getRowNode('draft'))
    })
}

export const mimTableColumnDef = (AgGrid: AgGridReact): IMimColDef[] => [
    {
        ...COL_DEF_TEMPLATE.ADD_NEW_ROW_TABLE_COL((params) => addNewRow(params)),
    },
    {
        headerName: 'Наименование техники',
        field: 'name',
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
        cellEditorParams: {
            dataType: 'text',
            validation: {
                validate: {
                    trim: (v: string) => (v.trim() ? true : 'Заполните поле'),
                    s: (v: string) => {
                        const excludeNames: string[] = []
                        AgGrid?.api?.forEachNode((row) => excludeNames.push(row?.data?.name))
                        return !excludeNames.includes(v) || 'Уже есть такой ресурс'
                    },
                },
            },
        },
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left ag-cell-editable ag-cell-px',
    },
    {
        headerName: 'ТЗМ',
        field: 'tzmIncluded',
        maxWidth: 60,

        cellRenderer: (params) => {
            return (
                <Switch
                    disabled={JSON.parse(localStorage.getItem('work')!)?.hasChildren}
                    checked={params.data?.tzmIncluded}
                    size="small"
                    inputProps={{ 'aria-label': 'controlled' }}
                    defaultChecked={params.api.getValue('tzmIncluded', params.node)}
                    onChange={(e) => {
                        DOES_ROLE_HAS_ACCESS_TO_FEATURE(params.context.profile.role, 'EDIT_WORK_MANAGMENT') &&
                            params.api.applyTransactionAsync(
                                {
                                    update: [{ ...params?.data!, tzmIncluded: e.target.checked }],
                                },
                                () => console.log('callback')
                            )
                    }}
                />
            )
        },
    },
    {
        headerName: 'План, м.ч. (на ЕР)',
        field: 'plan',
        maxWidth: 150,
        minWidth: 150,
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('plan'),
    },
    {
        ...COL_DEF_TEMPLATE.DELETE_ROW_TABLE_COL((params) => removeRow(params)),
    },
]

export const newMimRow: IMIMResource = {
    id: 0,
    name: 'Новая строка',
    plan: 0,
    tzmIncluded: false,
}

function getNewMimRow() {
    return {
        ...newMimRow,
        id: Math.random(),
    }
}
