import { Checkbox, Radio, Switch } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { IWorkForCopy } from '../CopyResourcesDrawer.def'
import { addWorkToList, switchResource, switchWork } from './WorksList.service'

export const workListColDef: ColDef[] = [
    {
        headerName: '',
        field: 'checked',
        width: 40,
        cellRenderer: (params: ICellRendererParams<IWorkForCopy>) => {
            // return params.context?.copyMode === 'from' ? (
            return (
                <Checkbox
                    sx={{ p: 0 }}
                    disableRipple
                    checked={params.value}
                    size="small"
                    onChange={(e, checked) =>
                        addWorkToList({
                            params: params,
                            checked: checked,
                        })
                    }
                />
            )
            // ) : (
            //     <Radio
            //         sx={{ p: 0 }}
            //         disableRipple
            //         size="small"
            //         checked={params.value}
            //         onChange={(e, checked) =>
            //             switchWork({
            //                 params: params,
            //                 checked: checked,
            //             })
            //         }
            //     />
            // )
        },
        // valueGetter: (params) => params.api.getValue("checked", params.node as IRowNode)
    },
    {
        headerName: '№',
        field: 'work.num',
        width: 50,
    },
    {
        headerName: 'Наименование работы',
        field: 'work.name',
        minWidth: 200,
        flex: 2,
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left',
    },
    {
        headerName: 'Группа работ',
        field: 'work.workGroup',
        minWidth: 200,
        flex: 2,
        headerClass: 'ag-cell-left',
        cellClass: 'ag-cell-left',
    },
    {
        headerName: 'Ед. изм.',
        field: 'work.unit',
        width: 70,
    },
    {
        headerName: 'Трудозатраты',
        field: 'choice.staff',
        width: 80,
        cellRenderer: (params: ICellRendererParams<IWorkForCopy>) => {
            return (
                <Switch
                    size="small"
                    disabled={params.data?.initialChoice?.staff === false}
                    checked={params.value}
                    onChange={(e, checked) => {
                        switchResource({
                            params: params,
                            resourceType: 'staff',
                            checked: checked,
                        })
                    }}
                />
            )
        },
    },
    {
        headerName: 'МиМ',
        field: 'choice.mim',
        width: 80,
        cellRenderer: (params: ICellRendererParams<IWorkForCopy>) => {
            return (
                <Switch
                    size="small"
                    disabled={params.data?.initialChoice?.mim === false}
                    checked={params.value}
                    onChange={(e, checked) => {
                        switchResource({
                            params: params,
                            resourceType: 'mim',
                            checked: checked,
                        })
                    }}
                />
            )
        },
    },
]
