import { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import ErrorsList from '../../shared/components/ErrorsList/ErrorsList'
import s from '../../pages/Projects/component/ProjectsDrawer.module.scss'
import DescriptionAndSelectUploadDrawer from './components/DescriptionAndSelectUploadDrawer'
import UploadAgain from './components/UploadAgain'
import placeImg from '../../pages/Projects/component/Headline.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { FadeLoader } from 'react-spinners'
import DownloadIcon from '@mui/icons-material/Download'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import UploadButtons from './components/UploadButtons'
import { useGetExcelLinkPeopleQuery, useUploadExcelPeopleMutation } from '../../api/ksgPeople/ksgPeople.api'
import { useAppDispatch } from '../../store/store'
import { toggleUploadKsgPeople } from '../../store/slices/drawersSlice'

export default function UploadDrawerKsgPeople() {
    const [showErrorsList, setShowErrorsList] = useState(false)
    const [showUploadAgain, setShowUploadAgain] = useState(false)
    const [areDatesBinded, setAreDatesBinded] = useState(false)
    const [uploadExPeopleReq, uploadExPeopleRes] = useUploadExcelPeopleMutation()

    const { t } = useTranslation('files')
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useAppDispatch()

    const exLinkPeople = useGetExcelLinkPeopleQuery()

    const { projectId } = useParams()

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            acceptedFiles.forEach((file: File) => {
                const formData = new FormData()

                formData.append('binding', String(areDatesBinded))

                formData.append('file', file)
                uploadExPeopleReq({
                    id: Number(projectId),
                    body: formData,
                })
            })
            setShowUploadAgain(false)
        },
        [areDatesBinded, projectId, uploadExPeopleReq]
    )

    function onDropRejected() {
        enqueueSnackbar(t('upload.wrong_extension'), {
            variant: 'error',
        })
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
    })

    function disabledButton() {
        return uploadExPeopleRes.status !== 'fulfilled' || uploadExPeopleRes.data?.error !== null
    }

    if (showErrorsList) {
        return (
            <ErrorsList
                setShowErrorsList={setShowErrorsList}
                errors={uploadExPeopleRes.data?.error}
                title="Информация об ошибках"
            />
        )
    }

    return (
        <main className={s.main_awd}>
            <h1>Загрузка КСГ Персонал</h1>
            <div className={s.awd_container}>
                <DescriptionAndSelectUploadDrawer />

                <div className={s.awd_loader}>
                    {uploadExPeopleRes.isSuccess && uploadExPeopleRes.data?.error !== null ? (
                        <Fragment>
                            {!showUploadAgain ? (
                                <UploadAgain
                                    length={uploadExPeopleRes.data?.error.length}
                                    setShowUploadAgain={setShowUploadAgain}
                                    setShowErrorsList={setShowErrorsList}
                                />
                            ) : (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <img src={placeImg} alt="" />
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {!uploadExPeopleRes.isLoading ? (
                                <Fragment>
                                    {uploadExPeopleRes.isSuccess ? (
                                        <div className={s.awd_loader_success}>
                                            <CheckCircleIcon
                                                sx={{
                                                    color: '#22c55e',
                                                    fontSize: '2rem',
                                                }}
                                            />
                                            <p>
                                                Успешно обработано! <br /> Всего добавлено строк:{' '}
                                                {uploadExPeopleRes.data.create}
                                            </p>
                                        </div>
                                    ) : (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={placeImg} alt="" />
                                        </div>
                                    )}
                                </Fragment>
                            ) : (
                                <FadeLoader />
                            )}
                        </Fragment>
                    )}
                </div>

                <div className={s.awd_download}>
                    <DownloadIcon />
                    <p onClick={() => window.open(exLinkPeople.data?.data, '_blank')}>СКАЧАТЬ ФОРМУ ДЛЯ КСГ</p>
                </div>

                <div className={s.awd_download}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={areDatesBinded}
                                    onChange={(e) => setAreDatesBinded(e.target.checked)}
                                    defaultChecked
                                />
                            }
                            label="Привязать даты"
                        />
                    </FormGroup>
                </div>

                <UploadButtons
                    disableButtons={disabledButton()}
                    isLoading={uploadExPeopleRes.isLoading}
                    onClose={() => dispatch(toggleUploadKsgPeople())}
                />
            </div>
        </main>
    )
}
