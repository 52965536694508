import { dailyChart } from '../../api/msg/msg.def'
import { ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { monthsList } from '../../layouts/TableLayout/components/ChangeMonthTableTop'
import { store } from '../../store/store'
import { msgPeopleEquipI } from '../../api/msgEquip/msgEquip.def'
import React from 'react'
import { Work } from '../../api/ksg/ksg.def'

export const getHeadersMonthsMsgEquip = (
    daysArr: Omit<dailyChart, 'drawFact' | 'drawPlan'>[] | undefined,
    callback: (data: ICellRendererParams<msgPeopleEquipI, any, any>, index: number) => React.ReactElement
) => {
    const header = monthsList[store.getState().monthMsg.month] + ', ' + store.getState().monthMsg.year

    const finalObj: {
        headerName: string
        suppressMovable: boolean
        children: {
            field: 'dailyCharts'
            minWidth: 120
            maxWidth: 120
            headerName: number
            suppressMovable: boolean
            headerComponent: (data: IHeaderParams<Work, any>) => React.ReactElement
            cellRenderer: (data: ICellRendererParams<msgPeopleEquipI, any, any>) => any
        }[]
    } = {
        headerName: header,
        suppressMovable: true,
        children: [],
    }
    daysArr?.forEach((obj, index) => {
        finalObj.children.push({
            field: 'dailyCharts',
            minWidth: 120,
            maxWidth: 120,
            headerName: index + 1,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        borderRadius: '0.5rem',
                        padding: '0.5rem 0',
                        backgroundColor:
                            index + 1 === new Date().getDate() &&
                            store.getState().monthMsg.month === new Date().getMonth()
                                ? '#3e5a81'
                                : undefined,
                    }}
                >
                    {data.displayName}
                </div>
            ),
            suppressMovable: true,
            cellRenderer: (data: ICellRendererParams<msgPeopleEquipI, any, any>) => callback(data, index),
        })
    })
    return finalObj
}
