import AddIcon from '@mui/icons-material/Add';
import { TabContext, TabList } from '@mui/lab';
import { Button, CircularProgress, Tab } from '@mui/material';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { usePermissionsByRole } from '@/hooks/usePermissionsByRole';

import { SvgAnalytics, SvgWorker } from '@/shared/SVG/Svg';
import EmptyPlaceholder from '@/shared/components/EmptyPlaceholder/EmptyPlaceholder';

import { toggleAddProject } from '@/store/slices/drawersSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { useTypedSelector } from '@/store/store';

import { useGetData, useGetFilteredData, useTabs } from './Projects.model';
import styles from './Projects.module.scss';
import { projectsTerms } from './Projects.service';
import AdminCard from './component/AdminCard';
import { Dot } from './component/Dot';
import UserCard from './component/UserCard';

const ChartsLegend = () => {
    return (
        <div className={styles.dots}>
            <Dot
                color='#FFB1B1'
                title='факт'
            />
            <Dot
                color='#6D9ADC'
                title='план'
            />
            <Dot
                color='#d4dce7'
                title='базовый план'
            />
        </div>
    );
};

const Tabs = ({
    tab,
    onChange,
    isEmptyTab,
}: {
    tab: projectsTerms;
    onChange: (event: React.SyntheticEvent, newValue: projectsTerms) => void;
    isEmptyTab: (status: projectsTerms) => boolean;
}) => {
    return (
        <TabContext value={tab}>
            <TabList
                sx={{
                    '.MuiButtonBase-root': {
                        fontWeight: 600,
                        fontSize: 14,
                    },
                }}
                onChange={onChange}
                aria-label='lab API tabs example'
            >
                <Tab
                    label='Все проекты'
                    value='Все'
                />
                <Tab
                    label='Активные'
                    disabled={isEmptyTab('Активный')}
                    value='Активный'
                />
                <Tab
                    label='Приостановленные'
                    disabled={isEmptyTab('Приостановлен')}
                    value='Приостановлен'
                />
                <Tab
                    label='Завершенные'
                    disabled={isEmptyTab('Завершенный')}
                    value='Завершенный'
                />
            </TabList>
        </TabContext>
    );
};

const ProjectsEmptyPlaceholder = () => {
    const dispatch = useDispatch();
    const { profile } = useTypedSelector(profileSelector);
    const { doesNotHaveFeatureAccess } = usePermissionsByRole();

    if (doesNotHaveFeatureAccess('CREATE_PROJECT')) {
        return <EmptyPlaceholder text={`Здравствуйте ${profile.firstName}, у вас еще нет проектов для отображения.`} />;
    }

    return (
        <EmptyPlaceholder
            text={`Здравствуйте ${profile.firstName}, у вас еще нет проектов для отображения.
                    Давайте создадим ваш первый проект.`}
            onClick={() => dispatch(toggleAddProject())}
            icon={<AiOutlinePlus />}
            buttonText={
                profile?.role === 'executor' || profile?.role === 'none' || profile.role === 'client'
                    ? undefined
                    : 'добавить проект'
            }
        />
    );
};

export default function Projects({ charts }: { charts?: boolean }) {
    const { tab, onChange: onTabChange } = useTabs();

    const { hasFeatureAccess } = usePermissionsByRole();

    const { projects, isLoading } = useGetData(charts);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { filteredProjects, placeholder } = useGetFilteredData(projects, tab);

    const isEmptyTab = (status: projectsTerms) => !projects.some((v) => v.status === status);

    const renderList = () => {
        if (!filteredProjects.length) return <EmptyPlaceholder text={placeholder} />;

        return (
            <section className={charts ? styles.user_cards : styles.admin_cards}>
                {filteredProjects.map((project) => (
                    <div key={project.id}>
                        {charts ? <UserCard project={project} /> : <AdminCard project={project} />}
                    </div>
                ))}
            </section>
        );
    };

    if (isLoading) {
        return (
            <div className='progress'>
                <CircularProgress />
            </div>
        );
    }

    if (projects.length === 0) return <ProjectsEmptyPlaceholder />;

    return (
        <main className={styles.main}>
            <section className={styles.top_bar}>
                <Tabs
                    tab={tab}
                    onChange={onTabChange}
                    isEmptyTab={isEmptyTab}
                />

                <div className={styles.top_right}>
                    {charts && <ChartsLegend />}
                    <div className={styles.buttons_container}>
                        <button
                            className={`${styles.left_view_button}  ${!charts && styles.active_button_view}`}
                            onClick={() => navigate('/')}
                        >
                            <SvgWorker fill={charts ? '#0044b4' : '#fff'} />
                        </button>
                        <button
                            className={`${styles.right_view_button}  ${charts && styles.active_button_view}`}
                            onClick={() => navigate('/charts')}
                        >
                            <SvgAnalytics fill={charts ? '#fff' : '#0044b4'} />
                        </button>
                    </div>

                    {hasFeatureAccess('CREATE_PROJECT') && (
                        <Button
                            onClick={() => dispatch(toggleAddProject())}
                            variant='outlined'
                            sx={{
                                padding: '5px 10px',
                                borderRadius: '6px',
                                backgroundColor: '#dce5f1',
                                letterSpacing: 0.1,
                                '&:hover': {
                                    backgroundColor: '#d4e1f3',
                                },
                            }}
                            startIcon={
                                <AddIcon
                                    sx={{
                                        width: '1.75rem',
                                        height: '1.75rem',
                                    }}
                                />
                            }
                        >
                            добавить
                        </Button>
                    )}
                </div>
            </section>

            {renderList()}
        </main>
    );
}
