import AddIcon from '@mui/icons-material/Add'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { EditableCallbackParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { Dispatch, SetStateAction } from 'react'
import { Location } from 'react-router-dom'
import { Work } from '../../api/ksg/ksg.def'
import { KsgEquipI } from '../../api/ksgEquip/ksgEquip.def'
import { PublicUserProfile } from '../../api/users/users.def'
import { WorkManagmentRecourceCell } from '../../components/WorkManagmentRecourceCell/WorkManagmentRecourceCell'
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '../../shared/rolePermissions'
import { toggleAddLevelKsgEquip } from '../../store/slices/drawersSlice'
import { AppDispatch } from '../../store/store'
import RowMonthHeaderRenderer from '../AgGrid/components/RowMonthHeaderRenderer'
import { getHeadersMonthsPeq } from '../AgGridKsgPeople/AgGridKsgPeople.service'
import { HeaderFilterPeqEl } from '../AgGridKsgPeople/components/HeaderFilterPeople'
import MonthRendererKsgAg from '../AgGridKsgPeople/components/MonthRendererKsgAg/MonthRendererKsgAg'
import { applyStylesPercent, hasParam } from '../KsgTable/Ksg.service'
import { COL_DEF_TEMPLATE } from '../WorkManagment/components/AgGridService/AgGridColumnDef.service'
import { NumberCellRenderer } from '../WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer'

interface IGetKsgMimColDefsArgs {
    profile: PublicUserProfile
    dispatch: AppDispatch
    setWarningModal: Dispatch<
        SetStateAction<{
            openState: boolean
            deleteId: number
        }>
    >
    deleteEquipRes: any
    setOpenModal: Dispatch<SetStateAction<boolean>>
    warningModal: {
        openState: boolean
        deleteId: number
    }
    equipKsgData: any
    location: Location
    headers: any
}

export const getKsgMimColDefs = ({
    profile,
    dispatch,
    setWarningModal,
    deleteEquipRes,
    setOpenModal,
    warningModal,
    equipKsgData,
    location,
    headers,
}: IGetKsgMimColDefsArgs) => [
    {
        field: '',
        minWidth: 100,
        maxWidth: 100,
        headerName: '',
        spanHeaderHeight: true,
        headerComponent: () => (
            <Box mx="auto">
                {DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_MIM_ROW') && (
                    <IconButton
                        onClick={() => {
                            dispatch(toggleAddLevelKsgEquip())
                        }}
                        color="primary"
                        component="label"
                    >
                        <AddIcon />
                    </IconButton>
                )}
            </Box>
        ),
        cellRenderer: (data: ICellRendererParams<KsgEquipI, any, any>) => {
            if (
                !data.data ||
                !DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_KSG_MIM_ROW') ||
                data.data?.isResource
            )
                return <></>
            return (
                <IconButton
                    onClick={() => {
                        setWarningModal({ deleteId: data.data?.id || 0, openState: true })
                    }}
                    color="primary"
                    component="label"
                >
                    {deleteEquipRes.isLoading && data.data.id === warningModal.deleteId ? (
                        <CircularProgress size={32} color="success" disableShrink />
                    ) : (
                        <DeleteForeverOutlinedIcon />
                    )}
                </IconButton>
            )
        },
    },
    {
        field: 'name',
        colId: 'name',
        flex: 2,
        minWidth: 250,
        headerName: 'Наименование',
        spanHeaderHeight: true,
        headerComponent: (data: IHeaderParams<Work, any>) => (
            <HeaderFilterPeqEl
                onFilterClick={() => {
                    setOpenModal(true)
                }}
                width={data.column.getActualWidth()}
                name={data.displayName}
            />
        ),
        editable: (params: EditableCallbackParams<KsgEquipI>) =>
            params.data?.id !== 4353246543 && !params.data?.isResource,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA(),
        ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('name'),
        cellRenderer: (params: ICellRendererParams) => {
            if (params.data?.isResource) {
                return <WorkManagmentRecourceCell>{params.data?.name}</WorkManagmentRecourceCell>
            }
            if (params.data?.id === 4353246543) {
                return 'Основной персонал'
            } else {
                return COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA().cellRenderer(params)
            }
        },
    },
    {
        field: 'category',
        colId: 'category',
        flex: 2,
        minWidth: 250,
        headerName: 'Категория',
        spanHeaderHeight: true,
        editable: (params: EditableCallbackParams<KsgEquipI>) =>
            params.data?.id !== 4353246543 && !params.data?.isResource,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA(),
        ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('category'),
        cellRenderer: (params: ICellRendererParams) => {
            if (params.data?.id === 4353246543) {
                return ''
            } else {
                return COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA().cellRenderer(params)
            }
        },
    },
    {
        field: 'avgCount',
        colId: 'avgCount',
        width: 180,
        headerName: 'Количество в месяц',
        spanHeaderHeight: true,
        cellRenderer: NumberCellRenderer,
    },
    {
        field: 'avgCount90',
        colId: 'avgCount90',
        width: 180,
        headerName: 'Общая средняя потребность, 90 дней',
        spanHeaderHeight: true,
        cellRenderer: NumberCellRenderer,
    },
    {
        headerName: 'Текущая потребность',
        colId: 'current',
        children: [
            {
                field: 'currentPlan',
                colId: 'currentPlan',
                width: 150,
                headerName: 'План',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'currentFact',
                colId: 'currentFact',
                width: 150,
                headerName: 'Факт',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'currentPercent',
                colId: 'currentPercent',
                width: 150,
                headerName: 'Обеспеченность',
                cellRenderer: (params: ICellRendererParams) => (
                    <NumberCellRenderer
                        {...params}
                        NumericFormatProps={{
                            suffix: ' %',
                        }}
                        TypographyProps={{
                            sx: applyStylesPercent(params?.value || 0),
                        }}
                    />
                ),
            },
        ],
    },
    {
        headerName: 'Общее количество нарастающим итогом',
        colId: 'total',
        children: [
            {
                field: 'totalPlan',
                colId: 'totalPlan',
                width: 150,
                headerName: 'План',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'totalFact',
                colId: 'totalFact',
                width: 150,
                headerName: 'Факт',
                cellRenderer: NumberCellRenderer,
            },
            {
                field: 'totalPercent',
                colId: 'totalPercent',
                width: 150,
                headerName: 'Соответствие',
                cellRenderer: (params: ICellRendererParams) => (
                    <NumberCellRenderer
                        {...params}
                        NumericFormatProps={{
                            suffix: ' %',
                        }}
                        TypographyProps={{
                            sx: applyStylesPercent(params?.value || 0),
                        }}
                    />
                ),
            },
        ],
    },
    {
        field: 'verifiedFactTotal',
        colId: 'verifiedFactTotal',
        width: 180,
        spanHeaderHeight: true,
        headerName: 'Вер. факт нарастающим итогом',
        headerClass: 'ag-verFact',
        cellRenderer: NumberCellRenderer,
    },
    {
        field: '',
        width: 120,
        headerName: 'План/Факт',
        spanHeaderHeight: true,
        cellRenderer: (params: ICellRendererParams<KsgEquipI, any, any>) => (
            <RowMonthHeaderRenderer
                isGant={hasParam('g', location.search) || hasParam('gf', location.search)}
                hasChildren={false}
                cellRendererParams={params}
                isResourceTable
            />
        ),
    },

    ...getHeadersMonthsPeq(equipKsgData.data?.data[0]?.monthlyCharts, (cellRendererParams, chartIndex) => {
        if (!cellRendererParams.data || !cellRendererParams.data?.monthlyCharts[chartIndex]) return <></>
        return (
            <MonthRendererKsgAg
                cellRendererParams={cellRendererParams}
                chartIndex={chartIndex}
                resourceType={'mim'}
                headers={headers?.data}
            />
        )
    }),
]
