import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import { MenuItem, Select, Typography } from '@mui/material';
import { CgDanger } from '@react-icons/all-files/cg/CgDanger';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';

import { useDownloadExcelMutation } from '@/api/ksg/ksg.api';
import { useUpdateKSGMutation } from '@/api/works/works.api';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import placeImg from '@/pages/Projects/component/Headline.png';
import { StyledBtn } from '@/pages/WorkManagment/components/components.styles';

import ErrorsList from '@/shared/components/ErrorsList/ErrorsList';
import { IUploadDrawerStatus } from '@/shared/def';
import useProjectUploadProgress from '@/shared/hooks/useProjectUploadProgress';
import { formDownloadLink } from '@/shared/utils';

import { onKSGUpload } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { drawersSelector, toggleUpdateKSG } from '@/store/slices/drawersSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { theme } from '@/styles/theme';

import {
    ICheckboxSettings,
    initialsParams,
    updateNames1S,
    updateNamesBase,
    uploadSelectStyles,
} from '../UploadDrawer/UploadDrawer.service';
import CheckParams from '../UploadDrawer/components/CheckParams';
import UploadAgain from '../UploadDrawer/components/UploadAgain';
import UploadButtons from '../UploadDrawer/components/UploadButtons';
import { DrawerTitle } from './UpdateKSGDrawer.styles';

export function UpdateKSGDrawer() {
    const [showUploadAgain, setShowUploadAgain] = useState<boolean>(false);
    const [showErrorsList, setShowErrorsList] = useState<boolean>(false);
    const [uploadVariant, setUploadVariant] = useState<'standart' | 'most'>('standart');

    const { t: tmut } = useTranslation('mutations');
    const { t: tfiles } = useTranslation('files');
    const { enqueueSnackbar } = useSnackbar();
    const { projectId } = useParams();
    const location = useLocation();
    const { activeTask, setTriggerUpdateStatus, result } = useProjectUploadProgress({
        supressOnClose: true,
        supressWatchBtn: true,
    });
    const { updateKSG } = useTypedSelector(drawersSelector);
    const [uploadStatus, setUploadStatus] = useState<IUploadDrawerStatus>('readyForDrop');

    /**
     * список активных имен чекбоксов для отправки на сервер
     */
    const actualNames = useMemo(() => {
        return uploadVariant === 'most' ? updateNames1S : updateNamesBase;
    }, [uploadVariant]);

    /**
     * Состояние чекбоксов
     */
    const [checks, setChecks] = useState(initialsParams);

    /**
     * Функция изменения состояния чекбоксов
     * @param name - имя свойства объекта
     */
    const changeParams = (param: ICheckboxSettings) => {
        setChecks((prevState) => ({
            ...prevState,
            [param.name]: !prevState[param.name],
            ...(param.contradactoryField && {
                [param.contradactoryField]: false,
            }),
        }));
    };

    const dispatch = useAppDispatch();
    const [updateKSGReq, updateKSGRes] = useUpdateKSGMutation();

    const [exReq] = useDownloadExcelMutation();

    const locationSplit = location.pathname.split('/');

    // useEffect(() => {
    //     !showUploadAgain && updateKSGRes.reset()
    // }, [showUploadAgain])

    function onDropRejected() {
        enqueueSnackbar(tfiles('upload.wrong_extension'), {
            variant: 'error',
        });
    }

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            acceptedFiles.forEach((file: File) => {
                const formData = new FormData();
                setUploadStatus('loading');
                formData.append('file', file);
                /* Выбираются только нужные свойства из объекта */
                let check: keyof typeof checks;
                for (check in checks) {
                    // if (actualNames.includes(check)) {
                    formData.append(check, String(checks[check]));
                    // }
                }
                updateKSGReq({
                    id: Number(projectId),
                    body: formData,
                    variant: uploadVariant,
                })
                    .unwrap()
                    .then((data) => {
                        setTriggerUpdateStatus(true);
                        // if (data.error == null) {
                        //     dispatch(onKSGUpdate())
                        // }
                        if (data.error?.length > 0) {
                            setShowUploadAgain(true);
                        }
                    })
                    .catch((e) => {
                        enqueueSnackbar('Ошибка', {
                            variant: 'error',
                        });
                        setUploadStatus('error');
                    });
            });
        },
        [projectId, uploadVariant, checks]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
    });

    function downloadProject() {
        exReq({ id: Number(projectId) })
            .unwrap()
            .then((res) => {
                formDownloadLink(res, locationSplit, projectId);
                enqueueSnackbar(tmut('download_ksg'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка', {
                    variant: 'error',
                });
            });
    }

    function disabledButton() {
        return uploadStatus === 'readyForDrop' || uploadStatus === 'loading';
    }

    /**
     * Эффект следит за изменение селекта
     * После смен очищается состояние чекбоксов
     */
    useEffect(() => {
        setChecks(initialsParams);
    }, [uploadVariant]);

    useEffect(() => {
        updateKSG && setTriggerUpdateStatus(true);
    }, [updateKSG]);

    useEffect(() => {
        activeTask?.result?.error && setShowUploadAgain(true);
    }, [activeTask?.result?.error]);

    useEffect(() => {
        result?.error && setUploadStatus('error');
        result?.success && setUploadStatus('success');
    }, [result]);

    useEffect(() => {
        uploadStatus === 'success' && dispatch(onKSGUpload());
    }, [dispatch, uploadStatus]);

    if (showErrorsList) {
        return (
            <ErrorsList
                setShowErrorsList={setShowErrorsList}
                errors={result?.error}
                title='Информация об ошибках'
            />
        );
    }

    let loaderContent = <></>;

    switch (uploadStatus) {
        case 'readyForDrop':
            loaderContent = (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img
                        style={{ objectFit: 'cover' }}
                        src={placeImg}
                        alt=''
                    />
                </div>
            );
            break;
        case 'loading':
            loaderContent = <FadeLoader />;
            break;
        case 'success':
            loaderContent = (
                <FlexColumnWrapper
                    alignItems={'center'}
                    gap={1}
                >
                    <CheckCircleIcon
                        fontSize='large'
                        sx={{
                            color: '#22c55e',
                        }}
                    />
                    <Typography variant='body2'>Успешно обработано!</Typography>
                    <Typography variant='body2'>Всего обновлено строк: {result?.update}</Typography>
                    <Typography variant='body2'>Всего добавлено строк: {result?.create}</Typography>
                </FlexColumnWrapper>
            );
            break;
        case 'error':
            loaderContent = (
                <UploadAgain
                    length={result?.error?.length}
                    setShowUploadAgain={setShowUploadAgain}
                    setShowErrorsList={setShowErrorsList}
                    onUploadAgain={() => setUploadStatus('readyForDrop')}
                />
            );
            break;
    }

    return (
        <FlexColumnWrapper
            width={400}
            alignItems={'center'}
            p={0}
        >
            <DrawerTitle>Обновить КСГ</DrawerTitle>
            <FlexColumnWrapper paddingX={2.5}>
                <Typography
                    variant='body2'
                    textAlign={'center'}
                    fontWeight={500}
                >
                    Вы можете обновить проект с помощью exсel документа с уже подготовленными данными.
                </Typography>
                <FlexRowWrapper justifyContent={'space-between'}>
                    <FlexRowWrapper gap={1}>
                        <CgDanger
                            color={theme.palette.secondary.dark}
                            fontSize={16}
                        />
                        <Typography
                            variant='subtitle2'
                            color={theme.palette.secondary.dark}
                            fontWeight={400}
                        >
                            Тип шаблона
                        </Typography>
                    </FlexRowWrapper>
                    <Select
                        size='small'
                        value={uploadVariant}
                        sx={uploadSelectStyles}
                        onChange={(e) => setUploadVariant(() => e.target.value as typeof uploadVariant)}
                    >
                        <MenuItem value='standart'>Стандартный шаблон</MenuItem>
                        <MenuItem value='most'>1С шаблон</MenuItem>
                    </Select>
                </FlexRowWrapper>
                <FlexColumnWrapper
                    width={'100%'}
                    minHeight={200}
                    maxHeight={200}
                    bgcolor={theme.palette.bg.shades}
                    borderRadius={2}
                    alignItems={'center'}
                    justifyContent={'center'}
                    overflow={'hidden'}
                    sx={{
                        cursor: showUploadAgain ? 'default' : 'pointer',
                    }}
                >
                    {loaderContent}
                    {/* {showUploadAgain && (
                        <UploadAgain
                            length={updateKSGRes.data?.error.length}
                            setShowUploadAgain={setShowUploadAgain}
                            setShowErrorsList={setShowErrorsList}
                        />
                    )}
                    {updateKSGRes.isLoading && <FadeLoader />}
                    {updateKSGRes.isSuccess && !updateKSGRes.data?.error && (
                        <FlexColumnWrapper alignItems={'center'} gap={1}>
                            <CheckCircleIcon
                                fontSize="large"
                                sx={{
                                    color: '#22c55e',
                                }}
                            />
                            <Typography variant="body2">Успешно обработано!</Typography>
                            <Typography variant="body2">Всего обновлено строк: {updateKSGRes.data.update}</Typography>
                            <Typography variant="body2">Всего добавлено строк: {updateKSGRes.data.create}</Typography>
                        </FlexColumnWrapper>
                    )}
                    {!showUploadAgain && !updateKSGRes.isLoading && !updateKSGRes.isSuccess && (
                        <Box {...getRootProps()}>
                            <input {...getInputProps()} />
                            <img src={placeImg} alt="" />
                        </Box>
                    )} */}
                </FlexColumnWrapper>
                <CheckParams
                    activeType={uploadVariant === 'most' ? '1C' : 'Standart'}
                    checkFn={changeParams}
                    mode={'update'}
                    state={checks}
                    disabled={uploadStatus === 'loading'}
                />
                <StyledBtn
                    startIcon={<DownloadIcon />}
                    onClick={downloadProject}
                >
                    Скачать КСГ
                </StyledBtn>
                <UploadButtons
                    disableButtons={disabledButton()}
                    isLoading={uploadStatus === 'loading'}
                    onClose={() => dispatch(toggleUpdateKSG())}
                />
            </FlexColumnWrapper>
        </FlexColumnWrapper>
    );
}
