import { Modal } from '@mui/material';
// import { useWhatChanged } from '@simbathesailor/use-what-changed';
import {
    BodyScrollEndEvent,
    ColumnResizedEvent,
    ColumnState,
    GetRowIdParams,
    RowClassRules,
    RowHeightParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import { Suspense, lazy, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { queryTermsType } from '@/api/filters/filters.api';
import { useGetHeadersCPGQuery, useSetTagMutation } from '@/api/ksg/ksg.api';
import { Work, WorkOrWorkPosition } from '@/api/ksg/ksg.def';
import { useDeleteWorkMutation } from '@/api/works/works.api';

import { TTemplateColumn } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.def';
import {
    useGetCurrentTemplateColumnWidths,
    useUpdateCurrentTemplate,
} from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.model';
import { getFilteredColumnsState } from '@/components/ConfigureAgGridColDefTemplate/ConfigureAgGridColDefTemplate.service';
import { useOpenDeleteZeroValueFactDialog } from '@/components/DeleteZeroValueFactDialog';
import GanttRelations from '@/components/GanttRelations/GanttRelations';

import { useToggleSearchParam } from '@/hooks/useToggleSearchParam';
import { useVerticalPagination } from '@/hooks/useVerticalPagination';

import { KSGColDefs } from '@/pages/AgGrid/AgGrid.colDef';
import { submitCellData, transformWorkPositionToWork } from '@/pages/AgGrid/AgGrid.service';
import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { AG_GRID_DEFAULT_PARAMS_KSG } from '@/pages/WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { LoadingOverlay } from '@/pages/WorkManagment/components/AgGridService/components/LoadingOverlay';

import { HEADER_ROW_HEIGHT } from '@/shared/constants/ag-grid';
import { IntegrationWithOfferSearchParam } from '@/shared/constants/integration-with-offer';
import useProjectUploadProgress from '@/shared/hooks/useProjectUploadProgress';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';

import {
    agGridKsgSelector,
    agGridListMode,
    onCheckTagKsgAg,
    onDeleteRowKsgAg,
} from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { IGetParamsKSG, getWorksAgGrid, getWorksAgGridOrphan } from '@/store/slices/agGridKsgMsgSlices/ksgThunks';
import { authSelector } from '@/store/slices/authSlice';
import { drawersSelector, setAgGrid, toggleUploadProgress } from '@/store/slices/drawersSlice';
import { TSelectedColumn, filtersSelector, setQueryTerm, setSelectedColumn } from '@/store/slices/filtersSlice';
import { integrationStatusSelector } from '@/store/slices/integrationStatusSlice';
import { profileSelector } from '@/store/slices/profileSlice';
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { TAgGridContext } from './DHTGant.def';
import { useGetAppliedFilters, useGetMinMaxLevels } from './DHTGantWithAgGrid.model';
import './DHTGanttWithAgGrid.scss';
import { KsgAddLevelDialog } from './components/KSGAddLevelDialog';
import { KSGPlaceholder } from './components/KSGPlaceholder';
import { FilterDialogs } from './components/KsgFilterDialogs';
import { useCollectionOfFactOfAct } from './model/useCollectionOfFactOfAct';

const GanttTable = lazy(() => import('./components/GanttTable').then((c) => ({ default: c.GanttTable })));

/* константа лимита для запросов к базе */
export const LIMIT = 1000;

export default function DHTMLXGanttWithAgGrid() {
    const [hiddenRowsIds, setHiddenRowsIds] = useImmer<
        {
            id: number;
            code: string;
        }[]
    >([]);
    const [mutationsLoading, setMutationsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isOpenAddLevel, setIsOpenAddLevel] = useState(false);
    const [addLevelData, setAddLevelData] = useState<{
        id: number | null;
        index: number;
        level: number | null;
    }>({
        id: 0,
        index: 0,
        level: null,
    });
    const [relationsOpened, setRelationsOpened] = useState<boolean>(false);
    const [relationData, setRelationData] = useState<null | {
        workName: string;
        startDate: string | null;
        endDate: string | null;
        id: number;
    }>(null);
    const [rowHeights, setRowHeights] = useState<{
        [key: number]: number;
    }>({});
    const openDeleteZeroValueFactDialog = useOpenDeleteZeroValueFactDialog();

    // const { AgGrid } = useTypedSelector(drawersSelector)
    const { filters } = useTypedSelector(filtersSelector);
    const {
        worksList,
        isKSGUpdatedByExcel,
        isKSGUploaded,
        rowHeight: globalRowHeight,
        minRowHeight,
        isOfferResynchronized,
    } = useTypedSelector(agGridKsgSelector);
    const { token } = useTypedSelector(authSelector);
    const { profile } = useTypedSelector(profileSelector);
    const navigate = useNavigate();
    const listMode = useTypedSelector(agGridListMode);
    const location = useLocation();

    /* Данные с стора для базового плана */
    const { basicPlan, costDoneMode } = useTypedSelector(getSettings);

    const { syncModuleData, triggerIfBackupRestored, uploadKsg, updateKSG, AgGrid } = useTypedSelector(drawersSelector);

    const dispatch = useAppDispatch();

    const gridRef = useRef<AgGridReact>(null);
    const rowHeightsRef = useRef<{
        [key: number]: number;
    }>({});
    const columnState = useRef<any[]>([]);

    // TODO:
    // Проверить как будет работать useEffect
    if (gridRef?.current) {
        dispatch(setAgGrid(gridRef?.current));
    }

    const { projectId } = useParams();
    const { isActive } = useToggleSearchParam(IntegrationWithOfferSearchParam);
    const integrationStatus = useTypedSelector(integrationStatusSelector);
    const isOfferActive = integrationStatus?.isImportFromOffer && isActive;

    const [urlSearchParams] = useSearchParams();

    const { update: updateCurrentTemplate } = useUpdateCurrentTemplate();
    const { columnWidths } = useGetCurrentTemplateColumnWidths();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('mutations');
    const { t: tCPG } = useTranslation('cpg');

    const [indexClick, setIndexClick] = useImmer<{
        month: number;
        work: number;
    } | null>(null);

    const [anchorElTemplateTable, setAnchorElTemplateTable] = useState<null | HTMLElement>(null);

    const [deleteReq] = useDeleteWorkMutation();
    const [setTagReq] = useSetTagMutation();

    const { refetch: refetchCollectionOfFactOfAct } = useCollectionOfFactOfAct();

    const [isLoading, setIsLoading] = useState(true);

    const headersCPG = useGetHeadersCPGQuery({
        id: Number(projectId),
    });

    const { minLevelByListMode } = useGetMinMaxLevels(worksList?.data, listMode);

    useLayoutEffect(() => {
        if (!worksList) return;
        if (!minLevelByListMode) {
            setHiddenRowsIds([]);
            return;
        }

        setHiddenRowsIds(
            worksList.data
                .filter((v) => v.level === minLevelByListMode)
                .map((v) => ({
                    id: v.id,
                    code: v.code,
                }))
        );
    }, [worksList, minLevelByListMode]);

    const isVisibleGantt = gridRef.current && urlSearchParams.has('g');

    const isKSGCreated = Boolean(headersCPG?.data?.isCreatedKSG);

    const { activeTask, setTriggerUpdateStatus } = useProjectUploadProgress({
        supressOnClose: !isKSGCreated,
        supressWatchBtn: !isKSGCreated,
    });

    useEffect(() => {
        if (!gridRef.current) return;
        dispatch(setAgGrid(gridRef?.current));
    });

    useEffect(() => {
        !activeTask && setTriggerUpdateStatus(true);
    }, [activeTask, headersCPG?.data]);

    useEffect(() => {
        setTriggerUpdateStatus(!uploadKsg);
    }, [uploadKsg]);

    useEffect(() => {
        setTriggerUpdateStatus(!updateKSG);
    }, [updateKSG]);

    useEffect(() => {
        return () => {
            setTriggerUpdateStatus(false);
            dispatch(
                toggleUploadProgress({
                    openDialog: false,
                    supressOnClose: false,
                    supressWatchBtn: false,
                    activeTask: undefined,
                })
            );
            dispatch(setAgGrid(null));
        };
    }, [location.pathname, projectId]);

    useEffect(() => {
        mutationsLoading && console.info('loading');
    }, [mutationsLoading]);

    /* возвращает сразу массив данных учитывая фильтры либо null */
    const [filteredWorks, setFilterWorks] = useState<Work[] | null>([]);

    const rowData = useMemo(() => {
        if (!isOfferActive || listMode) return filteredWorks;

        return filteredWorks?.flatMap((item) => {
            if (!item.workPosition) return item;

            if (
                !hiddenRowsIds.some((v) =>
                    isEqual(v, {
                        id: item.id,
                        code: item.code,
                    })
                )
            ) {
                return item;
            }

            return [item, ...item?.workPosition?.map((position) => transformWorkPositionToWork(item, position))];
        });
    }, [filteredWorks, listMode, isOfferActive]) as WorkOrWorkPosition[];

    useLayoutEffect(() => {
        setFilterWorks(() => {
            if (worksList) {
                const filtArr = worksList?.data.reduce((acc: Work[], prev) => {
                    const idx = hiddenRowsIds.every((filItem) => {
                        if (prev.code === filItem.code) {
                            return true;
                        }

                        return !filItem.code.split('-').every((v) => prev.code.split('-').includes(v));
                    });

                    return idx ? [...acc, prev] : acc;
                }, []);

                return filtArr?.length ? filtArr : worksList.data;
            } else {
                return null;
            }
        });
        return () => {
            setFilterWorks(null);
        };
    }, [hiddenRowsIds, urlSearchParams, worksList]);

    // PAGINATION
    /**
     * устанавливается нужный экшен по условию, для диспатча события внутри useVerticalPagination
     */
    const getData = useCallback(
        (params: IGetParamsKSG) => {
            const sendingFunc = listMode ? getWorksAgGridOrphan : getWorksAgGrid;
            gridRef.current?.api?.showLoadingOverlay();
            return sendingFunc(params);
        },
        [listMode]
    );

    /**
     * общее количество записей в БД
     */
    const [totalRows, setTotalRows] = useState(0);

    /**
     * Эффект следит за изменением projectId
     * и используя Api AgGrid управляет оверлэем таблицы
     * если workList не null отрисовывает таблицу,
     * в ином случае показывает что идет загрузка
     */
    useEffect(() => {
        if (worksList) {
            setTotalRows(worksList.total);
            // worksList?.activeUpload && setTriggerUpdateStatus(true)
        }
    }, [worksList]);

    /**
     *  добавил в массив resetToInitialDeps зависимость от projectId
     *  чтобы при смене проекта был перезапрос за данными
     */

    const scrollParams = JSON.parse(localStorage.getItem('scrollParams')!);
    const lastRenderedRow = scrollParams && scrollParams[location.pathname]?.lastRenderedRow;

    const { scrollHandlerFoAG } = useVerticalPagination<IGetParamsKSG>({
        initialParams: {
            limit: lastRenderedRow ? Math.ceil(lastRenderedRow / LIMIT) * LIMIT : LIMIT,
            offset: 0,
            id: Number(projectId),
        },
        sendFn: getData,
        thenFn: () => {
            gridRef.current?.api?.hideOverlay();
            setIsLoading(false);
        },
        catchFn: () => {
            gridRef.current?.api?.hideOverlay();
            setIsLoading(false);
        },
        totalCount: totalRows,
        requiredDeps: [projectId, totalRows !== null, token],
        resetToInitialDeps: [
            isOfferResynchronized,
            filters,
            listMode,
            projectId,
            isKSGUpdatedByExcel,
            isKSGUploaded,
            syncModuleData,
            triggerIfBackupRestored,
        ],
    });

    const onBodyScrollEnd = (e: BodyScrollEndEvent<any, any>) => {
        if (worksList?.total === worksList?.data?.length) return;
        scrollHandlerFoAG(e);
    };

    //==========================

    const onDeleteRow = useCallback(
        (id: number) => {
            deleteReq({
                id: Number(projectId),
                workId: id,
            })
                .unwrap()
                .then((res) => {
                    enqueueSnackbar(t('work_deleted'), {
                        variant: 'success',
                    });

                    dispatch(
                        onDeleteRowKsgAg({
                            id,
                            works: res.data,
                        })
                    );
                    gridRef.current && gridRef.current?.api.applyTransaction({ update: res.data });
                })
                .catch((e) => {
                    enqueueSnackbar('Ошибка', {
                        variant: 'error',
                    });
                });
        },
        [projectId]
    );

    const checkTag = useCallback((bool: boolean, workId: number | undefined) => {
        dispatch(
            onCheckTagKsgAg({
                bool,
                workId,
            })
        );

        if (!workId) return;

        setTagReq({
            id: Number(projectId),
            workId: workId,
            body: {
                tag: bool,
            },
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(t('tag_set'), {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar('Ошибка, для дополнительной информации откройте консоль', {
                    variant: 'error',
                });
                console.error(e);
            });
    }, []);

    const getRowId = useCallback((params: GetRowIdParams) => {
        return params?.data?.id;
    }, []);

    // TODO:
    // 1. Добавить также в МСГ
    const rowClassRules = useMemo<RowClassRules<Work>>(() => {
        return {
            level_zero: 'data.level === 0',
            level_one: 'data.level === 1',
            level_two: 'data.level === 2',
            level_three: 'data.level === 3',
            level_four: 'data.level >= 4 && data.level < 16',
            level_sixteen: 'data.level === 16 || !data.hasChildren',
        };
    }, []);

    //=== RESIZE ROW =======//

    const getRowHeight = (params: RowHeightParams<any, any>): number => {
        if (params.node.rowIndex === null) return globalRowHeight;
        return rowHeightsRef.current[params.node.rowIndex];
    };

    const handleHeightChange = useCallback((rowIndex: number, deltaY: number) => {
        const newHeight = (rowHeightsRef.current[rowIndex] || globalRowHeight) + deltaY;
        rowHeightsRef.current = {
            ...rowHeightsRef.current,
            [rowIndex]: Math.max(newHeight, minRowHeight),
        };
        setRowHeights((prevHeights) => {
            return {
                ...prevHeights,
                [rowIndex]: Math.max(newHeight, minRowHeight),
            };
        });
        if (gridRef.current?.api) {
            gridRef.current.api.resetRowHeights();
        }
    }, []);

    const handleColumnResized = (event: ColumnResizedEvent) => {
        if (event.source !== 'api' && event.source !== 'uiColumnDragged') {
            return;
        }
        if (event.finished && gridRef.current) {
            const columnApi = event.columnApi;
            const newState = columnApi.getColumnState();

            columnState.current = newState;
            updateCurrentTemplate({
                columns: getFilteredColumnsState(gridRef.current).map((columnState) => ({
                    name: (columnState as ColumnState).colId,
                    hide: (columnState as ColumnState).hide,
                    pinned: (columnState as ColumnState).pinned,
                    width: (columnState as ColumnState).width,
                })) as TTemplateColumn[],
            });
        }
    };

    const getSubmitCellDataThenFn = (prop: string | undefined) => {
        const map: Record<string, () => void> = {
            toClose: () => refetchCollectionOfFactOfAct(),
            default: () => {},
        };

        if (!prop) return map.default;
        return map[prop] ?? map.default;
    };

    const appliedFilters = useGetAppliedFilters();

    const openFilterModal = (column: TSelectedColumn, queryTerm?: queryTermsType) => {
        setOpenModal(true);
        dispatch(setSelectedColumn(column));

        if (queryTerm) {
            dispatch(setQueryTerm(queryTerm));
        }
    };

    const context = useMemo(() => {
        return {
            projectId: projectId!,
            appliedFilters,
            openFilterModal,
            openDeleteZeroValueFactDialog,
        } as TAgGridContext;
    }, [appliedFilters, projectId, openDeleteZeroValueFactDialog]);

    const closeFilterModal = () => {
        setOpenModal(false);
        dispatch(setSelectedColumn(null));
    };

    useEffect(() => {
        if (columnState.current.length && gridRef.current) {
            gridRef.current.columnApi.applyColumnState({
                state: columnState.current,
                applyOrder: true,
            });
        }
    }, [gridRef.current, columnState.current]);

    useEffect(() => {
        rowHeightsRef.current = {};
        if (gridRef.current?.api) {
            gridRef.current.api.resetRowHeights();
        }
    }, [globalRowHeight]);

    //======================//

    const [changeableArray, setChangeableArray] = useImmer<
        | {
              month: number;
              year: number;
              fill: boolean;
              fact: number | null;
          }[]
        | undefined
    >([]);

    useEffect(() => {
        headersCPG.refetch();
    }, [isKSGUploaded]);

    const columnDefs = useMemo(
        () =>
            KSGColDefs({
                rowData: rowData,
                setAddLevelData: setAddLevelData,
                setIsOpenAddLevel: setIsOpenAddLevel,
                location: location,
                profile: profile,
                onDeleteRow: onDeleteRow,
                checkTag: checkTag,
                hiddenRowsIds: hiddenRowsIds,
                setHiddenRowsIds: setHiddenRowsIds,
                gridRef: gridRef,
                changeableArray: changeableArray,
                setChangeableArray: setChangeableArray,
                setRelationsOpened: setRelationsOpened,
                setRelationData: setRelationData,
                urlSearchParams: urlSearchParams,
                anchorElTemplateTable: anchorElTemplateTable,
                setAnchorElTemplateTable: setAnchorElTemplateTable,
                indexClick: indexClick,
                setIndexClick: setIndexClick,
                dispatch: dispatch,
                headersCPG: headersCPG,
                basicPlan: basicPlan,
                costDoneMode: costDoneMode,
                setMutationsLoading: setMutationsLoading,
                setOpenModal: setOpenModal,
                isOfferActive: isOfferActive,
                integrationStatus: integrationStatus,
                handleHeightChange: handleHeightChange,
                refetchCollectionOfFactOfAct: refetchCollectionOfFactOfAct,
                columnWidths: columnWidths,
            }),
        [
            rowData,
            setAddLevelData,
            setIsOpenAddLevel,
            location,
            profile,
            onDeleteRow,
            checkTag,
            hiddenRowsIds,
            setHiddenRowsIds,
            gridRef,
            changeableArray,
            setChangeableArray,
            setRelationsOpened,
            setRelationData,
            urlSearchParams,
            anchorElTemplateTable,
            setAnchorElTemplateTable,
            indexClick,
            setIndexClick,
            dispatch,
            headersCPG,
            basicPlan,
            costDoneMode,
            setMutationsLoading,
            setOpenModal,
            isOfferActive,
            integrationStatus,
            handleHeightChange,
            columnWidths,
        ]
    );
    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
        };
    }, []);

    // const handleFilterModalOpen = () => setOpenModal(true);

    if (!isKSGCreated) {
        return <KSGPlaceholder />;
    }

    return (
        <>
            <LoadingOverlay
                key='overlay'
                open={isLoading}
                transitionDuration={0}
                sx={{
                    zIndex: 999,
                }}
            />

            <KsgAddLevelDialog
                data={addLevelData}
                isOpen={isOpenAddLevel}
                grid={gridRef.current}
                onClose={() => setIsOpenAddLevel(false)}
            />

            <FilterDialogs
                projectId={projectId!}
                isOpen={openModal}
                onClose={closeFilterModal}
            />

            <Modal
                open={relationsOpened}
                onClose={() => {
                    setRelationsOpened(false);
                    setRelationData(null);
                }}
            >
                <span>
                    <GanttRelations
                        setRelationsOpened={setRelationsOpened}
                        increaseRelationsCount={() => {}}
                        initialWorkData={relationData!}
                    />
                </span>
            </Modal>

            <FlexColumnWrapper>
                <FlexRowWrapper
                    className='table-wrapper ksg'
                    style={
                        {
                            '--ag-body-vertical-scroll-display': `${isVisibleGantt ? 'none' : 'flex'}`,
                        } as React.CSSProperties
                    }
                    height={'100%'}
                    width={'100%'}
                    gap={0}
                    pb={2}
                >
                    <div
                        className='ag-theme-alpine'
                        style={{
                            flex: '1 1 0',
                            height: '100%',
                            paddingRight: '0.5rem',
                        }}
                        onClickCapture={(e) => {
                            if (!!activeTask) {
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            onBodyScrollEnd={onBodyScrollEnd}
                            getRowId={getRowId}
                            rowClassRules={rowClassRules}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            context={context}
                            {...AG_GRID_DEFAULT_PARAMS_KSG(location)}
                            onCellValueChanged={(params) => {
                                submitCellData({
                                    params: params,
                                    projectID: projectId as string,
                                    currentAct: integrationStatus?.currentAct ?? null,
                                    enqueueSnackbar: enqueueSnackbar,
                                    translate: tCPG,
                                    dispatch: dispatch,
                                    thenFn: getSubmitCellDataThenFn(params.colDef.field),
                                    openDeleteZeroValueFactDialog: openDeleteZeroValueFactDialog,
                                });
                            }}
                            onCellClicked={(params) => {
                                params.event?.preventDefault();
                                params.event?.stopImmediatePropagation();
                            }}
                            {...((!DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_KSG') || !!activeTask) && {
                                suppressClickEdit: true,
                            })}
                            rowHeight={globalRowHeight}
                            getRowHeight={getRowHeight}
                            defaultColDef={defaultColDef}
                            onColumnResized={handleColumnResized}
                            headerHeight={HEADER_ROW_HEIGHT}
                            groupHeaderHeight={HEADER_ROW_HEIGHT}
                            loadingOverlayComponentParams={{
                                loading: true,
                            }}
                            // TODO:
                            // 1. В дальнейшем нужно добавить. Проблема что при включении, в cellEditor с date нельзя открыть календарь
                            // stopEditingWhenCellsLoseFocus
                        />
                    </div>
                    {isVisibleGantt && (
                        <Suspense fallback={null}>
                            <GanttTable
                                filteredWorks={filteredWorks}
                                grid={gridRef.current}
                                rowHeights={rowHeights}
                                setRowHeights={setRowHeights}
                            />
                        </Suspense>
                    )}
                </FlexRowWrapper>
            </FlexColumnWrapper>
        </>
    );
}
