import {
    ColDef,
    ICellEditorParams,
    ICellRendererParams,
    IHeaderParams,
    SuppressKeyboardEventParams,
} from 'ag-grid-community'
import { COL_DEF_TEMPLATE } from '../../../../components/AgGridService/AgGridColumnDef.service'
import { IAutocompleteOption } from '../../../../components/CellEditors/CellEditor/CellEditor.def'
import { removeRow } from '../../ResourcePage.service'
import { IStaffResource } from '../../ResoursePage.types'

export interface IStaffColDefs extends ColDef {
    field: keyof IStaffResource | undefined
    cellRenderer?: (params: ICellRendererParams<IStaffResource, number | string, any>) => React.ReactNode
    cellEditor?: ((params: ICellEditorParams<IStaffResource, number | string, any>) => React.ReactNode) | string
}

function addNewRow(params: IHeaderParams) {
    params.api.applyTransaction({
        add: [
            {
                id: 'draft',
                nameProf: '',
                profId: undefined,
                plan: 0,
            },
        ],
    })
    setTimeout(() => {
        params.api.startEditingCell({
            rowIndex: params.api.getRowNode('draft')?.rowIndex as number,
            colKey: 'nameProf',
        })
        params.api.setFocusedCell(params.api.getRowNode('draft')?.rowIndex as number, 'nameProf')
        params.api.ensureNodeVisible(params.api.getRowNode('draft'))
    })
}

export const staffTableColumnDef: IStaffColDefs[] = [
    {
        ...COL_DEF_TEMPLATE.ADD_NEW_ROW_TABLE_COL(addNewRow),
    },
    {
        headerName: 'Специальность',
        field: 'nameProf',
        editable: true,
        headerClass: 'ag-cell-left',
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.AUTOCOMPLETE,
        cellClass: 'ag-cell-left ag-cell-editable ag-cell-px',
        cellEditorParams: (params: ICellEditorParams) => {
            const excludedNames: string[] = []
            params.api.forEachNode((row) => {
                excludedNames.push(row.data.nameProf)
            })
            const excludeDoublesOptions = params.context.proffList.filter(
                (proff: IAutocompleteOption) => !excludedNames.includes(proff.label)
            )
            return {
                options: excludeDoublesOptions,
                dataType: 'autocomplete',
                withNewOptionCreation: true,
                createFilterOptionsConfig: {
                    limit: 100,
                },
                validation: {
                    maxLength: {
                        value: 150,
                        message: 'Макс 150',
                    },
                },
            }
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
            if (document.getElementsByClassName('MuiAutocomplete-popper')[0]) {
                return true
            } else {
                return false
            }
        },
    },
    {
        headerName: 'План, ч.ч. (на ЕР)',
        field: 'plan',
        maxWidth: 150,
        minWidth: 150,
        editable: true,
        ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(),
    },
    {
        ...COL_DEF_TEMPLATE.DELETE_ROW_TABLE_COL((params) => removeRow(params)),
    },
]

export const newStaffRow: IStaffResource = {
    id: 0,
    nameProf: '',
    profId: 1,
    plan: 0,
}

function getNewStaffRow() {
    return {
        ...newStaffRow,
        // id: Math.random(),
    }
}
