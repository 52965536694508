import { CircularProgress, Tab, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FlexColumnWrapper, FlexRowWrapper } from '../../pages/NewExecutorView/components/components.styles';
import { StyledButton } from '../../pages/Users/components/Button/styles';
import { req } from '../../pages/WorkManagment/api/api';
import { TabsContained } from '../../pages/WorkManagment/components/components.styles';
import { setTriggerIfBackupRestored } from '../../store/slices/drawersSlice';
import { projectsSelector } from '../../store/slices/projectsSlice';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { StyledDrawer, StyledDrawerContent, StyledDrawerTitle } from '../StyledDrawer/StyledDrawer.styles';
import { IBackupDrawerProps, IProjectBackup, TControlBackupItemCallback, TProjectBackupType } from './BackupDrawer.def';
import { BackupItem } from './BackupItem';

const MANUAL_BACKUP_LIMIT_COUNT = 20;

export function BackupDrawer(props: IBackupDrawerProps) {
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { t: tErrors } = useTranslation('projectBackup');
    const dispatch = useAppDispatch();
    const [type, setType] = useState<TProjectBackupType>('auto');
    const [backups, setBackups] = useState<IProjectBackup[] | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const { pageName } = useTypedSelector(projectsSelector);

    useEffect(() => {
        pageName === 'ksg' && getBackups();
    }, [projectId]);

    function getBackups() {
        req.get(`/projects/${projectId}/backups/list`)
            .then(({ data }) => setBackups(() => data.data))
            .catch((e) => enqueueSnackbar(e?.response?.data || 'Ошибка', { variant: 'error' }));
    }

    function isLimitExceeded() {
        return backups
            ? backups?.filter((backup) => backup.type === 'manual').length >= MANUAL_BACKUP_LIMIT_COUNT
            : true;
    }

    function getExceedLimitSnack() {
        enqueueSnackbar(
            'Превышен лимит создания резервных копий, пожалуйста, удалите любое ручное сохранение для создания новой резервной копии проекта',
            { variant: 'error' }
        );
    }

    function errorHandler(e: any) {
        enqueueSnackbar(tErrors('errors.' + e?.response?.data) || 'Ошибка', { variant: 'error' });
    }

    function createBackup() {
        if (isLimitExceeded()) {
            getExceedLimitSnack();
            return;
        }
        setType((prevState) => 'manual');

        req.post(`/projects/${projectId}/backups/save`)
            .then(() => getBackups())
            .then(() => enqueueSnackbar('Резервная копия проекта сохранена', { variant: 'success' }))
            .then(() => setIsFetching(() => false))
            .catch(errorHandler);
    }

    const switchBackupType: TControlBackupItemCallback = (
        backupID: number,
        setIsConfirmDialogOpen: Dispatch<SetStateAction<boolean>>
    ) => {
        if (isLimitExceeded()) {
            getExceedLimitSnack();
            return;
        }
        req.post(`/projects/${projectId}/backups/${backupID}/update`, { type: 'manual' })
            .then(() => getBackups())
            .then(() => enqueueSnackbar('Автоматическая копия сохранена как ручная', { variant: 'success' }))
            .then(() => setIsFetching(() => false))
            .then(() => setType((prevState) => 'manual'))
            .then(() => setIsConfirmDialogOpen(() => false))
            .catch(errorHandler);
    };

    const restoreBackup: TControlBackupItemCallback = (
        backupID: number,
        setIsConfirmDialogOpen: Dispatch<SetStateAction<boolean>>
    ) => {
        setIsFetching(() => true);
        req.post(`/projects/${projectId}/backups/${backupID}/restore`)
            .then(() => dispatch(setTriggerIfBackupRestored()))
            .then(() => enqueueSnackbar('Резервная копия проекта применена', { variant: 'success' }))
            .then(() => setIsConfirmDialogOpen(() => false))
            .then(() => setIsFetching(() => false))
            .catch((e) => {
                setIsFetching(() => false);
                errorHandler(e);
            });
    };

    const deleteBackup: TControlBackupItemCallback = (
        backupID: number,
        setIsConfirmDialogOpen: Dispatch<SetStateAction<boolean>>
    ) => {
        req.delete(`/projects/${projectId}/backups/${backupID}/delete`)
            .then(() => getBackups())
            .then(() => enqueueSnackbar('Резервная копия проекта удалена', { variant: 'success' }))
            .then(() => setIsConfirmDialogOpen(() => false))
            .catch(errorHandler);
    };

    return (
        <StyledDrawer {...props}>
            <StyledDrawerTitle>Резервная копия проекта</StyledDrawerTitle>
            <StyledDrawerContent sx={{ width: '100%', overflow: 'hidden' }}>
                <TabsContained
                    value={type}
                    sx={{ width: '100%' }}
                    onChange={(e, value) => setType(() => value)}
                >
                    <Tab
                        value={'auto'}
                        sx={{ width: '50%' }}
                        label='Автосохранения'
                    ></Tab>
                    <Tab
                        value={'manual'}
                        sx={{ width: '50%' }}
                        label='Ручные сохранения'
                    ></Tab>
                </TabsContained>
                <FlexColumnWrapper
                    width={'100%'}
                    height={'100%'}
                    overflow={'auto'}
                >
                    {backups === null && <CircularProgress />}
                    {!backups?.length && <Typography>У Вас пока нет ни одного сохранения</Typography>}
                    {backups &&
                        backups
                            ?.filter((backup) => backup.type === type)
                            .map((backup, index) => {
                                return (
                                    <BackupItem
                                        key={backup.id}
                                        index={index}
                                        backup={backup}
                                        onRestore={restoreBackup}
                                        onDeleteBackup={deleteBackup}
                                        onSwitchType={switchBackupType}
                                        isFetching={isFetching}
                                    />
                                );
                            })}
                </FlexColumnWrapper>
            </StyledDrawerContent>
            <FlexRowWrapper
                p={2}
                width={'100%'}
            >
                <StyledButton
                    variant={'contained'}
                    color={'success'}
                    fullWidth
                    sx={{ maxWidth: '100% !important' }}
                    onClick={createBackup}
                >
                    Создать резервную копию
                </StyledButton>
            </FlexRowWrapper>
        </StyledDrawer>
    );
}
