import { StyledTooltip } from '../../../WorkManagment/components/components.styles'
import { INotificatorProps } from './Notificator.def'
import { IconWrapper } from './Notificator.styles'

export function Notificator({ title, color }: INotificatorProps) {
    return (
        <StyledTooltip
            title={title}
            PopperProps={{
                disablePortal: false,
            }}
            disableInteractive
        >
            <IconWrapper>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM4.5 7.5V6.5H5.5V7.5H4.5ZM4.5 2.5V5.5H5.5V2.5H4.5Z"
                        fill={color ?? '#FDE047'}
                    />
                </svg>
            </IconWrapper>
        </StyledTooltip>
    )
}
