import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { req } from '../../pages/WorkManagment/api/api'
import { onEditAgKsg, onKSGUpload } from '../../store/slices/agGridKsgMsgSlices/agGridKsgSlice'
import { drawersSelector, toggleDistributionProgress, toggleUploadProgress } from '../../store/slices/drawersSlice'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { IActiveUploadTask, IDistributionTask, Nullable } from '../def'

export default function useProjectUploadProgress({
    supressOnClose,
    supressWatchBtn,
}: {
    supressOnClose: boolean
    supressWatchBtn: boolean
}) {
    const { projectId } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [activeTask, setActiveTask] = useState<Nullable<IActiveUploadTask>>(null)
    const [distributionTask, setDistributionTask] = useState<Nullable<IDistributionTask>>(null)

    const [triggerUpdateStatus, setTriggerUpdateStatus] = useState<boolean>(false)
    const [result, setResult] = useState<Nullable<IActiveUploadTask['result']>>(null)
    const [distributionResult, setDistributionResult] = useState<Nullable<IDistributionTask['result']>>(null)
    const dispatch = useAppDispatch()
    const drawers = useTypedSelector(drawersSelector)

    function handleError(e: { response: { data: any } }) {
        enqueueSnackbar(e?.response?.data || 'Ошибка', { variant: 'error' })
    }

    const refreshProgressStatus = useCallback(() => {
        if (!!activeTask?.uuid && !activeTask.result) {
            req.get(`/projects/${projectId}/works/upload-get-active-progress?uuid=${activeTask.uuid}`)
                .then(({ data }) => {
                    setActiveTask(() => data)
                    setResult(() => data?.result)
                })
                .catch((e) => {
                    handleError(e)
                    setActiveTask(null)
                })

            return
        }

        if (!!distributionTask?.uuid && !distributionTask.result) {
            req.get(`/projects/${projectId}/works/ksg/distribute/task-progress?uuid=${distributionTask.uuid}`)
                .then(({ data }) => {
                    console.log('data', data)

                    setDistributionTask(() => data)
                    setDistributionResult(() => data?.result)
                })
                .catch((e) => {
                    handleError(e)
                    setDistributionTask(null)
                    dispatch(
                        toggleDistributionProgress({
                            open: false,
                            distributionTask: undefined,
                        })
                    )
                })

            return
        }

        req.get(`/projects/${Number(projectId)}/works/get-active-upload`)
            .then(({ data }) => {
                setActiveTask(() => data.task)

                if (data.distributionTask) {
                    setDistributionTask(() => data.distributionTask)
                    toggleDistributionProgress({
                        open: true,
                    })
                }
            })
            .catch(handleError)
    }, [projectId, activeTask, distributionTask])

    useEffect(() => {
        if (!distributionTask) return

        dispatch(
            toggleDistributionProgress({
                open: true,
                distributionTask: distributionTask,
            })
        )
    }, [distributionTask])

    useEffect(() => {
        if (!distributionResult) return

        dispatch(
            toggleDistributionProgress({
                open: false,
                distributionTask: undefined,
            })
        )

        if (distributionResult.success) {
            dispatch(onEditAgKsg(distributionResult.data))
            enqueueSnackbar('План успешно распределен', { variant: 'success' })
            setDistributionResult(() => null)
            return
        }

        const error = distributionResult?.error as string[]

        enqueueSnackbar(error[0] ?? 'Возникла ошибка при распределении', { variant: 'error' })
        setDistributionResult(() => null)
    }, [distributionResult])

    useEffect(() => {
        if (
            !result &&
            !!activeTask &&
            drawers.uploadProgress.openDialog !== 'wrapped' &&
            !drawers.uploadKsg &&
            !drawers.updateKSG
        ) {
            dispatch(
                toggleUploadProgress({
                    openDialog: !!activeTask,
                    supressOnClose: supressOnClose,
                    supressWatchBtn: supressWatchBtn,
                    activeTask: activeTask,
                })
            )
        }
        if (!!result) {
            dispatch(
                toggleUploadProgress({
                    openDialog: false,
                    supressOnClose: false,
                    supressWatchBtn: false,
                    activeTask: undefined,
                })
            )
            if (!!result?.success) {
                dispatch(onKSGUpload())
            }
        }
    }, [activeTask, result])

    useEffect(() => {
        let interval: NodeJS.Timer
        if (triggerUpdateStatus) {
            interval = setInterval(() => {
                refreshProgressStatus()
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    }, [activeTask?.uuid, refreshProgressStatus, triggerUpdateStatus])

    return {
        activeTask: activeTask,
        setActiveTask: setActiveTask,
        setTriggerUpdateStatus: setTriggerUpdateStatus,
        result: result,
    }
}
